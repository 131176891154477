import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../../assets/SVG/LOGO.svg";
import Modal from "../../components/Modal/modal";
import ProgressBarModal from "../../components/Modal/progressBarModal";
import useEnrolledData from "../../components/hooks/useEnrolledData";
import { getVideoTestQuestions, resetExamData, submitVideoTest } from "../../features/testSlice";

const VideoPracticeTest = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const qnalistId = location?.state;

    let id;
    const [qIndex, setIndex] = useState(0);
    const [testTime, setTestTime] = useState("");
    const [finishTest, setFinishTest] = useState(false);
    const [viewScorePopup, setViewScorePopup] = useState(false);
    const [questionNum, setQuestionNum] = useState(1);

    const [questions, setQuestions] = useState([]);
    const [arr, setArr] = useState([]);

    const testData = useSelector((state) => state?.testlist?.singleTest);
    const studentId = useSelector((state) => state?.user?.profileData?.[0]?._id) || "65252977789e5e0469c418d5"
    console.log(studentId,'studentIdvideo')
    const testResponse = useSelector((state) => state?.testlist?.submitResponse?.data);
    const QuestionsData = useSelector((state) => state?.testlist?.questionsData);


    const { loadingState, selectedId, subCourses } = useEnrolledData();

    // console.log(testData, "line33")

    useEffect(() => {
        if (qnalistId) {
            dispatch(getVideoTestQuestions({ qnalistId: qnalistId }));
        }
    }, [qnalistId])

    useEffect(() => {
        if (QuestionsData && QuestionsData?.length) {
            setQuestions([...QuestionsData]);
        }
    }, [QuestionsData])

    useEffect(() => {
        if (questions && questions?.length) {
            setArr([...questions]);
        }
    }, [questions])

    useEffect(() => {
        return () => {
            dispatch(resetExamData());
        }
    }, [dispatch])

    const handleSelectOption = (questionId, option, index) => {
        // console.log(option, "line80")
        const updateArr = [...arr];
        updateArr[index] = {
            ...updateArr[index],
            studentAnswer: option,
        };
        setArr(() => updateArr);
    };

    const submit = async () => {
        let newData = arr?.map((item) => {
            return (
                {
                    ...item,
                    studentId: studentId,
                    qnalistId: testData?._id,
                    questionId: item?._id ? item?._id : item?.questionId,
                }
            )
        })
        dispatch(submitVideoTest(newData));
        setViewScorePopup(true);
        setFinishTest(false);
        if (document.fullscreenElement) {
            document.exitFullscreen();
        }
    };

    const updateTime = (time) => {
        let inSeconds = time;
        let hours = Math.floor(inSeconds / 60 / 60);
        let minutes = Math.floor((inSeconds / 60) % 60);
        let seconds = Math.floor(time - (hours * 60 * 60 + minutes * 60))
        if (!viewScorePopup) {
            setTestTime(`${hours}: ${minutes}: ${seconds}`);
        }
        inSeconds -= 1;

        return inSeconds;
    };

    const startTimer = async () => {
        const totalMinutes = testData?.duration;
        let newTime = totalMinutes * 60
        id = setInterval(() => {
            updateTime(newTime);
            newTime -= 1

            if (newTime <= 0) {
                clearInterval(id);
            }
        }, 1000);
    };

    useEffect(() => {
        if (testData?.duration) {
            startTimer();
        }
        return () => {
            clearInterval(id);
        }
    }, [testData?.duration])

    useEffect(() => {
        if (testTime && testTime === '0: 0: 1') {
            submit();
        }
    }, [testTime])

    // console.log(qIndex, "line247", arr)

    const options = ['a', 'b', 'c', 'd']

    return (
        <>
            <div className="w-full shadow-md relative ">
                <div className="max-w-[1600px] h-[95px] mx-auto flex justify-between  items-center">
                    <div>
                        <Logo />
                    </div>
                    <div>
                        <button onClick={() => setFinishTest(true)}
                            data-modal-target="defaultModal" data-modal-toggle="defaultModal" className="bg-primary text-white font-medium font-primary  px-3 py-2 rounded h-[40px] w-[142px] ">
                            SUBMIT TEST
                        </button>
                    </div>
                </div>
            </div>

            <div className="flex justify-end">

                <div className="w-full mx-auto mt-14 ml-56">
                    <div className="flex items-center gap-4 mb-8 w-full">
                        <svg width="41" height="41" viewBox="0 0 41 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M20.5 40.18C31.369 40.18 40.18 31.369 40.18 20.5C40.18 9.631 31.369 0.82 20.5 0.82C9.631 0.82 0.82 9.631 0.82 20.5C0.82 31.369 9.631 40.18 20.5 40.18Z" stroke="#333333" stroke-width="1.64" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M20.5 1.86372V5.0882" stroke="#2E90EF" stroke-width="1.64" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M20.5 35.912V39.1365" stroke="#2E90EF" stroke-width="1.64" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M1.86328 20.5001H5.08776" stroke="#2E90EF" stroke-width="1.64" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M35.1367 20.5001H39.1345" stroke="#2E90EF" stroke-width="1.64" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M7.32031 7.3223L9.37387 9.37585" stroke="#333333" stroke-width="1.64" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M31.625 31.6244L33.6786 33.6779" stroke="#333333" stroke-width="1.64" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M33.6786 7.32222L31.625 9.37585" stroke="#333333" stroke-width="1.64" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M9.37387 31.6243L7.32031 33.6779" stroke="#333333" stroke-width="1.64" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M20.5 11.0946V21.8668H27.8193" stroke="#2E90EF" stroke-width="1.75714" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <circle cx="20.4994" cy="21.9643" r="1.46429" fill="#2E90EF" />
                        </svg>
                        <div className="font-primary font-semibold text-xl">
                            <p>{testTime}</p>
                        </div>
                    </div>

                    {questions &&
                        questions.map((ques, i) => {
                            return (
                                i === qIndex && (
                                    <div className="grid grid-cols-5 font-primary w-full" key={i}>
                                        <div className="col-span-3">
                                            <p className="font-semibold text-primary text-xl">
                                                {"Question " + (i + 1)}
                                            </p>
                                            <h1 className="text-[#383838] text-lg font-semibold break-words mt-2">
                                                {ques.question}
                                            </h1>
                                            {ques?.questionfileurl ?
                                                <div className="min-h-[55px] h-[200px] my-2">
                                                    <img src={ques?.questionfileurl} className="h-full" />
                                                </div>
                                                : ""
                                            }

                                            <div>
                                                {options?.map((ans, index) => {
                                                    return (
                                                        <>
                                                            {ques[ans]?.includes(".jpg") || ques[ans]?.includes(".png") || ques[ans]?.includes(".jpeg") || ques[ans]?.includes(".webp") ?
                                                                <div
                                                                    key={index}
                                                                    className="bg-[#FDFDFD] min-h-[55px] h-[192px] cursor-pointer rounded border border-[#ADCBF900] shadow-md shadow-[#0064FD2E] my-6 py-3 text-[#383838] font-secondary text-base font-normal px-6 flex items-center gap-3"
                                                                    onClick={() => {
                                                                        handleSelectOption(ques._id, ans, i);
                                                                    }}
                                                                >
                                                                    <input
                                                                        className="before:content[''] h-6 w-6 border-gray-500 border-2"
                                                                        type="radio"
                                                                        name=""
                                                                        id=""
                                                                        key={ques[ans]}
                                                                        value={ques[ans]}
                                                                        checked={
                                                                            arr[qIndex]?.studentAnswer === ans
                                                                        }
                                                                        onChange={() => {
                                                                            handleSelectOption(ques._id, ans, i);
                                                                        }}
                                                                    />
                                                                    <img src={ques[ans]} className="h-full" />
                                                                </div>
                                                                :
                                                                <div
                                                                    key={index}
                                                                    className="bg-[#FDFDFD] h-[55px] cursor-pointer rounded border border-[#ADCBF900] shadow-md shadow-[#0064FD2E] my-6 py-3 text-[#383838] font-secondary text-base font-normal px-6 flex items-center gap-3"
                                                                    onClick={() => {
                                                                        handleSelectOption(ques._id, ans, i);
                                                                    }}
                                                                >
                                                                    <input
                                                                        className="before:content[''] h-6 w-6 border-gray-500 border-2"
                                                                        type="radio"
                                                                        name=""
                                                                        id=""
                                                                        key={ques[ans]}
                                                                        value={ques[ans]}
                                                                        checked={
                                                                            arr[qIndex]?.studentAnswer === ans
                                                                        }
                                                                        onChange={() => {
                                                                            handleSelectOption(ques._id, ans, i);
                                                                        }}
                                                                    />
                                                                    <p className="">{ques[ans]} </p>
                                                                </div>
                                                            }
                                                        </>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className="col-span-2"> </div>
                                    </div>
                                )
                            );
                        })}

                    <div className="mt-4">
                        <div className="flex gap-4 ">
                            <button
                                disabled={qIndex === 0}
                                className={` ${qIndex === 0 ? 'bg-[#0000001A]' : 'bg-primary'} h-11 w-11 px-2 rounded-full`}
                                onClick={() => {
                                    setIndex(qIndex - 1)
                                    setQuestionNum((prev) => prev - 1)
                                }}
                            >
                                {" "}
                                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none">
                                    <path d="M12.0375 19.4344C12.2625 19.2094 12.3705 18.9375 12.3615 18.6188C12.3525 18.3 12.2351 18.0281 12.0094 17.8031L8.83125 14.625H21.375C21.6937 14.625 21.9611 14.517 22.1771 14.301C22.3931 14.085 22.5007 13.818 22.5 13.5C22.5 13.1813 22.392 12.9139 22.176 12.6979C21.96 12.4819 21.693 12.3743 21.375 12.375H8.83125L12.0375 9.16877C12.2625 8.94377 12.375 8.6764 12.375 8.36665C12.375 8.0569 12.2625 7.7899 12.0375 7.56565C11.8125 7.34065 11.5451 7.22815 11.2354 7.22815C10.9256 7.22815 10.6586 7.34065 10.4344 7.56565L5.2875 12.7125C5.175 12.825 5.09512 12.9469 5.04787 13.0781C5.00062 13.2094 4.97737 13.35 4.97812 13.5C4.97812 13.65 5.00175 13.7906 5.049 13.9219C5.09625 14.0531 5.17575 14.175 5.2875 14.2875L10.4625 19.4625C10.6687 19.6688 10.9264 19.7719 11.2354 19.7719C11.5444 19.7719 11.8117 19.6594 12.0375 19.4344Z" fill="white" />
                                </svg>
                            </button>
                            <button
                                disabled={qIndex === questions.length - 1}
                                className={`${qIndex === questions.length - 1 ? 'bg-[#0000001A]' : 'bg-primary'} rounded-full h-11 w-11 px-2`}
                                onClick={() => {
                                    setIndex(qIndex + 1);
                                    setQuestionNum((prev) => prev + 1)
                                }}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" width="27" height="27" viewBox="0 0 27 27" fill="none" >
                                    <path d="M14.9625 19.4344C14.7375 19.2094 14.6295 18.9375 14.6385 18.6188C14.6475 18.3 14.7649 18.0281 14.9906 17.8031L18.1688 14.625H5.625C5.30625 14.625 5.03888 14.517 4.82288 14.301C4.60688 14.085 4.49925 13.818 4.5 13.5C4.5 13.1813 4.608 12.9139 4.824 12.6979C5.04 12.4819 5.307 12.3743 5.625 12.375H18.1688L14.9625 9.16877C14.7375 8.94377 14.625 8.6764 14.625 8.36665C14.625 8.0569 14.7375 7.7899 14.9625 7.56565C15.1875 7.34065 15.4549 7.22815 15.7646 7.22815C16.0744 7.22815 16.3414 7.34065 16.5656 7.56565L21.7125 12.7125C21.825 12.825 21.9049 12.9469 21.9521 13.0781C21.9994 13.2094 22.0226 13.35 22.0219 13.5C22.0219 13.65 21.9983 13.7906 21.951 13.9219C21.9038 14.0531 21.8243 14.175 21.7125 14.2875L16.5375 19.4625C16.3313 19.6688 16.0736 19.7719 15.7646 19.7719C15.4556 19.7719 15.1883 19.6594 14.9625 19.4344Z" fill="white" />
                                </svg>
                            </button>
                        </div>
                        <Modal openModal={finishTest} heading={'Attempted Questions'} subHead1={`( ${arr?.filter((item) => item?.studentAnswer)?.length}/${questions?.length} )`} subHead2={'Do you really want to Submit Test?'} btnName={'SUBMIT NOW'}
                            handleBtn={submit} btnCancel={'CANCEL'} handleCancel={() => setFinishTest(false)} />
                        <ProgressBarModal openModal={viewScorePopup} percentage={(testResponse?.obtainMarks / testResponse?.totalMarks) * 100} percentageValue={testResponse ? `${testResponse?.obtainMarks}/${testResponse?.totalMarks}` : ""}
                            heading={'Your Score:'} subHeading={'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur magna Lorem ipsum dolor sit amet'}
                            btnName={'VIEW ANSWERS'} handleBtn={() => navigate('/viewAnswers', { state: { testListId: qnalistId, examType: 'Video Test', questions: questions } })} />
                    </div>
                </div>

                <div className=" w-[411px] bg-[#FAFAFA] bg-repeat-y min-h-[1132px] absolute">
                    <div className=" lg:w-[#345px] py-10 px-8">
                        <p className="text-center font-primary font-bold text-2xl">Question Panel</p>
                        <div className="grid lg:grid-cols-5 md:grid-cols-3 mt-6">
                            {Array.from({ length: questions?.length }, (_, i) => i + 1)?.map((item, i) => {
                                return (
                                    <>
                                        <div key={i}
                                            className={`h-11 w-11 rounded-full py-1.5 text-center mb-5 cursor-pointer font-secondary text-lg font-semibold
                                            ${item === questionNum ? 'bg-primary text-white py-1' :
                                                    arr?.length && arr[i]?.studentAnswer ? 'border-2 border-[#4BB543] text-[#4BB543]' :
                                                        'border-2 border-[#8F98A8] text-[#8F98A8]'} `}
                                            onClick={() => {
                                                setQuestionNum(item)
                                                setIndex(i)
                                            }}
                                        >
                                            {item}
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );

}
export default VideoPracticeTest;