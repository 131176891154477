// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAkBML38Q_gN31fkVshmUM9NcCIdYnzS5s",
  authDomain: "iamlearning-8362e.firebaseapp.com",
  databaseURL:
    "https://iamlearning-8362e-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "iamlearning-8362e",
  storageBucket: "iamlearning-8362e.appspot.com",
  messagingSenderId: "691759792759",
  appId: "1:691759792759:web:3ea11d0140f9374adf7c72",
  measurementId: "G-JWFJ7NN1GC",
};

export const app = initializeApp(firebaseConfig);
export const messaging = (await isSupported()) === true && getMessaging(app);
// export const messaging = (async () => {
//   try {
//     const isSupportedBrowser = await isSupported();
//     if (isSupportedBrowser) {
//       return getMessaging(app);
//     }
//     console.log("Firebase is not supported in this browser");
//     return null;
//   } catch (err) {
//     console.log(err);
//     return null;
//   }
// })();

export const requestPermission = () => {
  return (
    isSupported() &&
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        return getToken(messaging, {
          vapidKey:
            "BLTVk2-OFc_EMT8-ILPUsHTN9jMVkDQSEOVtJTouWaq0w3BQwicVzSklXBS6JSrEFCYK0M5eJvAqcO25Z2qrxxU",
        })
          .then((currentToken) => {
            if (currentToken) {
              localStorage.setItem("fcmToken", currentToken);

              return currentToken;
            } else {
              console.log("Failed to generate the app registration token.");
            }
          })
          .catch((err) => {
            console.log(
              "An error occurred when requesting to receive the token.",
              err
            );
          });
      } else {
        console.log("User Permission Denied.");
      }
    })
  );
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      return resolve(payload);
    });
  });

export const Token = (setTokenFound) => {
  return getToken(messaging, { vapidKey: "GENERATED_MESSAGING_KEY" })
    .then((currentToken) => {
      if (currentToken) {
        setTokenFound(true);
        return currentToken;
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};
