import axios from "axios";

const baseURL = " http://api.cotginedu.com/api/";
const satendraUrl = "http://localhost:4000/api/";

// Replace with your API base URL ( http://13.235.103.94:4000/api/ - old base URL)

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api/`,
  headers: {
    "Content-Type": "application/json",
    "x-auth-token": localStorage.getItem("userToken"),

    // Replace with any other default headers you want to set
    // Add other headers here if needed
  },
});

// Request Interceptor: This will be executed before each request is made
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("userToken");
    if (token) {
      config.headers["x-auth-token"] = token;
    }
    return config;
  },
  (error) => {
    // Handle request errors here
    return Promise.reject(error);
  }
);


export default api;

export const PostReq = async (url, data, token, content) => {
  try {
    const response = await axios.post(url, data, {
      headers: {
        "x-auth-token": token,
      },
    });
    return response.data;
  } catch (error) { }
};
