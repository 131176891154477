import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../components/Card/VideoCard";
import Loader from "../../components/Loader";
import NoDataFound from "../../components/NoDataFound";
import useEnrolledData from "../../components/hooks/useEnrolledData";
import { getSubjects } from "../../features/getCourseSlice";
import { getPlaylistByChapter, resetData } from "../../features/playlistSlice";
import { quickSolutionPlaylist } from "../../features/quicksolutionSlice";

const RecordedLectures = () => {
  const dispatch = useDispatch();
  const { loadingState, selectedId, subCourses } = useEnrolledData();

  const LiveStreamData = useSelector(
    (state) => state?.playlist?.playlists?.data?.[0].videosDetails
  );

  console.log(LiveStreamData,'LiveStreamData')
  const loading = useSelector((state) => state?.liveStreams?.isLoading);

  const subjectsData = useSelector((state) => state?.course?.subjectData);
  const [chapters, setChapters] = useState("");
  const [activeTab, setTab] = useState(0);
  console.log(subjectsData,'subjectsData')

  const [chapterName, setChaptername] = useState("");
  const [tabId, setTabId] = useState(
    subjectsData && subjectsData?.data[0]?._id
  );

  useEffect(() => {
    if (selectedId) {
      dispatch(getSubjects({ courseFieldId: selectedId })).then(
        ({ payload }) => {
          if (payload && payload?.data.length > 0) {
            dispatch(
              quickSolutionPlaylist({
                subjectId: payload?.data[0]._id,
                playlistType: "live",
              })
            ).then(({ payload }) => {
              if (payload && payload.length > 0) {
                setChapters(payload);
                dispatch(
                  getPlaylistByChapter({
                    playlistId: payload?.[0]?._id,
                    playlistType: "live",
                  })
                );
              } else {
                setChapters("");
              }
            });
          }
        }
      );
    }

    return () => dispatch(resetData());
  }, [selectedId, dispatch]);

  // useEffect(() => {
  //   dispatch(
  //     getStreamBySubjectId({
  //       subjectId: tabId,
  //     })
  //   );
  // }, [dispatch, tabId]);

  return (
    <React.Fragment>
      <div>
        <div className="justify-center sm:justify-start flex flex-wrap gap-5">
          {subjectsData &&
            subjectsData?.data?.map((item, i) => {
              return (
                <button
                  key={i}
                  className={`font-medium text-sm font-primary p-2 px-5 border-2 border-primary rounded
                                      ${
                                        activeTab === i
                                          ? ` bg-primary text-white`
                                          : `bg-white text-primary`
                                      }`}
                  onClick={() => {
                    setTab(i);
                    setTabId(item._id);

                    dispatch(
                      quickSolutionPlaylist({
                        subjectId: item._id,
                        playlistType: "live",
                      })
                    ).then(({ payload }) => {
                      if (payload && payload?.length > 0) {
                        setChapters(payload);
                        dispatch(
                          getPlaylistByChapter({
                            playlistId: payload?.[0]?._id,
                            playlistType: "live",
                          })
                        ).then(({ payload }) => {
                          if (payload) {
                      
                            setChaptername(payload?.data?.[0]?.playlistName);
                          }
                        });
                      } else {
                        setChapters("");

                        dispatch(resetData());
                      }
                    });
                  }}
                >
                  {item?.subjectName}
                </button>
              );
            })}
        </div>{" "}
        {chapters && (
          <div className="flex justify-between mt-5 items-center">
            <p className="font-primary font-bold text-2xl">
              {chapterName ? chapterName : ""}
            </p>
            <select
              name=""
              placeholder="Select Chapter"
              className="border border-[#C3C3C3] rounded p-2 lg:w-52 font-primary text-sm font-medium text-black outline-none"
              onChange={(e) => {
                dispatch(
                  getPlaylistByChapter({
                    playlistId: e.target.value,
                    playlistType: "live",
                  })
                ).then(({ payload }) => {
                  if (payload && payload?.data) {
                    setChaptername(payload?.data?.[0]?.playlistName);
                  } else {
                    setChaptername("");
                  }
                });
              }}
            >
              {chapters &&
                chapters.map((item, i) => {
                  return (
                    <option
                      key={item?._id}
                      value={item?._id}
                      className="px-3 py-1 border border-[#C3C3C3] rounded-none"
                    >
                      {item?.playlistName}
                    </option>
                  );
                })}
            </select>
          </div>
        )}
        <div className="">
          {loading ? (
            <Loader />
          ) : (
            <>
              {LiveStreamData && LiveStreamData?.length ? (
                <div className="grid md:grid-cols-2 lg:grid-cols-3 lg:flex-wrap gap-y-7 mt-8">
                  {LiveStreamData?.map((item, i) => {
                    console.log(item)
                    return (
                      <React.Fragment key={i}>
                        {item.isActive && (
                          <Card
                            thumbnail={item?.teacherId?.image}
                            backgroundImg={item?.teacherId?.teacherBgImage}
                            title={item?.title}
                            fullname={item?.teacherId?.fullname}
                            btnName="Watch Class"
                            showSubjectDetails={true}
                            subjectName={item?.subject?.subjectName}
                            courseName={item?.subject?.courseFieldId?.name}
                            link={`/videos/${item.subject._id}`}
                            id={item?._id}
                            type={"recorded"}
                            classTime={item?.timeSchedule?.[0]?.timeSchedule}
                            />
                            )}
                      </React.Fragment>
                    );
                  })}
                </div>
              ) : (
                <NoDataFound />
              )}
            </>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default RecordedLectures;
