import React from 'react'
import mobileApp from '../../assets/images/mobiledev.webp';
import googlePlay from '../../assets/images/playStore.png';
import appStore from '../../assets/images/appStore.png';

const UpperFooter = () => {
  return (
    <section>
      <div className="container mx-auto py-6 sm:py-10 px-4 max-w-[1200px]">
        <div className="flex flex-col lg:flex-row sm:gap-20 gap-6">
          <div className="self-center lg:w-4/12 lg:text-start text-center">
            <h2 className='mb-2 sm:mb-4 text-dark text-xl sm:text-[32px] font-semibold font-primary capitalize text-[#333333] sm:leading-[36.81px]'>Take your <span className="text-secondary font-bold">education</span> with&nbsp;you</h2>
            <p style={{ color: '#383838' }} className='mb-3 sm:mb-4 font-normal font-secondary sm:leading-[18.75px] text-xs sm:text-base'>Download the Cotgin Edu App to take your learning to the next&nbsp;level.</p>
            <div className='flex gap-2 sm:gap-4  lg:justify-start justify-center'>
              <a href="https://play.google.com/store/apps/details?id=com.cotginanalytics.cotginedu&hl=en-IN" className="">
                <img src={googlePlay} alt="Play Store" className="sm:w-40 w-[111.72px]" />
              </a>
              <a href="#" className="">
                <img src={appStore} alt="App Store" className="sm:w-40  w-[111.72px]" />
              </a>
            </div>
          </div>
          <div className="lg:w-8/12 lg:h-[480px] lg:w-[700px] flex justify-lg-end justify-center items-center w-full">
          <div className="lg:text-start text-center">
            <img src={mobileApp} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default UpperFooter
