import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useDispatch } from "react-redux";
import Close from "../../assets/images/closeIcon.png";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;


export const PDFview = ({ pdfUrl, handleClose }) => {
  const dispatch = useDispatch();

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  /*To Prevent right click on screen*/
  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });

  function onDocumentLoadSuccess({ numPages, error }) {
    if (error) {
      console.error("Error loading PDF:", error);
      // Log additional details about the error
      console.error("Error details:", error.message, error.name, error.status);
      // Handle error (e.g., show an error message)
    } else {
      setNumPages(numPages);
      setPageNumber(1);
    }
  }


  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <div>
      <div className="flex justify-end pt-2 ">
        <button
          className="h-12 w-12 mx-2"
          onClick={() => {
            handleClose();
          }}
        >
          <img src={Close} />
        </button>
      </div>

      <div className="m-5 ">

        <Document file={process.env.REACT_APP_BASE_URL + pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
          <Page
            pageNumber={pageNumber}
            renderTextLayer={false}
            renderAnnotationLayer={false}
            customTextRenderer={false}
            height={550}
          />
        </Document>
      </div>

      <div className="flex justify-between m-5 items-center">
        <div className="font-primary font-bold">
          Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
        </div>
        <div className="flex gap-4 text-blue-400 font-primary font-bold ">
          <button
            type="button"
            disabled={pageNumber <= 1}
            onClick={previousPage}
            className="Pre"
          >
            Previous
          </button>
          <button
            type="button"
            disabled={pageNumber >= numPages}
            onClick={nextPage}
          >
            Next
          </button>
        </div>
      </div>
    </div >
  );
};

export default PDFview;
