import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
// import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
import "./App.css";
import ScrollToTop from "./ScrollToTop";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import About from "./pages/About/About";
// import BuyCourse from "./pages/BuyCourse/BuyCourse";
import Classes from "./pages/Classes/Classes";
import Goals from "./pages/Goals/Goals";
import Home from "./pages/Home/Home";
import InstructorDetail from "./pages/InstructorDetail/InstructorDetail";
import InstructorForm from "./pages/JoinAsInstructor/InstructorForm";
import JoinAsInsturctor from "./pages/JoinAsInstructor/JoinAsInsturctor";
import MyLearning from "./pages/MyLearning/MyLearning";
import Plans from "./pages/Plans/Plans";
import PractiseTest from "./pages/PractiseTest/PractiseTest";
import Settings from "./pages/Settings/Settings";
import Test from "./pages/Videos/Test";
import Videos from "./pages/Videos/Videos";

import { getProfileData, getTokenFromLocal } from "./features/userSlice";
import ContactUs from "./pages/ContactUs";

import News from "./pages/News/News";
import ReadNews from "./pages/News/ReadNews";
// import Notification from "./pages/Notification/Notification";

import { studentCourses } from "./features/enrollSlice";

import MyPurchases from "./pages/MyPurchases/MyPurchases";
import ViewAnswers from "./pages/PractiseTest/ViewAnswers";
import PlayLiveVideo from "./pages/Videos/PlayLiveVideo";
import InstructorList from "./pages/InstructorsList";
import DownloadApp from "./pages/DownloadApp";
import ApplyCertificate from "./pages/Certificate/applyCertificate";
import SyllabusInteral from "./pages/SyllabusInternal";
import Leaderboard from "./pages/LeaderBoard/leaderindex";
import DoubtChatTest from "./DoubtChatTest.js/DoubtChatTest";
import AreaGraphTest from "./DoubtChatTest.js/AreaGraphTest";
import VideoPracticeTest from "./pages/PractiseTest/VideoPracticeTest";
import RefundPolicy from "./pages/Terms/RefundPolicy";
import TermsConditions from "./pages/Terms/TermsConditions";
import PrivacyPolicy from "./pages/Terms/PrivacyPolicy";
import useWindowDimensions from "./components/useWindowDimensions";
import MobileNavBar from "./components/Navbar/MobileNavbar";
import HowDeactiveUser from "./pages/Terms/HowDeactiveUser";


function App() {
  const dispatch = useDispatch();
  const { isMobile } = useWindowDimensions();

  useEffect(() => {
    dispatch(getTokenFromLocal());
    dispatch(getProfileData());
  });

  let token = useSelector((state) => state.user.token);

  useEffect(() => {
    dispatch(studentCourses());
  }, []);

  const course = useSelector((state) => state?.user?.user?.enrolledCourses);

  const enroll = useSelector((state) => state?.enroll?.studentData);
  const isCourse = useSelector((state) => state?.enroll.hasCourse);

  return (
    <>
      <BrowserRouter>
        {isMobile ? <MobileNavBar /> : <Navbar />}
        <ScrollToTop />
        <Routes>
          <Route path="/test" element={<Test />} />

          {token ? (
            <>
              <Route path="/" element={isCourse ? <Classes /> : <Goals />} />
              <Route path="/goals" element={<Goals />} />
              {/* <Route path="/buy-course" element={<BuyCourse />} /> */}
              <Route path="/about-us" element={<About />} />
              <Route path="/mylearning" element={<MyLearning />} />
              <Route path="/videos/:id" element={<Videos />} />
              <Route path="/test" element={<Test />} />
              <Route path="/practiseTest" element={<PractiseTest />} />
              <Route path="/videoTest" element={<VideoPracticeTest />} />
              <Route path="/viewAnswers" element={<ViewAnswers />} />
              <Route path="/leaderBoard" element={<Leaderboard />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/plans" element={<Plans />} />
              {/* <Route path="/notifications" element={<Notification />} />  */}
              <Route path="/mypurchase" element={<MyPurchases />} />
              <Route path="/playlive/:id" element={<PlayLiveVideo />} />
            </>
          ) : (
            <>
              <Route path="/" element={<Home />} />
              <Route path="/about-us" element={<About />} />
              <Route path="/*" element={token ? <Goals /> : <Home />} />
            </>
          )}

          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/news" element={<News />} />
          <Route path="/news/:id" element={<ReadNews />} />
          <Route path="/insturctors" element={<InstructorList />} />
          <Route path="/joininsturctor" element={<JoinAsInsturctor />} />
          <Route path="/instructorform" element={<InstructorForm />} />
          <Route
            path="/instructordetail/:teacherId"
            element={<InstructorDetail />}
          />
          <Route path="/download-app" element={<DownloadApp />} />
          <Route path="/apply-certificate" element={<ApplyCertificate />} />
          <Route path="/syllabus-internal-page" element={<SyllabusInteral />} />

          {/*  testing live stream */}

          <Route path="/doubtchattest" element={<DoubtChatTest />} />
          <Route path="/areatest" element={<AreaGraphTest />} />
          <Route path="/refundpolicy" element={<RefundPolicy />} />
          <Route path="/termsConditions" element={<TermsConditions />} />
          <Route path="/howDeactiveUser" element={<HowDeactiveUser />} />
          <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
          <Route />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
