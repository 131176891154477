import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../app/AxiosHandler";

const initialState = {
  videoUrl: "",
  videoResponse: "",
  currentVideo:"",
  isLoading: false,
  error: null,
  overview: "",
  testVideo: "",
};

export const getVideoById = createAsyncThunk(
  "video/byid",
  async (data, thunkApi) => {
    try {
      const response = await api
        .post(process.env.REACT_APP_PLAY_VIDEO, data)
        .then((res) => res.data);
      console.log(response.data,'1111111111')
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getOverviewBySubject = createAsyncThunk(
  "overview/bySubject",
  async (data, thunkApi) => {
    try {
      const response = await api.post(
       "overviews/getbycoursefield",
        data
      );
      return response.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

const videoSlice = createSlice({
  name: "video",
  initialState,
  reducers: {
    resetUrl: (state, action) => {
      state.videoUrl = ""; 
      
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getVideoById.fulfilled, (state, action) => {
        state.videoUrl = action.payload?.[0]?.videoUrl
        state.currentVideo= action.payload
        state.isLoading = false;
      })
      .addCase(getVideoById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getVideoById.rejected, (state, action) => {
        state.error = action.payload;
      });

    builder
      .addCase(getOverviewBySubject.fulfilled, (state, action) => {
        state.overview = action.payload;
        state.isLoading = false;
      })
      .addCase(getOverviewBySubject.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getOverviewBySubject.pending, (state, action) => {
        state.isLoading = true;
      });
  },
});

export const currentVidDetails = state => state?.videos?.currentVideo?.[0]

export const { resetUrl } = videoSlice.actions;
export default videoSlice.reducer;
