import { useState } from "react";
import OTPpage from "./OTPpage";
import Close from "../../assets/images/closeIcon.png";

const LoginModal = ({
  setLoginModal,
  setPhoneNumber,
  handleLoginNumber,
  error,
}) => {
  const [OTPModal, setOTPMOdal] = useState(false);
  const [otpVerify, setOtpVerify] = useState("");
  const [mobileNum, setMobileNum] = useState("");

  console.log(mobileNum, "line6");

  function validate(phone) {
    const regex = /^(?!([0-9])\1{2}-?\1{3}-?\1{4}$)\(?\d{3}\)?[-. ]?\d{3}[-. ]?\d{4}$/;

    return regex.test(phone);
  }

  const handleNumber = (e) => {
    e.preventDefault();
    const progress = validate(mobileNum);
    if (progress) {
      setOTPMOdal(true);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-80">
      <div className=" flex justify-center">
        {OTPModal ? (
          <OTPpage
            otpVerify={otpVerify}
            setOtpVerify={setOtpVerify}
            modal={true}
            setOTPMOdal={setOTPMOdal}
            phoneNumber={mobileNum}
            setLoginModal={setLoginModal}
          />
        ) : (
          <section className="grid grid-cols ">
            <div className="relative flex flex-col flex-1 bg-white items-center self-start gap-5 sm:py-16 py-10 w-full rounded-xl shadow-xl">
              <div
                onClick={() => setLoginModal(false)}
                className="absolute sm:top-0 -top-3 -right-3 sm:right-0 h-20 w-20 cursor-pointer p-4"
              >
                <img src={Close} />
              </div>
           
              <form
                onSubmit={(e) => {
                  handleLoginNumber(e, "Modal");
                  handleNumber(e);
                }}
                className="flex flex-col w-4/5 mx-auto gap-3"
              >
                {/* <div className="relative">
                                    <input
                                        type="text"
                                        className="w-full p-3 border border-[#C3C3C3] rounded focus:outline-none focus:ring focus:ring-primary"
                                        placeholder="Enter Exam Preference..."
                                    />
                                    <button className="absolute right-3 top-4" type="button">
                                        <svg
                                            width="18"
                                            height="18"
                                            viewBox="0 0 18 18"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M2.12179 12.3665C3.3684 13.6149 5.02849 14.3643 6.78931 14.4736C8.55014 14.5828 10.2901 14.0444 11.6815 12.9598L16.4691 17.7473C16.6389 17.9113 16.8663 18.002 17.1023 18C17.3383 17.9979 17.5641 17.9032 17.7311 17.7363C17.898 17.5694 17.9926 17.3436 17.9947 17.1076C17.9967 16.8715 17.906 16.6441 17.742 16.4743L12.9545 11.6868C14.0873 10.2331 14.6228 8.40193 14.452 6.56689C14.2811 4.73184 13.4166 3.03109 12.0349 1.81149C10.6531 0.591898 8.85821 -0.0546788 7.01614 0.00362648C5.17408 0.0619317 3.42363 0.820728 2.12179 2.12526C1.44912 2.79759 0.915515 3.59586 0.55145 4.47448C0.187385 5.35309 0 6.29483 0 7.24588C0 8.19694 0.187385 9.13867 0.55145 10.0173C0.915515 10.8959 1.44912 11.6942 2.12179 12.3665ZM3.39475 3.40002C4.28471 2.51007 5.45571 1.95623 6.70823 1.83286C7.96075 1.70949 9.2173 2.02421 10.2638 2.72341C11.3103 3.42261 12.082 4.46303 12.4474 5.6674C12.8128 6.87177 12.7493 8.16558 12.2678 9.32839C11.7862 10.4912 10.9164 11.4511 9.80644 12.0445C8.69652 12.6379 7.41519 12.8281 6.18077 12.5827C4.94634 12.3373 3.83519 11.6714 3.03662 10.6986C2.23806 9.72585 1.80149 8.50627 1.8013 7.24768C1.79883 6.5325 1.93842 5.82395 2.21195 5.16314C2.48549 4.50233 2.88753 3.90242 3.39475 3.39822V3.40002Z"
                                                fill="#C3C3C3"
                                            />
                                        </svg>
                                    </button>
                                </div> */}
                <div className="relative">
                <h1 className="lg:text-4xl sm:text-3xl text-2xl  text-center  font-normal mt-2 font-primary mb-5">
                Achieve Your{" "}
                <span className="text-secondary font-semibold">Dreams</span>{" "}
                with the{" "}
                <span className="text-secondary font-semibold">
                  Best&nbsp;Teachers!
                </span>
              </h1>
                  <span className="absolute font-medium select-none p-3">
                    +91
                  </span>
                  <input
                    type="tel"
                    maxLength={10}
                    autoFocus={true}
                    value={mobileNum}
                    className="w-full p-3 pl-12 border border-[#C3C3C3] rounded focus:outline-none focus:ring focus:ring-primary"
                    onChange={(e) => {
                      if (!isNaN(Number(e.target.value))) {
                        setPhoneNumber(e.target.value);

                        setMobileNum(e.target.value);
                      }
                    }}
                    placeholder="Enter Your Mobile Number"
                  />

                  <svg
                    className="absolute right-3 top-4 hidden"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0ZM13.5338 6.93997L8.74921 11.7374L6.42259 9.41074C6.09715 9.08531 5.56951 9.08531 5.24408 9.41074C4.91864 9.73618 4.91864 10.2638 5.24408 10.5893L8.16074 13.5059C8.48649 13.8317 9.01474 13.8313 9.34004 13.5051L14.7139 8.1169C15.0389 7.79103 15.0382 7.26339 14.7123 6.93839C14.3864 6.61339 13.8588 6.6141 13.5338 6.93997Z"
                      fill="#2E90EF"
                    />
                    <path
                      d="M13.5338 6.93997L8.74921 11.7374L6.42259 9.41074C6.09715 9.08531 5.56951 9.08531 5.24408 9.41074C4.91864 9.73618 4.91864 10.2638 5.24408 10.5893L8.16074 13.5059C8.48649 13.8317 9.01474 13.8313 9.34004 13.5051L14.7139 8.1169C15.0389 7.79103 15.0382 7.26339 14.7123 6.93839C14.3864 6.61339 13.8588 6.6141 13.5338 6.93997Z"
                      fill="white"
                    />
                  </svg>
                </div>

                <button
                  className="bg-secondary rounded font-bold uppercase text-white p-3 hover:opacity-75 mt-2"
                // type="submit"
                >
                  Join Now
                </button>
                {error && (
                  <span className="text-secondary">
                    Please enter correct Number !!
                  </span>
                )}
              </form>
            </div>
          </section>
        )}
      </div>
    </div>
  );
};
export default LoginModal;
