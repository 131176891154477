import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../app/AxiosHandler";
import { loginNumberAPI, verifyOTPAPI } from "./api/authApi";
import { studentCourses } from "./enrollSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Navigate, useNavigate } from "react-router-dom";

const initialState = {
  user: null,
  token: localStorage.getItem("userToken"),
  isLoading: true,
  hasCourse: false,
  isError: false,
  errorMsg: "",
  otpValue: "",
  userNumber: "",
  data: "",
  isNumberCorrect: false,
  profileData: "",
  orders: "",
};

export const loginNumber = createAsyncThunk(
  "auth/loginNumber",
  async (phoneNumber) => {
    const response = await loginNumberAPI(phoneNumber);

    return response;
  }
);

export const OTPverify = createAsyncThunk(
  "auth/otp",
  async (data, thunkApi) => {
    const response = await verifyOTPAPI(data);

    localStorage.setItem("userToken", response?.token);
    // const decodeToken = jwtdecode(response?.token);
    // thunkApi.dispatch(studentEnrolledCourse({ studentId: decodeToken?._id }));
    thunkApi.dispatch(studentCourses());
    return response.data;
  }
);

export const updateProfile = createAsyncThunk(
  "update/profile",
  async (data, thunkApi) => {
    try {
      const response = await api.post(
        process.env.REACT_APP_UPDATE_PROFILE,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to home page
        localStorage.removeItem("token");

      }
      return thunkApi.rejectWithValue(error.message);
    }
  }
);


export const DeleteProfile = createAsyncThunk(
  'Delete/profile',
  async (data, thunkApi) => {
    try {
      const response = await api.post(
        process.env.REACT_APP_DELETE_PROFILE,
        data,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 200) {
        localStorage.removeItem('token');
      }

      return response.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);


export const getProfileData = createAsyncThunk(
  "profileData",
  async (data, { rejectWithValue }) => {
    try {
      const res = await api.post("students/profile", data).then((res) => res.data);
      return res.data;
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Redirect to home page

        localStorage.removeItem("token");
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getAllOrders = createAsyncThunk(
  "orders/get",
  async (data, { rejectWithValue }) => {
    try {
      const res = await api
        .post("payment/getall", data)
        .then((res) => res.data);
      return res.data;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateNumber: (state, action) => {
      state.userNumber = action.payload;
      state.isNumberCorrect = true;
    },
    getTokenFromLocal: (state, action) => {
      state.token = localStorage.getItem("userToken");
    },
    setLogout: (state, action) => {
      return {
        user: null,
        token: localStorage.getItem("userToken"),
        isLoading: true,
        hasCourse: false,
        isError: false,
        errorMsg: "",
        otpValue: "",
        userNumber: "",
        data: "",
        isNumberCorrect: false,
        profileData: "",
        orders: "",
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginNumber.fulfilled, (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
      state.isError = false;
    });

    builder.addCase(OTPverify.fulfilled, (state, action) => {
      state.token = localStorage.getItem("userToken");
      // state.data = action.payload;
      state.user = action?.payload[0]?.studentData;
    });

    builder
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.profileData = action.payload;
        state.isLoading = false;

      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.errorMsg = action.payload;
        state.isLoading = false;
      })
      .addCase(updateProfile.pending, (state, action) => {
        state.isLoading = true;
      });
    builder
      .addCase(DeleteProfile.fulfilled, (state, action) => {
        state.profileData = action.payload;
        state.isLoading = false;
      })
      .addCase(DeleteProfile.rejected, (state, action) => {
        state.errorMsg = action.payload;
        state.isLoading = false;
      })
      .addCase(DeleteProfile.pending, (state, action) => {
        state.isLoading = true;
      });

    builder
      .addCase(getProfileData.fulfilled, (state, action) => {
        state.profileData = action.payload;
      })
      .addCase(getProfileData.pending, (state, action) => {
        state.isLoading = action.payload;
      })
      .addCase(getProfileData.rejected, (state, action) => {
        state.isError = action.payload;
      });

    builder
      .addCase(getAllOrders.fulfilled, (state, action) => {
        state.orders = action.payload;
        state.isLoading = false;
      })
      .addCase(getAllOrders.rejected, (state, action) => {
        state.isError = action.payload;
      })
      .addCase(getAllOrders.pending, (state, aciton) => {
        state.isLoading = true;
      });
  },
});
export const userData = (state) => state?.user?.profileData[0];
export const { updateNumber, getTokenFromLocal, setLogout } = userSlice.actions;

export const orders = (state) => state?.user?.orders;

export default userSlice.reducer;
