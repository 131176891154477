import React, { useEffect, useMemo, useState } from "react";
import ReactHlsPlayer from "react-hls-player";
import { useDispatch, useSelector } from "react-redux";
import { io } from "socket.io-client";
import { getLiveById, playLive, streamInfo } from "../../features/liveSlice";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ChatBox from "./ChatBox";
import { teacherByCourseField, teachers } from "../../features/teacherSlice";
import useEnrolledData from "../../components/hooks/useEnrolledData";
import { getNews } from "../../features/doubtSlice";
import Blog from "../../components/Blog/Blog";
import Instructor from "../../components/Instructor/Instructor";
import soonImg from "../../assets/images/soon.jpg";
import '../../assets/css/App.css'

import LiveStreamingPlayer from "./LiveStreamingPlayer";
const PlayLiveVideo = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  // const liveStreamUrl = process.env.REACT_APP_BASE_URL + data;

  const { selectedId, subCourses, loadingState, selectedSubcourse } =
    useEnrolledData();

  const teachersData = useSelector(teachers);
  const newsData = useSelector((state) => state?.doubt?.newsData);
  const data = useSelector((state) => state?.liveStreams?.url);

  const user = useSelector((state) => state?.user?.user);

  useEffect(() => {
    dispatch(playLive({ liveStreamId: id }));
    dispatch(teacherByCourseField({ courseFieldId: selectedId }));
    dispatch(getLiveById({ liveStreamId: id }));
    dispatch(
      getNews({
        courseFieldId: selectedId,
      })
    );
  }, [id, selectedId]);
  const navigate = useNavigate();

  if (selectedSubcourse.isPaid === false) {
    return navigate("/plans");
  }

  return (
    <>
      <section className="bg-liveClasses bg-cover bg-no-repeat md:bg-center h-[242px] w-full relative">
        <div className="container max-w-[1200px] mx-auto px-4">
          <div className="absolute bottom-1/2 translate-y-2/4 font-primary font-bold text-white">
            <ol className="flex gap-2 items-center text-sm mb-4">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <svg
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L6 6L1 11"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </li>
              <li><a href="/mylearning">Courses</a></li>
              <li>
                <svg
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L6 6L1 11"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </li>
              <li><a href="/mylearning">Live Classes</a></li>
            </ol>
            <h1 className="lg:text-5xl text-3xl"><a href="/mylearning">Live Classes</a></h1>
          </div>
        </div>
      </section>

      <div className="items-center max-w-[1200px] mx-auto flex">
        <div className="mt-8 ml-auto">
          {!data ? (
            <img src={soonImg} alt="Error" />
          ) : (
            <div className="rounded-md">
              {/* <ReactHlsPlayer
                src={process.env.REACT_APP_BASE_URL + data}
                autoPlay={false}
                controls={true}
                width={"100%"}
                height={"778.21px"}
                /> */}
                
              <LiveStreamingPlayer
                url={`${process.env.REACT_APP_BASE_URL}${data}`}
                playing
                controls
                width="100%"
                height="500.21px"
              />
            </div>
          )}
        </div>
        {/* {data && ( */}
        <div className="lg:w-[300px] h-full mt-8">
          {<ChatBox id={id} courseFieldId={selectedId} />}
        </div>
        {/* )} */}
      </div>

      {/* instructors section */}
      <div className="container max-w-[1200px] mx-auto md:px-4 mt-8">
        <h2 className="text-black font-primary font-semibold text-3xl mb-5">
          Meet Our <span className="text-secondary font-bold">Instructors</span>
        </h2>
        <div className="flex flex-wrap my-6 lg:gap-[30px]">
          {teachersData &&
            teachersData.slice(0, 6)?.map((teacher, i) => {
              return <Instructor teacherData={teacher} />;
            })}
        </div>
        <div className="text-center mt-8">
          <a
            href="/insturctors"
            className="py-2 px-4 text-white bg-primary inline-block rounded font-medium font-primary text-base hover:opacity-75 lg:w-[142px] text-center"
          >
            VIEW MORE
          </a>
        </div>
      </div>

      {/* news section */}
      <section className="mt-8">
        <div className="container max-w-[1200px] mx-auto md:px-4 py-8">
          <h2 className="text-black font-primary font-semibold text-3xl mb-5">
            Find <span className="text-secondary font-bold">Latest News</span>
          </h2>
          <div className="grid sm:grid-cols-2 md:grid-cols-3 grid-cols-1  -mx-4 gap-y-4">
            {newsData &&
              newsData.slice(0, 3).map((news, i) => {
                const { title, newsImg, _id, createdAt, description } = news;
                return (
                  <div key={_id}>
                    <Blog
                      title={title}
                      img={newsImg}
                      date={createdAt}
                      _id={_id}
                      description={description}
                    />
                  </div>
                );
              })}
          </div>
          <div className="text-center mt-8">
            <a
              href="/news"
              className="py-2 px-4 text-white bg-primary inline-block rounded font-medium font-primary text-base hover:opacity-75 lg:w-[142px] text-center"
            >
              VIEW MORE
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default PlayLiveVideo;
