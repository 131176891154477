import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";

import api, { PostReq } from "../app/AxiosHandler";
import { ToastContainer, toast } from 'react-toastify';


// student's add course

export const enrollCourse = createAsyncThunk(
  "studentCourse/add",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await api
        .post(`${process.env.REACT_APP_ENROLL_NEW_COURSE}`, data)
        .then(() => {
          dispatch(
            studentCourses({ courseFieldId: data.courseFieldId })
            // studentEnrolledCourse()
          );
        });
      // const courseFieldId= data
      //       dispatch(studentEnrolledCourse({courseFieldId}))

      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
// student selected courses data
export const studentEnrolledCourse = createAsyncThunk(
  "studentCourse/get",
  async (courseFieldId) => {
    // http://localhost:4000/api/students/enrolled/course/website

    const response = await api
      .post("students/enrolledCourses", {
        courseFieldId: courseFieldId?.courseFieldId,
      })
      .then((res) => res.data.data);

    return response.data;
  }
);

// updated api for student enrolled courses

export const studentCourses = createAsyncThunk(
  "stdntEnrollCourses/get",
  async (data, { rejectWithValue }) => {
    try {
      const res = await api
        .post("students/enrolled/coursefield", data)
        .then((res) => res.data);

      return res;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

// remove enrolled course

export const removeCourse = createAsyncThunk(
  "student/courseDelete",
  async (data, thunkApi) => {
    try {
      const { courseFieldId } = data;

      const response = await api.post(
        process.env.REACT_APP_DELETE_ENROLL,
        data
      );
      if (response.status === 200) return courseFieldId;
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

// get subjects from selected course
export const getPlaylists = createAsyncThunk(
  "student/playlists",
  async (data, thunkApi) => {
    try {
      const response = await api.post(process.env.REACT_APP_GETPLAYLISTS, data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getNotificATION = createAsyncThunk(
  "student/getNotificATION",
  async (data, thunkApi) => {
    try {
      // api/notifications/getAll
      const response = await api.post('/notifications/getAll', data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const getNotificATIONreadAll = createAsyncThunk(
  "student/getNotificATIONreadAll",
  async (data, thunkApi) => {
    try {
      // api/notifications/getAll
      const response = await api.post('/notifications/readAll', data);
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);
export const getNotificATIONDelete = createAsyncThunk(
  "student/getNotificATIONDelete",
  async (data, thunkApi) => {
    try {
      // api/notifications/getAll
      const response = await api.post('/notifications/delete', data);
      return data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getExtraCourses = createAsyncThunk(
  "extraCourse",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api.post(process.env.REACT_APP_GETPLAYLISTS, data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getStudentById = createAsyncThunk("Student/Id", async () => {
  const response = await api
    .post(process.env.REACT_APP_STUDENTBYID)
    .then((res) => res.data);
  return response.data;
});

// add new teacher request
export const addTeacher = createAsyncThunk(
  "teacher/add",
  async (data, { rejectWithValue }) => {
    try {
      const res = await api.post(process.env.REACT_APP_ADD_TEACHER, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return res.data;
    } catch (error) {
      toast.error(error.response.data.msg);

      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  data: "",
  isLoading: true,
  isError: false,
  notifications: "",

  selectedCoursesData: "",
  studentData: "",
  addTeacher: "",
  profileData: "",
  isStudentDataLoading: false,
  selectedPlaylistData: [],
  allNotifications: [],
  extraCourses: "",
  hasCourse: false,
  enrollLoading: false,
};

const enrollSlice = createSlice({
  name: "enrollSlice",
  initialState,
  reducers: {
    handleNotification: (state, action) => {

      state.notifications = [action.payload];
    },
  },
  extraReducers: (builder) => {
    // enroll course
    builder.addCase(enrollCourse.fulfilled, (state, action) => {
      state.data = action.payload;
      state.selectedCoursesData = state?.data?.data[0]?.courses;
      state.isLoading = false;

      state.isError = false;
    });
    builder.addCase(enrollCourse.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(enrollCourse.rejected, (state, action) => {
      state.isLoading = false;
      state.data = action.payload;
      state.isError = true;
    });

    // student selected courses
    builder.addCase(studentEnrolledCourse.fulfilled, (state, action) => {
      state.studentData = action.payload;
      state.selectedCoursesData = state?.studentData?.data[0]?.courses;

      state.isLoading = false;
      state.isError = false;
    });
    builder.addCase(studentEnrolledCourse.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(studentEnrolledCourse.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });

    builder.addCase(getStudentById.pending, (state, action) => {
      state.isStudentDataLoading = true;
    });
    builder.addCase(getStudentById.fulfilled, (state, action) => {
      state.isStudentDataLoading = false;
      state.studentData = action.payload;
    });

    builder
      .addCase(removeCourse.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedCoursesData = state.selectedCoursesData.filter(
          (item) => item._id !== action?.payload
        );
      })
      .addCase(removeCourse.rejected, (state, actiotn) => {
        state.isError = actiotn.payload;
      })
      .addCase(removeCourse.pending, (state, action) => {
        state.isLoading = true;
      });

    builder
      .addCase(getPlaylists.fulfilled, (state, action) => {
        state.selectedPlaylistData = [...action.payload.data];
        state.isLoading = false;
      })
      .addCase(getPlaylists.rejected, (state, action) => {
        state.isError = action.payload;
      })
      .addCase(getPlaylists.pending, (state, action) => {
        state.isLoading = true;
      });
    builder
      .addCase(getNotificATION.fulfilled, (state, action) => {
        state.allNotifications = [...action.payload.data];
        state.isLoading = false;
      })
      .addCase(getNotificATION.rejected, (state, action) => {
        state.isError = action.payload;
      })
      .addCase(getNotificATION.pending, (state, action) => {
        state.isLoading = true;
      });
    builder
      .addCase(getNotificATIONreadAll.fulfilled, (state, action) => {
        const { notificationIds } = action.payload || {};

        console.log(JSON.parse(JSON.stringify(state, 'state')))
        if (notificationIds) {
          state.allNotifications = state.allNotifications.map((notification) => {
            if (notificationIds.includes(notification._id)) {
              return { ...notification, read: true };
            }
            return notification;
          });
        }

        state.isLoading = false;
      })
      .addCase(getNotificATIONreadAll.rejected, (state, action) => {
        state.isError = action.payload;
      })
      .addCase(getNotificATIONreadAll.pending, (state, action) => {
        state.isLoading = true;
      });
    builder
      .addCase(getNotificATIONDelete.fulfilled, (state, action) => {
        console.log(action,'action')
        const { notificationIds } = action.payload || {};

        console.log(notificationIds,'notificationIds')
        if (notificationIds) {
          console.log('1')

          state.allNotifications = state.allNotifications.filter((notification) => {
            console.log(state.allNotifications, 'allNotifications')

       
            return !notificationIds.includes(notification._id);
          });
        }

        state.isLoading = false;
      })
      .addCase(getNotificATIONDelete.rejected, (state, action) => {
        state.isError = action.payload;
      })
      .addCase(getNotificATIONDelete.pending, (state, action) => {
        state.isLoading = true;
      });

    builder
      .addCase(getExtraCourses.fulfilled, (state, action) => {
        state.extraCourses = [...action.payload.data];
        state.isLoading = false;
      })
      .addCase(getExtraCourses.rejected, (state, action) => {
        state.isError = action.payload;
      });

    builder.addCase(addTeacher.pending, (state, { payload }) => {
      state.isLoading = true;
    });

    builder.addCase(addTeacher.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      toast.success('Teacher added successfully:', payload);
      // alert('done')

      // alert('Teacher added successfully!');
    });

    builder.addCase(addTeacher.rejected, (state, { payload }) => {
      state.isLoading = false
      state.error = payload
    })


    builder.addCase(studentCourses.fulfilled, (state, action) => {
      state.studentData = action.payload;
      state.hasCourse = state?.studentData?.data[0]?.courses?.length > 0;
      state.selectedCoursesData = state?.studentData?.data[0]?.courses;

      state.isLoading = false;
      state.isError = false;
      state.enrollLoading = false;
    });
    builder.addCase(studentCourses.pending, (state, action) => {
      state.isLoading = true;
      state.enrollLoading = true;
    });
    builder.addCase(studentCourses.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
    });
  },
});

export const enrolledData = (state) => state?.enroll?.studentData;
export const loading = (state) => state?.enroll?.enrollLoading;
export const { handleNotification } = enrollSlice.actions;

export const notificationsData = (state) => state?.enroll?.notifications;

export default enrollSlice.reducer;
