import React, { useState } from "react";
import displayRazorpay from "../../utils/razorPay";

const PriceCard = ({ props, planDuration, coursefieldId }) => {
  // console.log(props,'props')

    const { _id, amount, description, planTypes, planFeatures } = props;

    const [onHover, setOnHover] = useState(false);

    // console.log(onHover, "line11")

    return (
      <div className=" flex flex-col gap-2  mt-5 mx-5 transition-all duration-300 ease-in-out bg-white  hover:-translate-y-14
      hover:rounded-lg hover:shadow-lg hover:scale-110 py-3 px-4 hover:px-8 hover:py-11 "
      onMouseEnter={() => setOnHover(true)}
      onMouseLeave={() => setOnHover(false)}
      >
        <h1 className={`font-bold font-primary text-3xl text-[#333] ${onHover ? 'text-center' : ''} `}>
          {planTypes}
        </h1>
        <p className={`text-[#383838] font-medium text-sm font-secondary ${onHover ? 'text-center' : ''}`}>
          {description}
        </p>
        <button className="bg-primary mb-3 mt-2 text-white font-medium text-base font-primary text-center py-2 px-1 rounded shadow-md uppercase" onClick={() => displayRazorpay(planDuration, _id, coursefieldId)}>
          Get Started
        </button>
        <h1 className="font-bold font-primary text-3xl text-[#333]">
          &#8377; {amount}<span className="text-[#85B5E0] ">/month</span>{" "}
        </h1>
        <h1 className="font-bold font-primary text-1xl text-[#333]">
          &#8377; 18%<span className="text-[#85B5E0] ">Tax</span>{" "}
        </h1>
        <ul className="ml-6">
          {planFeatures &&
            planFeatures.map((feature, i) => {
              return <li className="list-disc text-[#333] font-primary text-base font-medium ">{feature?.name}</li>;
            })}
        </ul>
      </div>
    );
  };

export default PriceCard;
