import React, { useEffect, useState } from "react";
import arrowIcon from '../../assets/images/arrow.png'
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { getExamAnswers, getTestAnswers, getVideoTestAnswers } from "../../features/testSlice";
import ImageViewer from "../../components/Modal/imageViewer";

const ViewAnswers = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const testData = useSelector((state) => state?.testlist?.singleTest);
    const Answers = useSelector((state) => state?.testlist?.answersData);
    const questions = location?.state?.questions;

    const [isOpen, setIsOpen] = useState(false);
    const [solutionImage, setSolutionImage] = useState("");

    useEffect(() => {
        if (location?.state?.examType === "Exam") {
            dispatch(getExamAnswers({
                "testListId": location?.state?.testListId,
            }))
        }
        else if (location?.state?.examType === "Video Test") {
            dispatch(getVideoTestAnswers({
                "qnalistId": location?.state?.testListId,
            }))
        }
        else {
            dispatch(getTestAnswers({
                "testListId": location?.state?.testListId,
                "subjectId": location?.state?.subjectId
            }))
        }
    }, [dispatch, location, location?.state?.examType])

    // useEffect(() => {
    //     window.onpopstate = (event) => {
    //         window.history.pushState(null, null, '/mylearning')
    //         window.location.reload();
    //     };
    // }, [window])

    useEffect(() => {
        window.addEventListener("popstate", (event) => {
            event.preventDefault();
            navigate('/mylearning');
            // window.history.pushState(null, null, '/mylearning');
            // window.location.reload();
        });
    }, [window])

    const options = ['a', 'b', 'c', 'd'];

    // console.log(isOpen, "line4899")

    return (
        <React.Fragment>
            <div className="">
                <div className="bg-[#FBFBFB]">
                    <div className="flex justify-between items-center font-primary font-bold py-11 max-w-[1200px] mx-auto">
                        <div className="">
                            <div className="flex gap-2 text-sm text-[#8F98A8] items-center font-semibold cursor-pointer">
                                <p onClick={() => navigate('/')}>Home</p>
                                <img src={arrowIcon} className="h-3" />
                                <p onClick={() => navigate('/mylearning')}>Test</p>
                                <img src={arrowIcon} className="h-3" />
                                <p>View Answers</p>
                            </div>
                            <p className="text-primary text-4xl pt-2">View Answers</p>
                        </div>
                        <div className="bg-primary text-white text-base px-4 py-2 font-medium rounded ">
                            <button onClick={() => navigate('/')} >Go to Home</button>
                        </div>
                    </div>
                </div>

                <div className="max-w-[1200px] mx-auto py-8">
                    {questions &&
                        questions.map((ques, i) => {
                            return (
                                <div className="font-primary w-full" key={i}>
                                    <div className="">
                                        <div className="flex justify-between">
                                            <div>
                                                <p className="font-bold text-xl">
                                                    {"Question " + (i + 1)}
                                                </p>
                                                <h1 className="text-[#383838] text-lg font-semibold break-words mt-2">
                                                    {ques.question}
                                                </h1>
                                                {ques?.questionfileurl ?
                                                    <div className="min-h-[55px] h-[200px] my-2">
                                                        <img src={ques?.questionfileurl} className="h-full" />
                                                    </div>
                                                    : ""
                                                }
                                            </div>
                                            {Answers[i]?.studentAnswer === null ?
                                                <div className="text-[#FF9900] font-semibold">
                                                    Not Attempted
                                                </div>
                                                : ""
                                            }
                                        </div>
                                        <div>
                                            {options?.map((ans, index) => {
                                                return (
                                                    <>
                                                        {ques[ans]?.includes(".jpg") || ques[ans]?.includes(".png") || ques[ans]?.includes(".jpeg") || ques[ans]?.includes(".webp") ?
                                                            <div
                                                                key={index}
                                                                className={`min-h-[55px] lg:h-[192px] lg:w-[649px] rounded border border-[#ADCBF900] shadow-md shadow-[#0064FD2E] my-6 py-3 text-[#383838]
                                                                  ${ques?.correctAnswer === ans ? 'bg-[#F6FBF5]' : ''}
                                                                  ${Answers[i]?.studentAnswer ? Answers[i]?.studentAnswer === ans ?
                                                                        Answers[i]?.studentAnswer === ques?.correctAnswer ? 'bg-[#F6FBF5]' : 'bg-[#FEF6F7]'
                                                                        : '' : ''}
                                   
                                                                  font-secondary text-base font-normal px-6 flex items-center gap-3`}
                                                            >
                                                                <input
                                                                    className={` h-4 w-4
                                                                      ${Answers[i]?.studentAnswer ? Answers[i]?.studentAnswer === ans ?
                                                                            Answers[i]?.studentAnswer === ques?.correctAnswer ? ' appearance-none bg-[#4BB543] rounded-full ring-2 ring-offset-4 ring-[#4BB543] '
                                                                                : ' appearance-none bg-[#EC4A5E] rounded-full ring-2 ring-offset-4 ring-[#EC4A5E] '
                                                                            : '' : ''}
                                                                      ${ques?.correctAnswer === ans ? ' appearance-none bg-[#4BB543] rounded-full ring-2 ring-offset-4 ring-[#4BB543] ' : ''} `}
                                                                    type="radio"
                                                                    name=""
                                                                    id=""
                                                                    key={ans._id}
                                                                    value={ans._id}
                                                                    disabled
                                                                />
                                                                <img src={ques[ans]} className="h-full" />
                                                            </div>
                                                            :
                                                            <div
                                                                key={index}
                                                                className={`lg:min-h-[55px] lg:w-[649px] rounded border border-[#ADCBF900] shadow-md shadow-[#0064FD2E] my-6 py-3 text-[#383838]
                                                                    ${ques?.correctAnswer === ans ? 'bg-[#F6FBF5]' : ''}
                                                                    ${Answers[i]?.studentAnswer ? Answers[i]?.studentAnswer === ans ?
                                                                        Answers[i]?.studentAnswer === ques?.correctAnswer ? 'bg-[#F6FBF5]' : 'bg-[#FEF6F7]'
                                                                        : '' : ''}
                                                                     font-secondary text-base font-normal px-6 flex items-center gap-3`}
                                                            >
                                                                <input
                                                                    className={` h-4 w-4
                                                                           ${Answers[i]?.studentAnswer ? Answers[i]?.studentAnswer === ans ?
                                                                            Answers[i]?.studentAnswer === ques?.correctAnswer ? ' appearance-none bg-[#4BB543] rounded-full ring-2 ring-offset-4 ring-[#4BB543] '
                                                                                : ' appearance-none bg-[#EC4A5E] rounded-full ring-2 ring-offset-4 ring-[#EC4A5E] '
                                                                            : '' : ''}
                                                                           ${ques?.correctAnswer === ans ? ' appearance-none bg-[#4BB543] rounded-full ring-2 ring-offset-4 ring-[#4BB543] ' : ''} `}
                                                                    type="radio"
                                                                    name=""
                                                                    id=""
                                                                    key={ans._id}
                                                                    value={ans._id}
                                                                    disabled
                                                                />
                                                                <p className="">{ques[ans]} </p>
                                                            </div>
                                                        }
                                                    </>
                                                );
                                            })}
                                        </div>
                                        <div>
                                            <h1 className="font-semibold text-[#4BB543] text-lg">Answer</h1>
                                            {/* {console.log(Answers[i], "line16777")} */}
                                            <p className="font-medium">{Answers[i]?.questionId?.solution}</p>
                                            {Answers[i]?.questionId?.solutionfileurl ?
                                                <div className="lg:h-[70.42px] lg:w-[70.42px] my-2 cursor-pointer"
                                                    onClick={() => {
                                                        setIsOpen(true)
                                                        setSolutionImage(Answers[i]?.questionId?.solutionfileurl)
                                                    }}>
                                                    <img src={Answers[i]?.questionId?.solutionfileurl} className="h-full rounded-lg" />
                                                </div>
                                                : ""
                                            }
                                        </div>
                                        {i === questions?.length - 1 ? "" :
                                            <div className="border border-black border-dashed my-6" ></div>
                                        }
                                    </div>
                                    <div className="">
                                        <ImageViewer openModal={isOpen} image={solutionImage} setIsOpen={setIsOpen} />
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>


        </React.Fragment>
    );
}
export default ViewAnswers;