import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../app/AxiosHandler";
import { toast } from "react-toastify";

export const addBookmark = createAsyncThunk(
  "bookmardAdd",
  async (data, { rejectWithValue }) => {
    try {
      const res = await api.post("bookmark/add", data).then((res) => res.data);
      toast.success(res.error ? res.error : res.msg);
      return res.data;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const getAllBookmark = createAsyncThunk(
  "bookmarkGet",
  async (data, { rejectWithValue }) => {
    try {
      const res = await api
        .post("bookmark/getall", data)
        .then((res) => res.data);

      return res.data;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const removeBookmark = createAsyncThunk(
  "removeBookmark",
  async (data, { rejectWithValue }) => {
    try {
      const res = await api
        .post("bookmark/delete", data)
        .then((res) => res.data);
      toast.error(res.msg);
      return data.bookmarkId;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

const initialState = {
  isLoading: false,
  isError: false,
  studyMaterialBookmark: [],
  videoBookmark: [],
  syllabusBookmark: [],
  data: [],
  addBookmarkSuccess: false,
  removeBookmarkSuccess: false
};

const bookmarkSlice = createSlice({
  name: "bookmarks",
  initialState,
  reducers: {
    resetSuccess: (state, action) => {
      return {
        ...state,
        addBookmarkSuccess: false,
        removeBookmarkSuccess: false
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBookmark.fulfilled, (state, action) => {
        state.videoBookmark = action.payload?.video;
        state.studyMaterialBookmark = action.payload;
        state.syllabus = action?.payload?.syllabus;
        state.isLoading = false;
      })
      .addCase(getAllBookmark.rejected, (state, action) => {
        state.isError = action.payload;
      })
      .addCase(getAllBookmark.pending, (state, action) => {
        state.isLoading = true;
      })

    builder
      .addCase(addBookmark.fulfilled, (state, action) => {
        state.data = action.payload;
        state.addBookmarkSuccess = true;
      })
      .addCase(addBookmark.rejected, (state, action) => {
        state.isError = action.payload;
      })
      .addCase(addBookmark.pending, (state) => {
        state.isLoading = true;
      });

    builder.addCase(removeBookmark.fulfilled, (state, action) => {
      state.data = action.payload;
      state.removeBookmarkSuccess = true;
      state.isLoading = false;

      if (Array.isArray(state.studyMaterialBookmark)) {
        state.studyMaterialBookmark = state.studyMaterialBookmark.filter(
          (item) => item._id !== action.payload
        );
      } else {
        state.studyMaterialBookmark = [];
      }
    })
      .addCase(removeBookmark.rejected, (state, action) => {
        state.isError = action.payload;
      })
      .addCase(removeBookmark.pending, (state, action) => {
        state.isLoading = true;
      });
  },
});

export const { resetSuccess } = bookmarkSlice.actions

export const videoBookmark = (state) => state?.bookmarks?.videoBookmark;
export const studyMaterialBookmark = (state) =>
  state?.bookmarks?.studyMaterialBookmark;

export default bookmarkSlice.reducer;
