import React, { useRef, useState, useEffect } from 'react';

const VideoPlayer = ({ url }) => {
    const [progressWidth, setProgressWidth] = useState(0);
    const [bufferedWidth, setBufferedWidth] = useState(0);
    const videoRef = useRef(null);

    console.log(url, 'url');

    useEffect(() => {
        const handleTimeUpdate = () => {
            if (videoRef.current) {
                const { currentTime, duration } = videoRef.current;
                const progress = (currentTime / duration) * 100;
                setProgressWidth(progress);
            }
        };

        const handleProgress = () => {
            if (videoRef.current) {
                const { buffered, duration } = videoRef.current;
                if (buffered.length > 0) {
                    const bufferedEnd = buffered.end(buffered.length - 1);
                    const bufferedPercent = (bufferedEnd / duration) * 100;
                    setBufferedWidth(bufferedPercent);
                }
            }
        };

        if (videoRef.current) {
            videoRef.current.addEventListener("timeupdate", handleTimeUpdate);
            videoRef.current.addEventListener("progress", handleProgress);
        }

        return () => {
            if (videoRef.current) {
                videoRef.current.removeEventListener("timeupdate", handleTimeUpdate);
                videoRef.current.removeEventListener("progress", handleProgress);
            }
        };
    }, []);

    const handleProgressBarClick = (event) => {
        if (videoRef.current) {
            const boundingRect = videoRef.current.getBoundingClientRect();
            const offsetX = event.clientX - boundingRect.left;
            const percentClicked = offsetX / boundingRect.width;
            videoRef.current.currentTime = percentClicked * videoRef.current.duration;
        }
    };

    return (
        <div className="video-container">
            <video
                ref={videoRef}
                className="video-player"
                width="100%"
                height="auto"
                autoPlay
                controls
                controlsList="nodownload"
            >
                <source
                    src={process.env.REACT_APP_BASE_URL + url}
                />
                Your browser does not support the video tag.
            </video>

            <div className="progress-bar" onClick={handleProgressBarClick}>
                <div className="progress" style={{ width: `${progressWidth}%` }} />
                <div className="buffered" style={{ width: `${bufferedWidth}%` }} />
            </div>
        </div>
    );
}

export default VideoPlayer;
