import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../app/AxiosHandler";

// Initial state for the teacherSlice
const initialState = {
  teacher: null,
  teacherLoading: false,
  teacherError: null,
  teachersByCourseField: [],
  teachersByCourseFieldLoading: false,
  teachersByCourseFieldError: null,
};

export const aboutTeacher = createAsyncThunk(
  "teacher/aboutTeacher",
  async (data, { rejectWithValue }) => {
    try {
      // Perform an API call to fetch teacher information based on 'data'
      const response = await api
        .post("teachers/aboutus", data)
        .then((res) => res.data);

      return response.data;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

// Async Thunk for fetching teachers by course field
export const teacherByCourseField = createAsyncThunk(
  "teacher/teacherByCourseField",
  async (data, { rejectWithValue }) => {
    try {
      // Perform an API call to fetch teachers by course field based on 'data'
      const response = await api
        .post("teachers/bycoursefield", data)
        .then((res) => res.data);

      return response.data;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

const teacherSlice = createSlice({
  name: "teacher",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(aboutTeacher.pending, (state) => {
        state.teacherLoading = true;
        state.teacherError = null;
      })
      .addCase(aboutTeacher.fulfilled, (state, action) => {
        state.teacher = action.payload;
        state.teacherLoading = false;
      })
      .addCase(aboutTeacher.rejected, (state, action) => {
        state.teacherLoading = false;
        state.teacherError = action.error.message;
      })
      .addCase(teacherByCourseField.pending, (state) => {
        state.teachersByCourseFieldLoading = true;
        state.teachersByCourseFieldError = null;
      })
      .addCase(teacherByCourseField.fulfilled, (state, action) => {
        state.teachersByCourseField = action.payload;
        state.teachersByCourseFieldLoading = false;
      })
      .addCase(teacherByCourseField.rejected, (state, action) => {
        state.teachersByCourseFieldLoading = false;
        state.teachersByCourseFieldError = action.error.message;
      });
  },
});



export const teachers = state => state?.teacher?.teachersByCourseField 


export const oneTeacher = state => state?.teacher?.teacher

// Export the actions and reducer
export const {} = teacherSlice.actions;
export default teacherSlice.reducer;
