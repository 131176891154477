import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSubjects } from "../../features/getCourseSlice";
import {
  getChapterListBySubject,
  getPlaylistByChapter,
  resetData,
} from "../../features/playlistSlice";

import Loader from "../../components/Loader";
import NoDataFound from "../../components/NoDataFound";
import useEnrolledData from "../../components/hooks/useEnrolledData";
import PlaylistCard from "./PlaylistCard";
import userprofile from '../../assets/images/UserProfile.png'

const ViewCourses = () => {
  // const studentGoalsData = useSelector(
  //   (state) => state?.enroll?.studentData[0]
  // );

  // const getSelectedId = studentGoalsData?.courses
  //   .flatMap((course) => course.courseFields)
  //   .find((courseField) => courseField.isSelected);

  const { loadingState, selectedId, subCourses } = useEnrolledData();

  const subjectsData = useSelector((state) => state?.course?.subjectData);

  const [activeTab, setTab] = useState(0);
  const dispatch = useDispatch();

  const chaptersList = useSelector((state) => state.playlist?.chapters?.data);

  const playlistData = useSelector((state) => state.playlist?.playlists?.data);




  const loading = useSelector((state) => state?.playlist?.loading);

  const [tabId, setTabId] = useState(
    subjectsData && subjectsData?.data[0]?._id
  );

  const [chapterName, setChapterName] = useState("");

  useEffect(() => {
    if (chaptersList && chaptersList?.length > 0) {
      setChapterName(chaptersList[0]?.playlistName);
    } else {
      setChapterName("");
    }
  }, [chaptersList]);

  useEffect(() => {
    if (loadingState === false) {
      dispatch(getSubjects({ courseFieldId: selectedId })).then(
        ({ payload }) => {
          const { data } = payload;
          // setTabId()
          dispatch(
            getChapterListBySubject({
              subjectId: data?.at(0)?._id,
            })
          );
        }
      );
      // dispatch(getPlaylists({ courseFieldId: selectedId }));
    }
    return () => dispatch(resetData())
  }, [selectedId, dispatch]);

  useEffect(() => {
    dispatch(
      getPlaylistByChapter({
        playlistId: chaptersList && chaptersList[0]?._id,
        playlistType: 'recorded'
      })
    );
  }, [chaptersList, dispatch]);


  return (
    <div>
      <div className="items-center sm:items-start">
        {/* <h1 className="text-2xl sm:text-3xl md:text-4xl font-semibold capitalize font-primary">
          Lorem Ipsum <span className="text-secondary"> Dolor Sit amet</span>{" "}
        </h1> */}

        <div className="justify-center sm:justify-start flex flex-wrap gap-5">
          {subjectsData &&
            subjectsData?.data?.map((item, i) => {
              return (
                <button
                  key={i}
                  className={`font-medium text-sm font-primary p-2 px-5 border-2 border-primary rounded
                  ${activeTab === i
                      ? ` bg-primary text-white`
                      : `bg-white text-primary`
                    }`}
                  onClick={() => {
                    setTab(i);
                    setTabId(item?._id);
                    dispatch(getChapterListBySubject({ subjectId: item?._id }));
                  }}
                >
                  {item?.subjectName}
                </button>
              );
            })}
        </div>

        <div className="flex justify-between mt-5 items-center">
          <p className="font-primary font-semibold text-2xl">{chapterName || ""}</p>
          <select
            name=""
            placeholder="Select Chapter"
            className="border border-[#C3C3C3] rounded p-2 mx-8 lg:w-52 text-sm font-medium text-black outline-none font-primary"
            onChange={(e) => {
              dispatch(
                getPlaylistByChapter({
                  playlistId: e.target.value,
                  playlistType: "recorded",
                })
              ).then((res) => {
            
                if(res.payload.data){

                  setChapterName(res?.payload?.data[0]?.playlistName);
                }
              });
            }}
          >
            {chaptersList && chaptersList?.length ? (
              chaptersList?.map((item, i) => {
                return (
                  <option
                    key={item?._id}
                    value={item?._id}
                    className="px-3 py-1 border border-[#C3C3C3] rounded-none"
                  >
                    {item?.playlistName}{" "}
                  </option>
                );
              })
            ) : (
              <option>Select Chapter</option>
            )}
          </select>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <>
            {playlistData ? (
              <div className="grid md:grid-cols-2 lg:flex lg:flex-wrap gap-7 mt-7">
                {playlistData?.map((playlist, i) => {
                  return playlist?.videosDetails?.map((video, index) => {
                    console.log(video, playlist)
                    const data = { video, playlist };
                    return (
                      <div key={index}>
                        <PlaylistCard props={data} />
                      </div>
                    );
                  });
                })}
              </div>
            ) : (
              <NoDataFound />
            )}
          </>
        )}

        <div></div>
      </div>
    </div>
  );
};

export default ViewCourses;
