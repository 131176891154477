import api from "../app/AxiosHandler";

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

//  take student info from uer profile
export default async function displayRazorpay(
  planDurationId,
  planId,
  courseFieldId
) {
  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }

  let result = "";

  try {
    if(!planDurationId || !planId || !courseFieldId){
      alert("Please select Course and plan duration")
    }else{

      result = await api
        .post("/order/create", {
          planDurationId,
          planId,
          courseFieldId,
        })
        .then((res) => res.data.data).catch(error => console.log(error));
    }
  } catch (error) {
    console.log(error)
  }



  if (!result) {
    alert("Server error. Are you online?");
    return;
  }

  const { amount, orderId,  currency, } = result;

  const options = {
    key:"rzp_test_J1rXwbM6OO7Hub" || process.env.REACT_APP_RAZORPAY_KEY_ID,
    currency: currency,
    // amount: amount,
    name: "Learn Code Online",
    description: "Wallet Transaction",
    image: "",
    order_id: orderId,
    handler: async function (response) {
      // console.log(response)
      
      const data = {
        courseFieldId: "",
        totalAmount: amount,
        planDuration: planDurationId,
        planId,
        paymentStatus: "Success",
        paymentMethod: "Bank",
        orderId: response?.razorpay_order_id,
        paymentId: response?.razorpay_payment_id,
        paymentSignature: response?.razorpay_signature, 
        courseFieldId:courseFieldId,

   
      };

      try {
        await api.post("subscription/add", data);
        // Redirect to '/mypurchase' page after successful subscription
        window.location.replace('/mypurchase');
      } catch (error) {
        console.error('Error adding subscription:', error);
        // Handle error scenario
      }
    },
    prefill: {
      name: "Anirudh Jwala",
      email: "anirudh@gmail.com",
      contact: "7494920753",
    },
  };

  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
}
