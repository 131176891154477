import WebsiteLoader from '../../assets/images/fullLoader.gif'

const Loader = () => {
    return (
        <div className='flex justify-center w-full items-center h-[350px] mt-10 '>
            <img src={WebsiteLoader} className='h-20 w-20' />
        </div>
    )
}

export default Loader;