import React, { useEffect } from "react";
import Blog from "../../components/Blog/Blog";
import Instructor from "../../components/Instructor/Instructor";
import AboutInstructor from "../../components/aboutInstructor/aboutInstructor";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getNews } from "../../features/doubtSlice";
import {
  aboutTeacher,
  oneTeacher,
  teacherByCourseField,
  teachers,
} from "../../features/teacherSlice";
import useEnrolledData from "../../components/hooks/useEnrolledData";

const InstructorDetail = () => {
  const newsData = useSelector((state) => state?.doubt?.newsData);
  const teachersData = useSelector(teachers);
  const singleTeacher = useSelector(oneTeacher);
  console.log(singleTeacher,';singleTeacher')

  const dispatch = useDispatch();
  const { teacherId } = useParams();

  // const studentGoalsData = useSelector(
  //   (state) => state?.enroll?.studentData[0]
  // );
  // const getSelectedId = studentGoalsData?.courses
  //   .flatMap((course) => course.courseFields)
  //   .find((courseField) => courseField.isSelected);
  const { loadingState, selectedId, subCourses } = useEnrolledData();

  useEffect(() => {
    dispatch(aboutTeacher({ teacherId: teacherId }));

    dispatch(
      getNews({
        courseFieldId: selectedId,
      })
    );
    if (loadingState === false)
      dispatch(teacherByCourseField({ courseFieldId: selectedId }));
  }, [selectedId, dispatch, teacherId]);

  const {
    additionalInfo,
    designation,
    experience,
    fullname,
    subjects,
    gender,
    image,
    qualification,
    teacherBgImage,
  } = singleTeacher || "";

  return (
    <div className="font-secondary ">
      {/* banner section   */}
      <section className="bg-instructorDetail bg-cover bg-no-repeat md:bg-center h-[242px] w-full relative">
        <div className="container  px-4 max-w-[1200px] mx-auto">
          <div className="absolute bottom-1/2 translate-y-2/4 font-primary font-bold text-white">
            <ol className="flex gap-2 items-center text-sm mb-4">
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <svg
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L6 6L1 11"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </li>
              <li>Instructor Details</li>
            </ol>
            <h1 className="lg:text-5xl text-3xl">Instructor Details</h1>
          </div>
        </div>
      </section>

      <div className="max-w-[1200px] mx-auto container">
        <AboutInstructor
          name={fullname}
          designation={designation}
          qualification={qualification}
          experience={experience}
          subjects ={subjects}
          image={image}
          additionalInfo={additionalInfo}
          teacherBgImage={image}
        />

        {/* instructor section */}
        <div className="container max-w-[1200px] mx-auto md:px-4 mt-8">
          <h2 className="text-black font-primary font-semibold text-3xl mb-5">
            Meet Our <span className="text-secondary font-bold">Instructors</span>
          </h2>
          <div className="flex flex-wrap my-6 lg:gap-[30px]">
            {teachersData &&
              teachersData.slice(0, 6)?.map((teacher, i) => {
                const { teacherId } = teacher;

                return <Instructor teacherData={teacher} />;
              })}
          </div>
          <div className="text-center mt-8">
            <a
              href="/insturctors"
              className="py-2 px-4 text-white bg-primary inline-block rounded font-medium font-primary text-base hover:opacity-75 lg:w-[142px] text-center"
            >
              VIEW MORE
            </a>
          </div>
        </div>

        {/* Blog section */}
        <section className="mb-6">
          <div className="container max-w-[1200px] mx-auto md:px-4 py-8">
            <h2 className="text-black font-primary font-semibold text-3xl mb-5">
              Find <span className="text-secondary font-bold">Latest News</span>
            </h2>
            <div className="grid sm:grid-cols-2 md:grid-cols-3 grid-cols-1  -mx-4 gap-y-4">
              {newsData &&
                newsData.slice(0, 3).map((news, i) => {
                  const { title, newsImg, _id, createdAt, description } = news;
                  return (
                    <div key={_id}>
                      <Blog
                        title={title}
                        img={newsImg}
                        date={createdAt}
                        _id={_id}
                        description={description}
                      />
                    </div>
                  );
                })}
            </div>
            <div className="text-center mt-8">
              <a
                href="/news"
                className="py-2 px-4 text-white bg-primary inline-block rounded font-medium font-primary text-base hover:opacity-75 lg:w-[142px] text-center"
              >
                VIEW MORE
              </a>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default InstructorDetail;
