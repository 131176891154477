import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTestlist } from "../../features/testSlice";
import TestCard from "./TestCard";
import { getSubjects } from "../../features/getCourseSlice";
import Loader from "../../components/Loader";
import useEnrolledData from "../../components/hooks/useEnrolledData";
import NoDataFound from "../../components/NoDataFound";


const Practise = () => {
  const { loadingState, selectedId, subCourses } = useEnrolledData();

  // const studentGoalsData = useSelector(
  //   (state) => state?.enroll?.studentData[0]
  // );

  // const getSelectedId = studentGoalsData?.courses
  //   .flatMap((course) => course.courseFields)
  //   .find((courseField) => courseField.isSelected);

  const subjectsData = useSelector((state) => state?.course?.subjectData);

  const [activeTab, setTab] = useState(0);
  const [tabId, setTabId] = useState(
    subjectsData && subjectsData?.data[0]?._id
  );

  const testlistData = useSelector((state) => state?.testlist?.testList?.data);

  const dispatch = useDispatch();

  const loading = useSelector((state) => state?.testlist?.isLoading);

  useEffect(() => {
    if (loadingState === false) {
      dispatch(getSubjects({ courseFieldId: selectedId }));
    }
    dispatch(getTestlist({ subjectId: tabId }));
  }, [selectedId, dispatch, tabId]);

  return (
    <div>
      <div className="justify-center sm:justify-start flex flex-wrap gap-5">
        {subjectsData &&
          subjectsData.data.map((item, i) => {
            return (
              <button
                key={i}
                className={`font-medium text-sm font-primary p-2 px-5 border-2 border-primary rounded
                  ${activeTab === i
                    ? ` bg-primary text-white`
                    : `bg-white text-primary`
                  }`}
                onClick={() => {
                  setTab(i);
                  setTabId(item._id);
                }}
              >
                {item?.subjectName}
              </button>
            );
          })}
      </div>
      {loading ?
        <Loader />
        :
        <div>
          {testlistData ?
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 mt-8 md:gap-y-5">
              {testlistData.map((test, i) => {
                return (
                  <div key={i}>
                    <TestCard props={test} examType={'Test'} />
                  </div>
                );
              })

              }
            </div>
            :
            <NoDataFound />
          }
        </div>
      }
    </div>
  );
};

export default Practise;
