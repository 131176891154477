import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getPlansById, getPlansDuration } from "../../features/plansSlice";
import { getProfileData} from "../../features/userSlice";

import PriceCard from "../../components/PriceCard/PriceCard";
import FeaturesCard from "../../components/FeaturesCard/FeaturesCard";
import useEnrolledData from "../../components/hooks/useEnrolledData";

const Plans = () => {
  const dispatch = useDispatch();

  const { loadingState, selectedId, subCourses } = useEnrolledData();

  const plansData = useSelector((state) => state?.plans?.plansData);
  const plansDuration = useSelector((state) => state?.plans?.durationData);

  const [activeTab, setActiveTab] = useState(0);
  const [planDuration, setDuration] = useState(
    plansDuration && plansDuration.length > 0 ? plansDuration[0]._id : null
  );
  const [planId, setPlanId] = useState("");

  useEffect(() => {
    dispatch(getPlansDuration());
    dispatch(getProfileData());

    if (loadingState === false) {
      dispatch(getPlansById({ courseFieldId: selectedId }));
    }
  }, [dispatch, loadingState, selectedId]);

  // Check if plansDuration is available and has elements
  useEffect(() => {
    if (plansDuration && plansDuration.length > 0) {
      setDuration(plansDuration[0]._id);
    }
  }, [plansDuration]);

  return (
    <div className="">
      <div className="bg-payment h-[400px] text-center max-h-full bg-cover bg-center flex flex-col">
        <h1 className="mt-10 text-[48px] font-bold font-primary ">
          Get Started Now <br /> Pick a{" "}
          <span className="text-[#EC4A5E]">Plan Later</span>{" "}
        </h1>
        <p className="w-1/3 mx-auto mt-1 text-[#383838] text-base font-semibold ">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
          magna eros, pharetra sed egestas sagittis, sagittis vitae quam. Ut
          viverra, ex maximus dapibus placerat, turpis.
        </p>
        <div className="flex justify-center mt-8 gap-4 bg-[#83BBEC] max-w-max mx-auto px-2 rounded-3xl text-white text-base font-primary font-bold  ">
          {plansDuration &&
            plansDuration.map((duration, i) => {
              return (
                <button
                  className={
                    activeTab === i
                      ? `bg-[#0868C478] rounded-3xl px-3 py-2`
                      : ` bg-[#83BBEC] px-3 rounded-3xl black py-2 `
                  }
                  onClick={() => {
                    setActiveTab(i);
                    setDuration(duration._id);
                  }}
                >
                  {duration.duration} Months  
                </button>
              );
            })}
        </div>
      </div>

      {/*  price card section */}
      <div className=" mx-auto sm:mx-auto">
    <div className="flex flex-wrap justify-center mx-4">
 {plansData && plansData.map((plan, index) => (
    <div key={plan._id} className="w-full sm:w-1/2 md:w-1/3">
      <PriceCard
        props={plan}
        planDuration={planDuration}
        key={plan._id}
        coursefieldId={selectedId}
      />
    </div>
  ))}
</div>


        {/*  feature cards */}
        <h1 className="font-bold font-primary text-3xl mt-8 mx-8 ">
          Plus Subscription
        </h1>
        <div className=" grid grid-cols-3 gap-y-8 gap-x-16 mx-5 justify-center mt-5 mb-16 px-2">
          <FeaturesCard
            title={"India's top educators"}
            description={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non dictum justo, vel commodo nibh. Donec erat orci, ornare quis quam vitae."
            }
          />

          <FeaturesCard
            title={"Organized Planner"}
            description={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non dictum justo, vel commodo nibh. Donec erat orci, ornare quis quam vitae."
            }
          />

          <FeaturesCard
            title={"Personal Attention"}
            description={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non dictum justo, vel commodo nibh. Donec erat orci, ornare quis quam vitae."
            }
          />

          <FeaturesCard
            title={"Personalised Class"}
            description={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non dictum justo, vel commodo nibh. Donec erat orci, ornare quis quam vitae."
            }
          />

          <FeaturesCard
            title={"Live Doubt Solving"}
            description={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non dictum justo, vel commodo nibh. Donec erat orci, ornare quis quam vitae."
            }
          />

          <FeaturesCard
            title={"Up-to-Date Content"}
            description={
              "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Pellentesque non dictum justo, vel commodo nibh. Donec erat orci, ornare quis quam vitae."
            }
          />
        </div>
      </div>
    </div>
  );
};

export default Plans;
