import React from "react";
import icon from "../../assets/images/planEducator.png";
import {ReactComponent as Icon} from '../../assets/SVG/planEducator.svg'

const FeaturesCard = ({ title, description }) => {
  return (
    <div className="flex flex-col gap-2  hover:shadow-lg hover:rounded-md px-3 py-4 hover:shadow-[#0868C41C] hover:px-5 hover:py-7 hover:transition-all hover:duration-300"> 
    <div className="bg-primary max-w-min rounded-full px-1 py-1 mx-1 ">
    <Icon />
    </div>
  
      <h1 className="font-primary font-semibold text-2xl text-[#333] mt-1">{title}</h1>
      <p className="text-black text-base font-secondary font-normal">
        {description}
      </p>
    </div>
  );
};

export default FeaturesCard;
