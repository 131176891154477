import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateProfile, userData, getProfileData, DeleteProfile } from "../../features/userSlice";
import { useEffect } from "react";
import UserProfile from "../../assets/images/UserProfile.png";
import EditIcon from "../../assets/images/EditIcon.png";
import closeIcon from "../../assets/images/closeIcon.png";
import { ToastContainer, toast } from 'react-toastify';
import Modal from 'react-modal';

import '../../assets/css/App.css'

import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
const Settings = () => {
  const user = useSelector((state) => state.user.profileData[0]);
  const dispatch = useDispatch();
  const userdata = useSelector(userData);
  const [isUpdateInProgress, setIsUpdateInProgress] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true); // State to control button visibility
  const [isUpdateSuccess, setIsUpdateSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const navigate = useNavigate(); // Import the useNavigate hook


  const [image, setImage] = useState("");

  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    mobile: "",
    address: "",
    bio: "",
    profile_img: "",
    state: "",
    gender: "",
    pincode: "",
  });



  const handleUpload = (e) => {
    const files = e.target.files;
    const file = files[0];

    // Reset the error message
    setErrorMessage('');

    // Check if a file is selected
    if (files && files[0]) {
      // Get the file extension
      const fileExtension = file.name.split('.').pop().toLowerCase();

      // Check if the file extension is jpg or png
      if (fileExtension === 'jpg' || fileExtension === 'png') {
        const reader = new FileReader();
        reader.onload = () => {
          setImage(reader.result);
          setFormData((prevFormData) => ({
            ...prevFormData,
            profile_img: file,
          }));
        };
        reader.readAsDataURL(file);
      } else {
        // Set the error message
        setErrorMessage('Please select a JPG or PNG file only.');
      }
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const isAlphabetic = (char) => {
    return /^[a-zA-Z\s]+$/.test(char);
  };

  const isNumeric = (char) => {
    return /^\d+$/.test(char);
  };

  const handleKeyPress = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    const { name, value } = event.target;

    if (name === "mobile") {
      if (charCode && !isNumeric(String.fromCharCode(charCode))) {
        event.preventDefault();
      }
    } else if (name === "fullname") {
      if (charCode && !isAlphabetic(String.fromCharCode(charCode))) {
        event.preventDefault();
      }
    } else if (name === "pincode") {
      // Check if the pressed key is a numeric digit
      if (charCode && !/\d/.test(String.fromCharCode(charCode))) {
        event.preventDefault();
      }

      if (value.length >= 6) {
        event.preventDefault();
      }
    }
  };



  const updateUser = async (e) => {
    e.preventDefault();
    setIsUpdateInProgress(true);
    const bodyData = new FormData();
    bodyData.append("fullname", formData?.fullname);
    bodyData.append("email", formData?.email);
    bodyData.append("mobile", formData?.mobile);
    bodyData.append("address", formData?.address);
    bodyData.append("gender", formData?.gender);
    bodyData.append("state", formData?.state);
    bodyData.append("pincode", formData?.pincode);
    bodyData.append("bio", formData?.bio);
    bodyData.append("profile_img", formData.profile_img);

    try {
      await dispatch(updateProfile(bodyData));
      setIsUpdateSuccess(true); // Set the success state to true
      setIsButtonVisible(false); // Hide the button after update
      // Use setTimeout to show the button again after 4 seconds
      setTimeout(() => {
        setIsButtonVisible(true);
      }, 4000); // 4000 milliseconds = 4 seconds
    } catch (error) {
      console.error("Error updating profile:", error);
    } finally {
      setIsUpdateInProgress(false);
      dispatch(getProfileData());
    }
  };


  useEffect(() => {
    if (isUpdateSuccess) {
      toast.success("Profile updated successfully!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsUpdateSuccess(false); // Reset the success state after showing the toast
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    dispatch(getProfileData());
  }, []);

  useEffect(() => {
    if (userdata) {
      setFormData({
        fullname: user?.fullname,
        email: user?.email,
        mobile: user?.mobile?.toString()?.replace("91", ""),
        address: user?.address,
        gender: user?.gender,
        state: user?.state,
        pincode: user?.pincode,
        bio: user?.bio,
        profile_img: user?.profile_img,
      });
    }
  }, [user]);



  const handleDeleteProfile = () => {
    dispatch(DeleteProfile({ mobile: formData.mobile }))
      .then(() => {
        localStorage.removeItem("userToken");
        localStorage.removeItem("fcmToken");
        localStorage.clear();
        navigate('/');
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error deleting profile:', error);
      });
  };



  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleConfirm = () => {
    handleDeleteProfile();
    closeModal();
  };
  return (
    <div>
      <ToastContainer />

      {/*  banner  */}
      <section className="bg-settings bg-cover bg-no-repeat md:bg-center lg:h-[242px] w-full relative">
        <div className="max-w-[1200px]  mx-auto px-4">
          <div className="asm:absolute sm:bottom-1/2 sm:translate-y-2/4 py-7 sm:py-0 font-primary font-bold text-white">
            <ol className="flex gap-2 items-center text-sm sm:mb-4 mb-2">
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <svg
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L6 6L1 11"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </li>
              <li>Account Settings</li>
            </ol>
            <h1 className="lg:text-5xl text-xl font-bold">Account Settings</h1>
          </div>
        </div>
      </section>
      <div className="max-w-[1200px] mx-auto">
        <div className="sm:mt-16 mt-8 flex flex-col gap-2 mb-5 px-5">
          <h1 className="text-3xl text-[#333] font-primary font-bold capitalize">
            {" "}
            Update Your <span className="text-[#EC4A5E]">Details</span>
          </h1>
          <p className="text-base font-secondary font-normal text-[#383838]">
            Make Your Profile Shine
          </p>
          <hr className="text-[#383838]" />
        </div>
        <div>
          <div style={{ float: "right" }}>
            <button onClick={openModal} className="bg-red-500 px-4 py-2 text-white rounded-md">Delete</button>
            {/* <img src={closeIcon} className="w-10" onClick={openModal} alt="Close" /> */}
          </div>

          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Confirm Delete"
            className="custom-modal"
            overlayClassName="custom-overlay"
          >
            <h2 className="modal-title">Confirm Deletion</h2>
            <p className="modal-message">Are you sure you want to delete your profile?</p>
            <div className="modal-buttons">
              <button className="btn-confirm" onClick={handleConfirm}>Yes</button>
              <button className="btn-cancel" onClick={closeModal}>No</button>
            </div>
          </Modal>
        </div>
        <div className="flex gap-9 my-5 px-5 ">
          <div>
            <label htmlFor="upload">
              <div className="w-[120px] h-[120px] relative cursor-pointer rounded-full">
                <div className="">
                  <img
                    src={image ? image : (user?.profile_img ? process.env.REACT_APP_BASE_URL + user.profile_img : UserProfile)}
                    className="absolute w-[120px] border-[#b31942] h-[120px] rounded-full object-cover"
                  />



                </div>
                <div className="absolute -right-5 -bottom-4">
                  <img src={EditIcon} />
                </div>
              </div>
            </label>

            {errorMessage && <p className="error-message">{errorMessage}</p>}
            <input
              type="file"
              id="upload"
              className="hidden"
              name="profile_img"
              onChange={handleUpload}
              accept="image/jpeg, image/png"
            />

          </div>


          <div className="flex flex-col justify-center">
            <h1 className="font-primary font-bold text-3xl text-[#333]">
              {" "}
              {formData?.fullname}
            </h1>
            <p className="text-base font-secondary font-normal text-[#383838]">
              {formData?.address || "New Delhi, India"}
            </p>
          </div>
        </div>

        <div className="max-w-[747px] px-5">
          <form className="w-full" onSubmit={(e) => updateUser(e)}>
            <div className="mb-5">
              <label className="block font-primary  text-base font-bold text-[#333]">
                Full Name
              </label>

              <input
                type="text"
                required
                placeholder="Enter Full Name"
                name="fullname"
                value={formData?.fullname}
                onChange={handleChange}
                className="border px-2 py-1 border-[#C3C3C3] w-full focus:outline-none mt-1"
                onKeyPress={handleKeyPress}
              />
            </div>
            <div className="w-full grid grid-cols-2 gap-4 mb-5">
              <div>
                <label className="block font-primary  text-base font-bold text-[#333]">
                  Email ID
                </label>
                <input
                  type="email"
                  required
                  name="email"
                  placeholder="Enter Email ID"
                  value={formData.email}
                  onChange={handleChange}
                  className="border px-2 py-1 border-[#C3C3C3]  w-full focus:outline-none mt-1"
                />
              </div>

              <div>
                <label className="block font-primary  text-base font-bold text-[#333]">
                  Mobile Number
                </label>
                <input
                  type="text"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  placeholder="Enter Mobile No."
                  className="border px-2 py-1 border-[#C3C3C3] w-full focus:outline-none mt-1"
                  onKeyPress={handleKeyPress}
                  maxLength={10} readOnly
                />
              </div>
            </div>

            <div className="w-full grid grid-cols-2 gap-4 mb-2 mt-3">
              <div>
                <label className="block font-primary text-base font-bold text-[#333]">
                  Gender
                </label>
                <select
                  name="gender"
                  value={formData.gender}
                  onChange={handleChange}
                  className="border px-2 py-1 border-[#C3C3C3] w-full focus:outline-none mt-1"
                  required
                >
                  <option value="">Select Gender</option>
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Other</option>
                </select>
              </div>


              <div className="w-full grid grid-cols-2 gap-4 mb-5">
                <div>
                  <label className="block font-primary  text-base font-bold text-[#333]">
                    Pin code
                  </label>
                  <input
                    type="text"
                    required
                    name="pincode"
                    placeholder="Enter Your Pincode"
                    value={formData.pincode}
                    onChange={handleChange}
                    className="border px-2 py-1 border-[#C3C3C3] w-full focus:outline-none mt-1"
                    onKeyPress={handleKeyPress}
                    pattern="[0-9]{6}"
                    maxLength={6}
                  />
                </div>

                <div>
                  <label className="block font-primary  text-base font-bold text-[#333]">
                    State
                  </label>
                  <input
                    type="text"
                    required
                    name="state"
                    placeholder="Enter Your State"
                    value={formData.state}
                    onChange={handleChange}
                    className="border px-2 py-1 border-[#C3C3C3]  w-full focus:outline-none mt-1"
                  />
                </div>
              </div>

              {/* <div>
                <label className="block font-primary  text-base font-bold text-[#333]">
                Enrolled Courses
                </label>
                <input
                  type="text"
                  required
                  name="courses"
                  placeholder="Enter Your Courses"
                  value={formData.courses}
                  onChange={handleChange}
                  className="border px-2 py-1 border-[#C3C3C3]  w-full focus:outline-none mt-1"
                />
              </div> */}
            </div>
            <div>
              <label className="block font-primary  text-base font-bold text-[#333]">
                Address
              </label>
              <input
                type="text"
                name="address"
                required
                placeholder="Enter Address"
                value={formData.address}
                onChange={handleChange}
                className="border px-2 py-1 border-[#C3C3C3]  w-full focus:outline-none mt-1"
              />

            </div>
            <div>
              <label className="block font-primary mt-5  text-base font-bold text-[#333]">
                Enter Bio
              </label>
              <textarea
                name="bio"
                placeholder="Add your Bio or anything else you want to share "
                rows={6}
                value={formData.bio}
                onChange={handleChange}
                className="border px-2 py-1 border-[#C3C3C3] resize-none w-full focus:outline-none mt-1"
              />
            </div>

            {isButtonVisible && ( // Conditionally render the button
              <button
                type="submit"
                className="bg-primary px-4 py-2 rounded-md shadow-md font-primary my-5 text-white font-medium text-base uppercase "
              >
                Save Changes
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Settings;
