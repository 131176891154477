import React from "react";
import Logo from "../../assets/SVG/LOGO.svg";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { userData } from "../../features/userSlice";
// import newLog from '../../assets/imtihan logo.png'
import newLog from "../../assets/CotginEDU.png";

import useWindowDimensions from "../useWindowDimensions";

const Footer = () => {
  const user = useSelector(userData);

  const location = useLocation();
  const { isMobile } = useWindowDimensions();
  if (
    location.pathname === "/practiseTest" ||
    location.pathname === "/videoTest" ||
    location.pathname === "/leaderBoard"
  ) {
    return null;
  }
  return (
    <React.Fragment>
      <footer className="font-secondary bg-[#F5F9FD]">
        <div className="max-w-[1200px] w-full container mx-auto">
          <div className="px-4 py-6 mx-xl-auto md:flex sm:gap-[50px] lg:items-center sm:flex-row sm:flex-nowrap flex-wrap flex-col">
            <div className="w-full md:w-64 flex-shrink-0 md:mx-0 text-center md:text-left lg:w-[290px]">
              <a
                href="#"
                className="flex title-font font-medium items-center md:justify-start justify-center text-gray-900"
              >
                <img
                  src={newLog}
                  alt="Footer Logo"
                  className="sm:h-[50px] sm:w-[169px] h-auto w-[169px]"
                />
              </a>
              <div className="lg:w-[290px]">
                <p className="sm:mt-6 mt-3 text-sm sm:text-base text-[#383838] font-normal sm:text-justify text-center">
                  Cotgin Edu is your ultimate partner in your teaching career. With advanced platform features like live classes, and backup options we empower you to teach anytime, anywhere. Take your teaching career to new heights with us.

                  {/* <a href="#" className='text-secondary font-semibold hover:text-primary'>Read more.</a> */}
                </p>
              </div>
            </div>

            <div className="flex flex-col sm:gap-[47px] mt-3">
              <div className="grid grid-cols-3 lg:grid-cols-4 gap-y-4 lg:gap-5 gap-10">
                <div className="">
                  <h2 className="text-primary font-primary text-base sm:text-xl font-bold mb-1 uppercase">
                    Company
                  </h2>
                  <nav className="list-none sm:text-base text-sm">
                    <li>
                      <Link
                        to={"/about-us"}
                        className="text-black hover:text-primary"
                      >
                        About Us
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/contact-us"
                        className="text-black hover:text-primary"
                      >
                        Contact us
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/joininsturctor"
                        className="text-black hover:text-primary"
                      >
                        Why Cotgin EDU
                      </Link>
                    </li>
                  </nav>
                </div>
                <div className="">
                  <h2 className="text-primary font-primary text-base sm:text-xl font-bold mb-1 uppercase">
                    Competitive Exams
                  </h2>
                  <nav className="list-none sm:text-base text-sm">
                    <li>
                      <Link
                        to={user?._id ? "/goals" : "#"}
                        className="text-black hover:text-primary"
                      >
                        UPSC / SSC{" "}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={user?._id ? "/goals" : "#"}
                        className="text-black hover:text-primary"
                      >
                        IIT JEE
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={user?._id ? "/goals" : "#"}
                        className="text-black hover:text-primary"
                      >
                        NEET
                      </Link>
                    </li>
                  </nav>
                </div>
                <div className="">
                  <h2 className="text-primary font-primary text-base sm:text-xl font-bold mb-1 uppercase">
                    Useful Links
                  </h2>
                  <nav className="list-none sm:text-base text-sm">
                    <li>
                      <Link
                        to={"/termsconditions"}
                        className="text-black hover:text-primary"
                      >
                        Terms and Conditions
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/privacypolicy"}
                        className="text-black hover:text-primary"
                      >
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/refundpolicy"}
                        className="text-black hover:text-primary"
                      >
                        Refund Policy
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={"/howDeactiveUser"}
                        className="text-black hover:text-primary"
                      >
                        Delete Account
                      </Link>
                    </li>
                    {/* <li>
                                            <a href='#' className="text-black hover:text-primary">FAQs</a>
                                        </li> */}
                    {/* <li>
                                            <a href='#' className="text-black hover:text-primary">Privacy Policy</a>
                                        </li>
                                        <li>
                                            <a href='#' className="text-black hover:text-primary">Terms and Conditions</a>
                                        </li> */}
                  </nav>
                </div>
                <div className="">
                  <h2 className="text-primary font-primary text-base sm:text-xl font-bold sm:mb-3 mb-2 uppercase">
                    Contact Us
                  </h2>
                  <div className="text-sm sm:text-base">
                    <div className="flex gap-1 mb-2">
                      <span className="text-primary font-bold font-primary">
                        Email:{" "}
                      </span>
                      <a
                        className="text-black hover:text-primary"
                        href="mailto:support@cotginedu.com"
                      >
                       support@cotginedu.com
                      </a>
                    </div>
                    <div className="flex gap-1">
                      <span className="text-primary font-bold font-primary">
                        Toll Free:{" "}
                      </span>
                      <a
                        className="text-black hover:text-primary"
                        href="tel:18002050568"
                      >
                        1800-205-0568
                      </a>
                    </div>
                  </div>
                </div>
              
              </div>

              {/* <div className="flex gap-[30px] ">
                {isMobile ? "" :
                  <div>
                    <h2 className="text-primary font-primary text-xl font-bold mb-3 uppercase">
                      Follow Us
                    </h2>
                    <nav className="list-none mb-10 flex gap-4 md:justify-start justify-center">
                      <li>
                        <Link
                          className="text-black bg-[#ECF0F3] hover:bg-blue-300 w-8 h-8 grid place-content-center rounded-full"
                          href="#"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M15.1194 5.32003H16.9994V2.14003C16.0891 2.04538 15.1745 1.99865 14.2594 2.00003C11.5394 2.00003 9.67938 3.66003 9.67938 6.70003V9.32003H6.60938V12.88H9.67938V22H13.3594V12.88H16.4194L16.8794 9.32003H13.3594V7.05003C13.3594 6.00003 13.6394 5.32003 15.1194 5.32003Z"
                              fill="#0868C4"
                              fill-opacity="0.47"
                            />
                          </svg>
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="text-black bg-[#ECF0F3] hover:bg-blue-300 w-8 h-8 grid place-content-center rounded-full"
                          href="#"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M16.5 2.25H7.5C6.10807 2.25149 4.77358 2.80509 3.78933 3.78933C2.80509 4.77358 2.25149 6.10807 2.25 7.5V16.5C2.25149 17.8919 2.80509 19.2264 3.78933 20.2107C4.77358 21.1949 6.10807 21.7485 7.5 21.75H16.5C17.8919 21.7485 19.2264 21.1949 20.2107 20.2107C21.1949 19.2264 21.7485 17.8919 21.75 16.5V7.5C21.7485 6.10807 21.1949 4.77358 20.2107 3.78933C19.2264 2.80509 17.8919 2.25149 16.5 2.25ZM12 16.5C11.11 16.5 10.24 16.2361 9.49993 15.7416C8.75991 15.2471 8.18314 14.5443 7.84254 13.7221C7.50195 12.8998 7.41283 11.995 7.58647 11.1221C7.7601 10.2492 8.18868 9.44736 8.81802 8.81802C9.44736 8.18868 10.2492 7.7601 11.1221 7.58647C11.995 7.41283 12.8998 7.50195 13.7221 7.84254C14.5443 8.18314 15.2471 8.75991 15.7416 9.49993C16.2361 10.24 16.5 11.11 16.5 12C16.4988 13.1931 16.0243 14.337 15.1806 15.1806C14.337 16.0243 13.1931 16.4988 12 16.5ZM17.625 7.5C17.4025 7.5 17.185 7.43402 17 7.3104C16.815 7.18679 16.6708 7.01109 16.5856 6.80552C16.5005 6.59995 16.4782 6.37375 16.5216 6.15552C16.565 5.93729 16.6722 5.73684 16.8295 5.5795C16.9868 5.42217 17.1873 5.31502 17.4055 5.27162C17.6238 5.22821 17.85 5.25049 18.0555 5.33564C18.2611 5.42078 18.4368 5.56498 18.5604 5.74998C18.684 5.93499 18.75 6.1525 18.75 6.375C18.75 6.67337 18.6315 6.95952 18.4205 7.1705C18.2095 7.38147 17.9234 7.5 17.625 7.5ZM15 12C15 12.5933 14.8241 13.1734 14.4944 13.6667C14.1648 14.1601 13.6962 14.5446 13.1481 14.7716C12.5999 14.9987 11.9967 15.0581 11.4147 14.9424C10.8328 14.8266 10.2982 14.5409 9.87868 14.1213C9.45912 13.7018 9.1734 13.1672 9.05764 12.5853C8.94189 12.0033 9.0013 11.4001 9.22836 10.8519C9.45542 10.3038 9.83994 9.83524 10.3333 9.50559C10.8266 9.17595 11.4067 9 12 9C12.7956 9 13.5587 9.31607 14.1213 9.87868C14.6839 10.4413 15 11.2044 15 12Z"
                              fill="#0868C4"
                              fill-opacity="0.47"
                            />
                          </svg>
                        </Link>
                      </li>
                      <li>
                        <a
                          className="text-black bg-[#ECF0F3] hover:bg-blue-300 w-8 h-8 grid place-content-center rounded-full"
                          href="#"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M22.4591 6C21.6891 6.35 20.8591 6.58 19.9991 6.69C20.8791 6.16 21.5591 5.32 21.8791 4.31C21.0491 4.81 20.1291 5.16 19.1591 5.36C18.3691 4.5 17.2591 4 15.9991 4C13.6491 4 11.7291 5.92 11.7291 8.29C11.7291 8.63 11.7691 8.96 11.8391 9.27C8.27906 9.09 5.10906 7.38 2.99906 4.79C2.62906 5.42 2.41906 6.16 2.41906 6.94C2.41906 8.43 3.16906 9.75 4.32906 10.5C3.61906 10.5 2.95906 10.3 2.37906 10V10.03C2.37906 12.11 3.85906 13.85 5.81906 14.24C5.18979 14.4122 4.52916 14.4362 3.88906 14.31C4.16067 15.1625 4.6926 15.9084 5.41008 16.4429C6.12756 16.9775 6.99451 17.2737 7.88906 17.29C6.37269 18.4904 4.49306 19.1393 2.55906 19.13C2.21906 19.13 1.87906 19.11 1.53906 19.07C3.43906 20.29 5.69906 21 8.11906 21C15.9991 21 20.3291 14.46 20.3291 8.79C20.3291 8.6 20.3291 8.42 20.3191 8.23C21.1591 7.63 21.8791 6.87 22.4591 6Z"
                              fill="#0868C4"
                              fill-opacity="0.47"
                            />
                          </svg>
                        </a>
                      </li>
                      <li>
                        <Link
                          className="text-black bg-[#ECF0F3] hover:bg-blue-300 w-8 h-8 grid place-content-center rounded-full"
                          href="#"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M4.75 1.875C4.18641 1.875 3.64591 2.09888 3.2474 2.4974C2.84888 2.89591 2.625 3.43641 2.625 4C2.625 4.56359 2.84888 5.10409 3.2474 5.5026C3.64591 5.90112 4.18641 6.125 4.75 6.125C5.31359 6.125 5.85409 5.90112 6.2526 5.5026C6.65112 5.10409 6.875 4.56359 6.875 4C6.875 3.43641 6.65112 2.89591 6.2526 2.4974C5.85409 2.09888 5.31359 1.875 4.75 1.875ZM2.75 7.875C2.71685 7.875 2.68505 7.88817 2.66161 7.91161C2.63817 7.93505 2.625 7.96685 2.625 8V21C2.625 21.069 2.681 21.125 2.75 21.125H6.75C6.78315 21.125 6.81495 21.1118 6.83839 21.0884C6.86183 21.0649 6.875 21.0332 6.875 21V8C6.875 7.96685 6.86183 7.93505 6.83839 7.91161C6.81495 7.88817 6.78315 7.875 6.75 7.875H2.75ZM9.25 7.875C9.21685 7.875 9.18505 7.88817 9.16161 7.91161C9.13817 7.93505 9.125 7.96685 9.125 8V21C9.125 21.069 9.181 21.125 9.25 21.125H13.25C13.2832 21.125 13.3149 21.1118 13.3384 21.0884C13.3618 21.0649 13.375 21.0332 13.375 21V14C13.375 13.5027 13.5725 13.0258 13.9242 12.6742C14.2758 12.3225 14.7527 12.125 15.25 12.125C15.7473 12.125 16.2242 12.3225 16.5758 12.6742C16.9275 13.0258 17.125 13.5027 17.125 14V21C17.125 21.069 17.181 21.125 17.25 21.125H21.25C21.2832 21.125 21.3149 21.1118 21.3384 21.0884C21.3618 21.0649 21.375 21.0332 21.375 21V12.38C21.375 9.953 19.265 8.055 16.85 8.274C16.1028 8.34202 15.3709 8.52694 14.681 8.822L13.375 9.382V8C13.375 7.96685 13.3618 7.93505 13.3384 7.91161C13.3149 7.88817 13.2832 7.875 13.25 7.875H9.25Z"
                              fill="#0868C4"
                              fill-opacity="0.47"
                            />
                          </svg>
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="text-black bg-[#ECF0F3] hover:bg-blue-300 w-8 h-8 grid place-content-center rounded-full"
                          href="#"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M22.0581 6.9449C21.9386 6.50093 21.7045 6.09614 21.3794 5.77103C21.0543 5.44592 20.6495 5.2119 20.2056 5.0924C18.5706 4.6499 12.0006 4.6499 12.0006 4.6499C12.0006 4.6499 5.43056 4.6499 3.79556 5.0924C3.35159 5.2119 2.9468 5.44592 2.62169 5.77103C2.29658 6.09614 2.06257 6.50093 1.94306 6.9449C1.63777 8.61235 1.48962 10.3048 1.50056 11.9999C1.48962 13.695 1.63777 15.3875 1.94306 17.0549C2.06257 17.4989 2.29658 17.9037 2.62169 18.2288C2.9468 18.5539 3.35159 18.7879 3.79556 18.9074C5.43056 19.3499 12.0006 19.3499 12.0006 19.3499C12.0006 19.3499 18.5706 19.3499 20.2056 18.9074C20.6495 18.7879 21.0543 18.5539 21.3794 18.2288C21.7045 17.9037 21.9386 17.4989 22.0581 17.0549C22.3634 15.3875 22.5115 13.695 22.5006 11.9999C22.5115 10.3048 22.3634 8.61235 22.0581 6.9449ZM9.90056 15.1499V8.8499L15.3531 11.9999L9.90056 15.1499Z"
                              fill="#0868C4"
                              fill-opacity="0.47"
                            />
                          </svg>
                        </Link>
                      </li>
                    </nav>
                  </div>
                }
                <div className="w-full sm:px-4">
                  <h2 className="text-primary font-primary text-base sm:text-xl font-bold sm:mb-3 mb-2 uppercase">
                    Contact Us
                  </h2>
                  <div className="flex flex-col sm:flex-row sm:gap-4 gap-3 text-sm sm:text-base">
                    <div className="flex gap-1">
                      <span className="text-primary font-bold font-primary">
                        Email:{" "}
                      </span>
                      <a
                        className="text-black hover:text-primary"
                        href="mailto:support@cotginedu.com"
                      >
                       support@cotginedu.com
                      </a>
                    </div>
                    <div className="flex gap-1">
                      <span className="text-primary font-bold font-primary">
                        Toll Free:{" "}
                      </span>
                      <a
                        className="text-black hover:text-primary"
                        href="tel:18002050568"
                      >
                        1800-205-0568
                      </a>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </footer>
      <div className="border-t bg-white">
        <div className="py-4 px-5 flex justify-center max-w-[1200px] w-full container mx-auto">
          <p
            style={{ color: "#555F65" }}
            className="font-semibold font-primary text-sm text-center sm:text-left"
          >
            © All rights reserved – Cotgin EDU {new Date().getFullYear()}
          </p>
          {/* <span className="inline-flex sm:ml-auto sm:mt-0 mt-2 justify-center sm:justify-start font-primary gap-4 font-semibold">
                <a href="#" className='hover:text-primary'>Disclaimer</a>
                <a href="#" className='hover:text-primary'>Cookie Policy</a>
            </span> */}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Footer;
