import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../app/AxiosHandler"; // You need to install axios

const initialState = {
  chapters: [],
  loading: false,
  error: null,
  playlists: [], 
  liveRecorded:"",
  
  videoPageData: "",
};

export const getPlaylistBySubject = createAsyncThunk(
  "playlist/busubjects",
  async (data, thunkAPI) => {
    try {
      const response = await api.post(
        process.env.REACT_APP_PLAYLIST_BY_SUBJECT,
        data
      ); // Adjust the API endpoint
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getChapterListBySubject = createAsyncThunk(
  "getChapterListBySubject",
  async (data, thunkAPI) => {
    try {
      const response = await api.post(
        process.env.REACT_APP_PLAYLIST_BY_SUBJECT,
        data
      ); // Adjust the API endpoint
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getPlaylistByChapter = createAsyncThunk(
  "getPlaylistByChapter",
  async (data, thunkAPI) => {
    try {
      const response = await api.post("/playlists/videobyPlaylistid", data); // Adjust the API endpoint
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const playlistSlice = createSlice({
  name: "playlist",
  initialState,
  reducers: {
    resetData: (state, action) => {
      state.playlists = [];
      state.chapters = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPlaylistByChapter.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getPlaylistByChapter.fulfilled, (state, action) => {
        state.playlists = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getPlaylistByChapter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.playlists = [];
      });

    builder
      .addCase(getChapterListBySubject.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getChapterListBySubject.fulfilled, (state, action) => {
        state.chapters = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(getChapterListBySubject.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.chapters = [];
      });

    builder.addCase(getPlaylistBySubject.fulfilled, (state, action) => {
      state.videoPageData = action.payload;
    });
  },
});

export const { resetData } = playlistSlice.actions;

export const liveVideos = (state) => state?.playlist?.playlists;

export default playlistSlice.reducer;
