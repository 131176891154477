import React from "react";

const Modal = ({ openModal, heading, subHead1, subHead2, btnName, handleBtn, btnCancel, handleCancel, }) => {

    return (
        <>
            <React.Fragment>
                {openModal ? (
                    <>
                        <div className=''>
                            <div className="bg-[#555F65] bg-opacity-60  flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                                <div className="relative w-auto my-6 mx-auto">
                                    <div className="h-[298.13px] w-[450px] border-0 rounded-lg shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                                        <div className="h-[56.25px] bg-primary font-primary font-medium text-center text-xl text-white py-4 ">
                                            {heading}
                                        </div>
                                        <div className=" w-[283px] mx-[83px] my-7 text-center font-primary font-bold">
                                            <p className="text-base" >{subHead1}</p>
                                            <div className="mt-4 text-xl">
                                                {subHead2}
                                            </div>
                                        </div>
                                        <div className="flex items-center justify-center gap-4">
                                            <button
                                                className="w-[161.97px] h-[48px] border border-[#6C6C6C] rounded-sm p-3 font-primary font-medium text-base text-[#6C6C6C] "
                                                onClick={() => handleCancel()}>
                                                {btnCancel}
                                            </button>
                                            <button
                                                className="w-[161.97px] h-[48px] bg-primary text-white rounded-sm p-3 font-primary font-medium text-base "
                                                onClick={() => handleBtn()}>
                                                {btnName}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ) : ""}
            </React.Fragment>
        </>
    );
}

export default Modal;