import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../app/AxiosHandler";

export const getPlansById = createAsyncThunk(
  "plans/byId",
  async (data, { rejectWithValue }) => {
    try {
      const res = await api
        .post(process.env.REACT_APP_GET_PLANS, data)
        .then((res) => res.data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getPlansDuration = createAsyncThunk(
  "plans/duration",
  async (_,thunkApi) => {
    try {
      const res = await  api
        .post(process.env.REACT_APP_GET_DURATION)
        .then((res) => res.data);
      return res.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

const initialState = {
  plansData: [],
  durationData: [],
  isLoading: false,
  error: "",
};

const plansSlice = createSlice({
  name: "plans",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPlansById.fulfilled, (state, action) => {
        state.plansData = action.payload;
        state.isLoading = false;
        
      })
      .addCase(getPlansById.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getPlansById.pending, (state, action) => {
        state.isLoading = true;
      });

    builder
      .addCase(getPlansDuration.fulfilled, (state, action) => {
        state.durationData = action.payload;
        state.isLoading = false;
      })
      .addCase(getPlansDuration.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getPlansDuration.pending, (state, action) => {
        state.isLoading = true;
      });
  },
});

export default plansSlice.reducer;
