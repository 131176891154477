import axios from "axios";
import api from "../../app/AxiosHandler";


export const loginNumberAPI = async (phoneNumber) => {

  try {
    const response = await api.post(`/public/send/otp`, {
      mobile: phoneNumber,
    });
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};

export const verifyOTPAPI = async (data) => {
  try {
    const response = await api.post(
      `/public/verify/otp`,
      data
    );
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.error);
  }
};
