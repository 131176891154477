import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../app/AxiosHandler";

export const getBatchDetails = createAsyncThunk(
  "batch/get",
  async (data, { rejectWithValue }) => {
    try {
      const res = await api
        .post("batch/batchbycoursefield", data)
        .then((res) => res.data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  isLoading: false,
  isError: false,
  error: "",
  data: "",
};

const batchSlice = createSlice({
  name: "batch",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBatchDetails.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(getBatchDetails.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getBatchDetails.rejected, (state, action) => {
        state.isError = action.payload;
        state.isLoading = false;
      });
  },
});

export const batchData = (state) => state?.batch?.data;

export default batchSlice.reducer;
