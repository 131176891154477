import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';

const VideoCard = ({
  thumbnail,
  backgroundImg,
  title,
  subjectName,
  courseName,
  showSubjectDetails,
  btnName,
  
  type,
  ...props
}) => {
  const navigate = useNavigate();

  const [isClickable, setIsClickable] = useState(false);

  useEffect(() => {
    const currentTime = new Date();
    const classTime = new Date(props.classTime); // Assuming props.classTime is a valid Date string

    const options = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false
    };

    const formattedClassTime = classTime.toLocaleDateString(undefined, options) + ' ' + classTime.toLocaleTimeString(undefined, options);

    console.log(formattedClassTime,'formattedClassTime');


    // console.log(currentTime, 'currentTime')
    // console.log(classTime, 'classTime')
    // // Check if the current time is equal to or greater than the class time
    // setIsClickable(currentTime >= classTime);
  }, [props.classTime]);


  return (
    <div
      className={`p-3 shadow-sm shadow-[#00000040] border rounded lg:w-[268px] lg:h-[333px] flex flex-col `}
      onClick={() => {
        navigate(props?.link, {
          state: { _id: props?.id, playlistType: 'live' },
        });
      }}
    >
      <div className="relative flex flex-col">
        <div
          style={{
            backgroundImage: `url(${process.env.REACT_APP_BASE_URL + backgroundImg})`,
            backgroundSize: "cover",
          }}
          className="mb-3"
        >
          <img
            src={process.env.REACT_APP_BASE_URL + thumbnail}
            alt=""
            className="h-[188px] w-full px-6 pt-3 object-contain"
          />
        </div>
        <button className="absolute bottom-2/4 right-2/4 translate-y-2/4 translate-x-2/4">
          <svg
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.668 19.9993L20.0013 13.9993L10.668 7.99935V19.9993ZM14.0013 27.3327C12.1569 27.3327 10.4235 26.9825 8.8013 26.282C7.17908 25.5816 5.76797 24.6318 4.56797 23.4327C3.36797 22.2327 2.41819 20.8216 1.71864 19.1993C1.01908 17.5771 0.668858 15.8438 0.667969 13.9993C0.667969 12.1549 1.01819 10.4216 1.71864 8.79935C2.41908 7.17713 3.36886 5.76602 4.56797 4.56602C5.76797 3.36602 7.17908 2.41624 8.8013 1.71668C10.4235 1.01713 12.1569 0.666905 14.0013 0.666016C15.8457 0.666016 17.5791 1.01624 19.2013 1.71668C20.8235 2.41713 22.2346 3.3669 23.4346 4.56602C24.6346 5.76602 25.5849 7.17713 26.2853 8.79935C26.9857 10.4216 27.3355 12.1549 27.3346 13.9993C27.3346 15.8438 26.9844 17.5771 26.284 19.1993C25.5835 20.8216 24.6337 22.2327 23.4346 23.4327C22.2346 24.6327 20.8235 25.5829 19.2013 26.2833C17.5791 26.9838 15.8457 27.3336 14.0013 27.3327Z"
              fill="white"
            />
          </svg>
        </button>
        {type === "Current" ? (
          <button
            className={`absolute top-3 left-2 border border-secondary flex gap-1 items-center px-1 animate-pulse `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
            >
              <circle cx="4.5" cy="4.5" r="3.5" fill="red" />
            </svg>
            <p className="font-primary font-medium text-xs text-secondary">
              LIVE
            </p>
          </button>
        ) : (
          ""
        )}
      </div>
      {showSubjectDetails ? (
        <div className="flex justify-between mb-1 h-[18px] items-center">
          <div className="flex gap-1 items-center">
            <div className="border border-secondary font-primary font-semibold p-0.5 text-secondary text-[11px] leading-[13.41px] rounded-sm uppercase h-full">
              EN
            </div>
            <div className="font-primary font-semibold p-0.5 text-primary text-xs leading-[13.41px] rounded-sm uppercase h-full">
              {subjectName?.length > 10
                ? `${subjectName?.slice(0, 10)}...`
                : subjectName}
            </div>
          </div>
          <div className="h-full p-0.5 font-primary font-semibold text-[11px] text-[#383838] leading-[13.41px] border border-[#383838CC]">
            {courseName?.length > 20
              ? `${courseName?.slice(0, 20)}...`
              : courseName}
          </div>
        </div>
      ) : (
        ""
      )}
      <h3 className="text-black font-primary font-semibold text-base mb-2 mt-1 leading-[19.5px] break-words">
        <p>{title?.length > 55 ? `${title?.slice(0, 55)}...` : title}</p>
      </h3>
      <div className="flex-1"></div>
      <p className="text-sm font-secondary font-normal">By {props?.fullname}</p>
      {props?.classTime ? (
        <p className="text-[#9E9E9E] text-xs font-secondary font-normal mb-2">
          {new Date(props?.classTime).toLocaleString()}
        </p>
      ) : (
        ""
      )}

      {/* <Link
                to={props?.link} state={props?.id}
                className="px-[2px] py-2 w-full  my-auto text-center text-white bg-secondary inline-block  text-[14px] rounded font-bold font-primary hover:opacity-75"
            >
                {btnName || 'START NOW'}
            </Link> */}
    </div>
  );
};

export default VideoCard;
