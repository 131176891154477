import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { examQues, getExamList, getUpcomingExams } from "../../features/examSlice";
import { getSubjects } from "../../features/getCourseSlice";
import TestCard from "./TestCard";
import Loader from "../../components/Loader";
import useEnrolledData from "../../components/hooks/useEnrolledData";
import UpComingExamList from "./UpComingExamList";
import NoDataFound from "../../components/NoDataFound";
import Instructions from "./Instructions";
import { completedExams, completedExamsLists } from "../../features/testSlice";
import { useNavigate } from "react-router-dom";


const Tests = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loadingState, selectedId, subCourses } = useEnrolledData();

  const subjectsData = useSelector((state) => state?.course?.subjectData);
  const [activeTab, setTab] = useState("Your Exams");
  const [tabId, setTabId] = useState(
    subjectsData && subjectsData?.data[0]?._id
  );

  const exams = useSelector(examQues);
  const completedExamsData = useSelector((state) => state.testlist.completedExams);
  console.log(completedExamsData,'111')

  const loading = useSelector((state) => state?.exams?.isLoading);

  const upcomingExams = useSelector((state) => state?.exams?.upcomingExams);

  useEffect(() => {
    dispatch(getUpcomingExams({ courseFieldId: selectedId }));
  }, [dispatch, selectedId]);

  useEffect(() => {
    if (loadingState === false) {
      dispatch(getSubjects({ courseFieldId: selectedId }));
    }

    dispatch(getExamList({ courseFieldId: selectedId }));
  }, [dispatch, selectedId]);

  useEffect(() => {
    dispatch(completedExams({ courseFieldId: selectedId }));
  }, [dispatch, selectedId])

  console.log(completedExamsData, "line422")

  const handleClick = (id) => {
    navigate('/leaderBoard', { state: { testId: id } });
  }

  return (

    <React.Fragment>
      {loading ?
        <Loader />
        :
        <div>
          {activeTab === "Instructions" ? ''
            :
            <div className="flex gap-5" >
              <button
                className={`font-medium text-sm font-primary p-2 px-5 border-2 border-primary rounded
                ${activeTab === "Your Exams"
                    ? ` bg-primary text-white`
                    : `bg-white text-primary`
                  }`}
                onClick={() => {
                  setTab('Your Exams');
                }}
              >
                Your Exams
              </button>
              <button
                className={`font-medium text-sm font-primary p-2 px-5 border-2 border-primary rounded
                ${activeTab === "Completed Exams"
                    ? ` bg-primary text-white`
                    : `bg-white text-primary`
                  }`}
                onClick={() => {
                  setTab('Completed Exams');
                }}
              >
                Completed Exams
              </button>
            </div>
          }

          {activeTab === "Instructions" ?
            <div>
              <Instructions setTab={setTab} />
            </div>
            :
            activeTab === "Completed Exams" ?
              <>

                {completedExamsData && completedExamsData?.length ?
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 mt-8 md:gap-y-5">
                    {
                      completedExamsData?.map((item, key) => {
                        return (
                          <div className="px-3 pb-5 pt-6 shadow shadow-[#00000040] border rounded lg:w-[268px] lg:h-[179px] flex flex-col cursor-pointer"
                            onClick={() => handleClick(item?._id)}
                          >
                            <div className="flex justify-between mb-1">
                              <div className="border border-primary font-primary font-semibold p-0.5 text-primary text-[11px] leading-[13.41px] rounded-sm uppercase h-full">SSC - CGL</div>
                              <div className="h-full p-0.5 font-primary font-semibold text-[11px] text-[#383838] leading-[13.41px] border border-[#383838CC]">{new Date(item?.startTime)?.toDateString()}</div>
                            </div>

                            <h3 className="text-black font-primary font-bold text-base leading-[19.5px] break-words mt-1">
                              <p>{item?.title && item?.title?.slice(0, 70)}</p>
                            </h3>
                            <div className="flex-1"></div>
                            <div className='flex flex-col'>

                              <div>
                                <button
                                  disabled
                                  className={`py-1 px-2 rounded font-medium font-primary uppercase text-primary border-2 border-primary text-sm mt-auto inline-block  hover:opacity-75 `}>
                                  rank : {item?.attemptexamlists?.rank}
                                </button>
                              </div>
                            </div>
                          </div>
                        );
                      })

                    }
                  </div>
                  :
                  <NoDataFound />
                }
              </>
              :
              <>
                {exams?.testList && exams?.testList?.length ?
                  <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 mt-8 md:gap-y-5  ">
                    {exams?.testList?.map((test, i) => {
                      return (
                        <div key={i}>
                          <TestCard props={test} btnName={'Start Test'} examType={'Exam'} activeTab={activeTab} setTab={setTab} />
                        </div>
                      );
                    })}
                  </div>
                  :
                  <NoDataFound />
                }
              </>
          }


        </div>
      }
      {activeTab === "Your Exams" ?
        <div className="" >
          {upcomingExams && upcomingExams?.length ?
            <UpComingExamList subjectId={selectedId} exams={upcomingExams} />
            : ""
          }
        </div>
        : ''
      }
    </React.Fragment>
  )

};

export default Tests;
