import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { askDoubt, doubtBySubject } from "../../features/doubtSlice";
import { userData } from "../../features/userSlice";
import RecentDoubts from "./RecentDoubts";
import { useEffect } from "react";
import useEnrolledData from "../../components/hooks/useEnrolledData";
import { useNavigate } from "react-router-dom";
import UserProfile from "../../assets/images/UserProfile.png";
import ImageViewer from "../../components/Modal/imageViewer";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Doubts = () => {
  const dispatch = useDispatch();
  const [img, setImg] = useState("");
  const [message, setMessage] = useState("");

  const subjectsData = useSelector((state) => state?.course?.subjectData);
  const user = useSelector(userData);
  const [selectedImage, setSelectedImage] = useState(null);

   const [errorMessage, setErrorMessage] = useState("");

 const handleFileChange = (e) => {
    const files = e.target.files;
    const validFiles = Array.from(files).filter((file) => {
      const fileExtension = file.type.split("/")[1];
      return fileExtension === "png" || fileExtension === "jpeg";
    });

    if (validFiles.length === files.length) {
      setImg(validFiles);
      setErrorMessage(""); // Clear the error message if all files are valid
    } else {
      setImg([]);
      setErrorMessage("Please select only JPG or PNG files.");
    }
 };
  const [activeTab, setTab] = useState(0);
  const [tabId, setTabId] = useState(
    subjectsData && subjectsData?.data[0]?._id
  );
  const [singleDoubtData, setSingleDoubtData] = useState("");

  const [isOpen, setIsOpen] = useState(false);
  const [solutionImage, setSolutionImage] = useState("");

  const subjectDoubts = useSelector((state) => state?.doubt?.getDoubts);
  const { selectedId, selectedSubcourse } = useEnrolledData();

  const navigate = useNavigate();
  const doubtHandler = (e) => {
    if (!selectedSubcourse?.isPaid) {
      return navigate("/plans");
    }
    e.preventDefault();
    const form = new FormData();

    form.append("subjectId", tabId);
    form.append("courseFieldId", selectedId);
    form.append("studentId", user?._id);
    form.append("message", message);
    Object.entries(img).forEach((el) => form.append("imageUrl", el[1]));

    dispatch(askDoubt(form));
    setImg("");
    setMessage("");
    dispatch(doubtBySubject({ subjectId: tabId, limit: 9 }));
  };
  useEffect(() => {
    dispatch(doubtBySubject({ subjectId: tabId, limit: 9 }));
  }, [tabId]);

  // console.log(RecentDoubts,'RecentDoubts')

  // console.log(singleDoubtData,'singleDoubtData');
  return (
    <div>
      {singleDoubtData ? (
        ""
      ) : (
        <div className="justify-center sm:justify-start flex flex-wrap gap-5">
          <ToastContainer />
          {subjectsData &&
            subjectsData?.data?.map((item, i) => {
              return (
                <div
                  key={i}
                  className={`font-medium text-sm font-primary p-2 px-5 border-2 border-primary rounded
                  ${activeTab === i
                      ? ` bg-primary text-white`
                      : `bg-white text-primary`
                    }`}
                  onClick={() => {
                    setTab(i);
                    setTabId(item?._id);
                  }}
                >
                  {item?.subjectName}
                </div>
              );
            })}
        </div>
      )}

      <div className="mt-5">
        {singleDoubtData ? (
          <div>
            <div className="flex justify-between">
              <h1 className="text-2xl md:text-3xl font-semibold capitalize font-primary">
                Solutions By <span className="text-secondary"> Experts</span>{" "}
              </h1>
              <button
                onClick={() => setSingleDoubtData("")}
                className="bg-primary text-white font-medium uppercase px-4 py-2 rounded"
              >
                Ask A Doubt
              </button>
            </div>

            <div className="mt-5">
              <div className="flex gap-6">
                <img
                  src={process.env.REACT_APP_BASE_URL + singleDoubtData?.studentId?.profile_img || UserProfile}
                  className="h-14 w-14 rounded-full"
                />
                <div>
                  <div className="bg-[#FAFAFA] lg:w-[765px] lg:min-h-[125px] rounded-lg p-4 font-primary text-base">
                    {singleDoubtData?.message}
                  </div>
                  <div>
                    {singleDoubtData?.fileUrl &&
                      singleDoubtData.fileUrl.length > 1 ? (

                      <div
                        className="mt-5 h-20 w-20 cursor-pointer flex"
                        onClick={() => {
                          setIsOpen(true);
                        }}
                      >
                        {singleDoubtData.fileUrl.length > 1 ? (
                          singleDoubtData.fileUrl.map((imgsrc) => (
                            <img
                              src={process.env.REACT_APP_BASE_URL + imgsrc}
                              className="h-full w-full rounded "
                              onClick={() => setSolutionImage(imgsrc)}
                            />
                          ))
                        ) : (
                          <img
                            src={process.env.REACT_APP_BASE_URL + singleDoubtData?.fileUrl[0]}
                            className="h-full w-full rounded "
                          />
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="flex gap-6 mt-5">
                <div>
                  <div className="bg-[#FAFAFA] lg:w-[765px] lg:min-h-[125px] rounded-lg p-4 font-primary text-base">
                    {singleDoubtData?.solutionMsg}
                  </div>
                  <div className="mt-4">
                    {singleDoubtData?.solutionImg?.length > 0 ? (
                      singleDoubtData?.solutionImg?.map((imgsrc, index) => (
                        <img
                          key={index}
                          src={process.env.REACT_APP_BASE_URL + imgsrc}
                          className="rounded"
                          style={{ maxWidth: '100px', maxHeight: '100px' }}
                          onClick={() => setSelectedImage(imgsrc)}
                          alt={`Solution Image ${index + 1}`}
                        />
                      ))
                    ) : (
                      
                      <p>No Solution found</p>
                    )}
                    {selectedImage && (
                      <div className="modal">
                        <span className="close " style={{ fontSize: "35px", cursor: "pointer" }} onClick={() => setSelectedImage(null)}>
                          &times;
                        </span>
                        <img src={process.env.REACT_APP_BASE_URL + selectedImage} alt="Enlarged Solution Image" />
                      </div>
                    )}
                  </div>

                </div>
           
                <img
                  src={process.env.REACT_APP_BASE_URL + singleDoubtData?.teacherId?.image || UserProfile}
                  className="h-14 w-14 rounded-full"
                />
              </div>
            </div>
          </div>
        ) : (
          <div>
            <h1 className="text-2xl md:text-[28px] font-semibold capitalize font-primary">
              Ask a <span className="text-secondary"> doubt</span>{" "}
            </h1>
            <div className="mt-5 ">
              <form onSubmit={doubtHandler}>
                <div>
                  <input
                    type="text"
                    required
                    placeholder="Ask Question..."
                    value={message}
                    className="border border-[#C3C3C3] w-full py-2 px-3 outline-none rounded"
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
                <div className="mt-5">
                 <input
        type="file"
        accept="image/png, image/jpeg"
        name=""
        id="upload-img"
        className="hidden"
        onChange={handleFileChange}
        multiple
      />
      {errorMessage && <p className="text-red-500">{errorMessage}</p>}
                  <label htmlFor="upload-img" className="cursor-pointer">
                    <div className="rounded-xl w-full h-56 flex flex-col justify-center items-center border-dotted border-2 border-[#C3C3C3]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="40"
                        height="31"
                        viewBox="0 0 40 31"
                        fill="none"
                      >
                        <path
                          d="M20.1054 22.078C23.1537 22.078 25.6249 19.6069 25.6249 16.5585C25.6249 13.5102 23.1537 11.0391 20.1054 11.0391C17.0571 11.0391 14.5859 13.5102 14.5859 16.5585C14.5859 19.6069 17.0571 22.078 20.1054 22.078Z"
                          fill="#C9C9C9"
                        />
                        <path
                          d="M37.3478 5.51948H30.1897C29.931 5.51948 29.6102 5.35217 29.3601 5.08827L27.0074 1.39712C26.0501 -3.28987e-07 25.6189 0 24.0666 0H16.1323C14.58 0 14.0625 0 13.194 1.39798L10.8388 5.08827C10.6473 5.29698 10.3782 5.51948 10.0954 5.51948V4.13961C10.0954 3.95663 10.0227 3.78114 9.8933 3.65175C9.76391 3.52236 9.58842 3.44968 9.40544 3.44968H5.95576C5.77278 3.44968 5.59729 3.52236 5.46791 3.65175C5.33852 3.78114 5.26583 3.95663 5.26583 4.13961V5.51948H2.85106C2.30211 5.51948 1.77565 5.73755 1.38748 6.12571C0.999318 6.51388 0.78125 7.04034 0.78125 7.58929V28.2873C0.78125 28.8363 0.999318 29.3627 1.38748 29.7509C1.77565 30.1391 2.30211 30.3571 2.85106 30.3571H37.3478C37.8968 30.3571 38.4232 30.1391 38.8114 29.7509C39.1995 29.3627 39.4176 28.8363 39.4176 28.2873V7.58929C39.4176 7.04034 39.1995 6.51388 38.8114 6.12571C38.4232 5.73755 37.8968 5.51948 37.3478 5.51948ZM20.4884 24.829C18.8196 24.9074 17.1662 24.4791 15.7454 23.6005C14.3245 22.7218 13.2027 21.4339 12.5274 19.9059C11.852 18.3779 11.6547 16.6813 11.9614 15.0391C12.2681 13.3969 13.0644 11.8859 14.2456 10.7046C15.4269 9.52336 16.9379 8.72707 18.5801 8.4204C20.2223 8.11374 21.9189 8.31104 23.4469 8.98639C24.9749 9.66174 26.2628 10.7835 27.1415 12.2044C28.0201 13.6252 28.4484 15.2786 28.37 16.9474C28.2712 19.0058 27.4091 20.9537 25.9519 22.4109C24.4946 23.8681 22.5468 24.7302 20.4884 24.829Z"
                          fill="#C9C9C9"
                        />
                      </svg>
                      <p className="font-primary font-medium mt-1">
                        Click a photo of your doubt
                      </p>
                    </div>
                  </label>
                </div>
                {img ? (
                  <div className="mt-4  relative flex  ">
                    {Object.entries(img).map((el, i) => (
                      <div className="flex h-20 w-20 justify-start">
                        <img
                          src={URL.createObjectURL(el[1])}
                          className="h-full w-full rounded "
                        />
                        <button
                          className="relative  -top-2 right-3 bg-[#F6F6F6] rounded-full h-5 w-5"
                          type="button"
                          onClick={(e) =>
                            setImg(
                              Object.fromEntries(
                                Object.entries(img).filter(
                                  ([key, val]) => key !== el[0]
                                )
                              )
                            )
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M7.05469 12.9457L12.948 7.05408M7.05469 7.05408L12.948 12.9457"
                              stroke="#333333"
                              stroke-width="1.2"
                              stroke-linecap="round"
                            />
                          </svg>
                        </button>
                      </div>
                    ))}
                    {/* <img
                      src={URL.createObjectURL(img)}
                      className="h-full w-full rounded "
                    /> */}
                  </div>
                ) : (
                  ""
                )}
                <div>
                  <button
                    className="bg-primary text-white font-medium uppercase mt-5 px-4 py-2 rounded"
                    type="submit"
                  >
                    SEND DOUBTS
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
      </div>

      <div className="">
        <ImageViewer
          openModal={isOpen}
          image={solutionImage}
          setIsOpen={setIsOpen}
        />
      </div>

      <div>
        <RecentDoubts
          doubtsData={subjectDoubts}
          tabId={tabId}
          setSingleDoubtData={setSingleDoubtData}
        />
      </div>
    </div>
  );
};

export default Doubts;
