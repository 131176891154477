import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import verifyOTP from "../../assets/SVG/verifyOTP.svg";
import player from "../../assets/SVG/playerBtn.svg";
import { OTPverify, loginNumber } from "../../features/userSlice";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import useTimer from "../../components/useTimer";
import useWindowDimensions from "../../components/useWindowDimensions";
import { toast } from "react-toastify";
import { ToastContainer } from 'react-toastify';
import Close from "../../assets/images/closeIcon.png";

import '../../../src/assets/css/App.css'

const OTPpage = ({ modal, phoneNumber, setOTPMOdal, setLoginModal }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isMobile } = useWindowDimensions();

  const mobile = useSelector((state) => state.user.userNumber);
  const [otpError, setOtpError] = useState("")
  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const refs = useRef([]);

  const [timer, setTimer] = useTimer(60);

  const handleChange = (index, value) => {
    // Check if the new value is a single digit
    if (value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      // If the value is not empty and there is a next input field, focus on it
      if (value !== "" && index < otp.length - 1) {
        refs.current[index + 1].focus();
      }
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === "Backspace") {
      if (otp[index] === "" && index > 0) {
        const newOtp = [...otp];
        newOtp[index - 1] = "";
        setOtp(newOtp);
        refs.current[index - 1].focus();
      } else if (index > 0) {
        const newOtp = [...otp];
        newOtp[index] = "";
        setOtp(newOtp);
      }
    }
  };
  const handlePaste = (event) => {
    event.preventDefault();
    const pasteData = event.clipboardData.getData("text/plain").trim();

    if (/^\d{1,6}$/.test(pasteData)) {
      const newOtp = [...otp];
      for (let i = 0; i < pasteData.length; i++) {
        newOtp[i] = pasteData.charAt(i);
      }
      setOtp(newOtp);
      refs.current[pasteData.length - 1].focus();
    }
  };

  const token = useSelector((state) => state.user.token);
  const submitHandler = async (e) => {
    e.preventDefault();
    const verifyOtp = otp.join("");

    try {
      const { payload } = await dispatch(
        OTPverify({
          mobile: mobile,
          otp: verifyOtp,
        })
      );

      if (payload) {
        setOTPMOdal(false);
        setLoginModal(false);
      } else {
        toast.error('Please insert correct OTP');
        setOtpError("Please insert correct OTP");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error('An error occurred while verifying OTP');
    }
  };

  const handleResendOtp = () => {
    if (!timer) {
      dispatch(loginNumber(mobile));
      setTimer(60);
    }
  };

  useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [token]);

  return (

    <React.Fragment>
      <ToastContainer />

      {modal ? (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-80">


          <div className=" flex justify-center">
            <div className="relative flex flex-col order-2 lg:order-2 self-center flex-1 items-center sm:py-[45px] sm:px-[67px] w-full ml-auto rounded-2xl">
              <div
                onClick={() => setLoginModal(false)}
                className="absolute sm:top-3 sm:right-3 h-14 w-14 cursor-pointer" style={{ right: "4rem" ,top:"45px" }}
              >
                <img src={Close} alt="Close" />
              </div>
              <div className=" flex flex-col order-2 lg:order-2 self-center flex-1 bg-white items-center py-5 px-3 sm:py-[45px] sm:px-[67px] w-full ml-auto rounded-2xl shadow-xl">
                <h1 className="lg:text-[32px] sm:text-3xl text-2xl leading-none mb-2  text-center  font-semibold font-primary">

                  Verify <span className="text-secondary font-bold">OTP</span>
                </h1>
                <p className="text-[#101111] font-secondary font-normal sm:text-base text-sm text-center">
                  Please enter the OTP below to verify your
                  &nbsp;Account.</p>
                <form
                  className="flex flex-col w-full mx-auto gap-3"
                  onSubmit={submitHandler}
                >
                  <div className="sm:my-5 mt-3 flex justify-end gap-2 items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      className="cursor-pointer"
                      onClick={() => setOTPMOdal(false)}
                    >
                      <path
                        d="M1.75 15.775C1.3375 15.775 0.984251 15.6282 0.690251 15.3347C0.396251 15.0412 0.249501 14.688 0.250001 14.275V3.77498C0.250001 3.36248 0.397001 3.00923 0.691001 2.71523C0.985001 2.42123 1.338 2.27448 1.75 2.27498H8.44375L6.94375 3.77498H1.75V14.275H12.25V9.06248L13.75 7.56248V14.275C13.75 14.6875 13.603 15.0407 13.309 15.3347C13.015 15.6287 12.662 15.7755 12.25 15.775H1.75ZM10.1313 2.70623L11.2 3.75623L6.25 8.70623V9.77497H7.3L12.2688 4.80623L13.3375 5.85623L7.9375 11.275H4.75V8.08748L10.1313 2.70623ZM13.3375 5.85623L10.1313 2.70623L12.0063 0.831226C12.3063 0.531226 12.6658 0.381226 13.0848 0.381226C13.5038 0.381226 13.8568 0.531226 14.1438 0.831226L15.1938 1.89998C15.4813 2.18748 15.625 2.53748 15.625 2.94998C15.625 3.36248 15.4813 3.71248 15.1938 3.99998L13.3375 5.85623Z"
                        fill="#181717"
                      />
                    </svg>
                    <div>
                      <p className="text-[#181717] text-base font-secondary font-normal ">
                        +91-{phoneNumber || "9255XXXXXX"}
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-4 justify-center sm:mb-5">
                    {otp.map((digit, index) => (
                      <input
                        className="w-8 h-8 md:w-12 md:h-12 sm:p-3 p-2 border border-[#C3C3C3] rounded focus:outline-none focus:ring focus:ring-primary"
                        key={index}
                        type={isMobile ? "number" : "text"}
                        maxLength="1"
                        onKeyDown={(e) => handleKeyDown(index, e)}
                        value={digit}
                        onChange={(e) => handleChange(index, e.target.value)}
                        onPaste={handlePaste}
                        ref={(input) => (refs.current[index] = input)}
                        autoFocus={index === 0 ? true : false}
                      />
                    ))}
                  </div>
                  {
                    otpError && <h1 className="text-secondary text-lg font-medium">{otpError}</h1>
                  }

                  <button className="bg-secondary rounded font-bold font-primary uppercase text-white p-3 mb-3 sm:mb-5 text-sm sm:text-base hover:opacity-75">
                    VERIFY
                  </button>
                </form>
                <p
                  className="font-primary cursor-pointer"
                  onClick={handleResendOtp}
                >
                  <a
                    href="#"
                    className="text-primary font-semibold text-sm sm:text-base"
                  >
                    Resend OTP
                  </a>
                  {timer ? ` in ${timer}s` : ""}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <section className="grid grid-cols lg:grid-cols-2 gap-1 sm:gap-[52px] items-center w-full container mx-auto px-4 font-secondary max-w-[1200px]">
          {/* Left */}
          <div className="flex flex-col order-1 lg:order-1 spacing-y-4 flex-1 ">
            <img
              src={verifyOTP}
              className="sm:h-[311px] sm:w-[517px] w-[320px] h-[193px]"
              alt=""
            />
            <p className="text-black font-medium text-sm sm:text-lg mb-5 mt-3 sm:mt-1 lg:text-start text-center">
              Simplify your login process like never before.{" "}
              <span className="text-secondary font-bold text-base sm:text-xl">
                Sign up
              </span>{" "}
              today and{" "}
              <span className="text-secondary font-bold text-base sm:text-xl">
                start experiencing the benefits.
              </span>
            </p>
            {isMobile ? (
              ""
            ) : (
              <div className="flex lg:justify-start justify-center flex-wrap items-center gap-6 ">
                <button className="bg-secondary font-primary rounded font-bold uppercase text-white px-5 py-3 hover:opacity-75">
                  Join Course
                </button>
                <button className="flex items-center gap-3 font-primary font-medium text-black">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="51"
                    viewBox="0 0 50 51"
                    fill="none"
                  >
                    <circle cx="25" cy="25.1511" r="25" fill="#2E90EF" />
                    <path
                      d="M34.2109 25.1511L20.3951 34.2671L20.3951 16.035L34.2109 25.1511Z"
                      fill="white"
                    />
                  </svg>
                  <span> See how it works?</span>
                </button>
              </div>
            )}
          </div>

          {/* Right  */}
          <div className=" flex flex-col order-1 lg:order-2 self-center flex-1 bg-white items-center py-5 px-3 sm:py-[45px] sm:px-[67px] w-full ml-auto rounded-2xl shadow-xl">
            <h1 className="lg:text-[32px] sm:text-3xl text-2xl leading-none mb-2  text-center font-semibold  font-primary">
              Verify <span className="text-secondary font-bold">OTP</span>
            </h1>
            <p className="text-[#101111] font-secondary font-normal text-xs sm:text-base text-center">
              We have sent an OTP on your number
            </p>
            <form
              className="flex flex-col w-full mx-auto gap-3"
              onSubmit={submitHandler}
            >
              <div className="sm:my-5 mt-3 flex justify-end gap-2 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  className="cursor-pointer"
                  onClick={() => setOTPMOdal(false)}
                >
                  <path
                    d="M1.75 15.775C1.3375 15.775 0.984251 15.6282 0.690251 15.3347C0.396251 15.0412 0.249501 14.688 0.250001 14.275V3.77498C0.250001 3.36248 0.397001 3.00923 0.691001 2.71523C0.985001 2.42123 1.338 2.27448 1.75 2.27498H8.44375L6.94375 3.77498H1.75V14.275H12.25V9.06248L13.75 7.56248V14.275C13.75 14.6875 13.603 15.0407 13.309 15.3347C13.015 15.6287 12.662 15.7755 12.25 15.775H1.75ZM10.1313 2.70623L11.2 3.75623L6.25 8.70623V9.77497H7.3L12.2688 4.80623L13.3375 5.85623L7.9375 11.275H4.75V8.08748L10.1313 2.70623ZM13.3375 5.85623L10.1313 2.70623L12.0063 0.831226C12.3063 0.531226 12.6658 0.381226 13.0848 0.381226C13.5038 0.381226 13.8568 0.531226 14.1438 0.831226L15.1938 1.89998C15.4813 2.18748 15.625 2.53748 15.625 2.94998C15.625 3.36248 15.4813 3.71248 15.1938 3.99998L13.3375 5.85623Z"
                    fill="#181717"
                  />
                </svg>
                <div className="pt-0.5 pr-3">
                  <p className="text-[#181717] text-base font-secondary font-normal ">
                    +91-{phoneNumber || "9255XXXXXX"}
                  </p>
                </div>
              </div>
              <div className="flex gap-4 justify-center sm:mb-5">
                {otp.map((digit, index) => (
                  <input
                    className="w-8 h-8 md:w-12 md:h-12 sm:p-3 p-2 border border-[#C3C3C3] rounded focus:outline-none focus:ring focus:ring-primary no-spin-buttons"
                    key={index}
                    type="number"
                    pattern="\d*"
                    maxLength="1"
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    value={digit}
                    onChange={(e) => handleChange(index, e.target.value)}
                    onPaste={handlePaste}
                    ref={(input) => (refs.current[index] = input)}
                    autoFocus={index === 0 ? true : false}
                  />

                ))}
              </div>

              <button className="bg-secondary rounded font-bold font-primary uppercase text-white sm:p-3 p-1 mb-3 sm:mb-5 text-sm sm:text-base hover:opacity-75">
                VERIFY
              </button>
            </form>
            <p
              className="font-primary cursor-pointer"
              onClick={handleResendOtp}
            >
              <a
                href="#"
                className="text-primary font-semibold text-sm sm:text-base"
              >
                Resend OTP
              </a>
              {timer ? ` in ${timer}s` : ""}
            </p>
          </div>
        </section>
      )}
    </React.Fragment>
  );
};

export default OTPpage;
