import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getAllCourses } from "../../features/getCourseSlice";
import { useNavigate } from "react-router-dom";

import { FaMagnifyingGlass } from "react-icons/fa6";
import { enrollCourse } from "../../features/enrollSlice";
import useEnrolledData from "../../components/hooks/useEnrolledData";
import useWindowDimensions from "../../components/useWindowDimensions";

const Goals = () => {

  const dispatch = useDispatch();
  const [courseId, setCourseId] = useState("");
  const [courseFieldId, setCourseFieldId] = useState("");
  const [languageId, setLanguageId] = useState(null);
  const [academicId, setAcademicId] = useState("");

  const navigate = useNavigate();
  const { isMobile } = useWindowDimensions();

  const isLoading = useSelector((state) => state.course.isLoading);
  const selectYourGoal = useSelector(
    (state) => state?.course?.AllCourses?.selectYourGoal
  );
  const selectLanguage = useSelector(
    (state) => state.course.AllCourses.chooseLanguage
  );
  console.log(selectLanguage,'selectLanguage')
  const selectAcademic = useSelector(
    (state) => state.course.AllCourses.selectExamAcademic
  );
  const token = useSelector((state) => state.user.token);

  const [isActive, setIsActive] = useState(false);
  const [isExam, setIsExam] = useState(false);

  const { selectedSubcourse, hasCourse } = useEnrolledData();

  const [selectedGoal, setSelectedGoal] = useState("");

  const academicHandler = async (id) => {
    const data = {
      courseId,
      courseFieldId,
      languageId,
      academicId: id,
    };
    await dispatch(enrollCourse(data));
    navigate("/");
  };

  useEffect(() => {
    dispatch(getAllCourses());
  }, [dispatch]);

  const handleClick = (courseId, courseFieldId) => {
    setCourseId(courseId);
    setCourseFieldId(courseFieldId);
    setIsActive(!isActive);

  };

  const handleClickClose = () => {
  setIsActive(false);
  setIsExam(false); // Add this line to hide the modal
};

  const handleClickStop = (event) => {
    event.stopPropagation();
  };

  const handleSelectGoal = (id) => {
    setSelectedGoal(id);
    const element = document.getElementById(id);
    if (element) {
      window.scrollTo({
        top: element.offsetTop - 25,
        behavior: "smooth",
      });
    }
  };
  useEffect(() => {
    const goals = filterData("");
    setFilteredGoals(goals);
  }, [selectYourGoal]);

  const filterData = (searchText) => {
    console.log(searchText);

    const query = searchText.toLowerCase();
    if (searchText) {
      const filteredData = selectYourGoal?.filter((categoryObj) => {
        return categoryObj.name.toLowerCase().includes(query);
      });

      return filteredData;
    }

    return selectYourGoal;
    // Use filter to keep only the objects that match the search criteria
  };

  const [filteredGoals, setFilteredGoals] = useState();

  return (
    <>
      {isLoading || selectYourGoal === undefined ? (
        <div className="min-h-screen"></div>
      ) : (
        <div className="relative ">
          {/* banner section   */}
          <section className="bg-Goals bg-center bg-no-repeat md:bg-center h-60 w-full relative">
            <div className=" max-w-[1200px]  mx-auto px-4">
              <div className="absolute bottom-1/2 translate-y-2/4 font-primary font-bold text-white">
                <ol className="flex gap-2 items-center text-sm mb-4">
                  <li>
                    <a href="#">Home</a>
                  </li>
                  <li>
                    <svg
                      width="7"
                      height="12"
                      viewBox="0 0 7 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 1L6 6L1 11"
                        stroke="white"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </li>
                  <li>Achieve Your Goal</li>
                </ol>
                <h1 className="lg:text-5xl text-3xl">Achieve Your Goal</h1>
              </div>
              <div className="absolute right-1/2 translate-x-2/4 -bottom-5 lg:w-[460px] w-[300px] shadow-xl rounded bg-white">
                <input
                  type="search"
                  onChange={(e) =>
                    setFilteredGoals(() => filterData(e.target.value))
                  }
                  className="p-4 w-full sm:h-[50px] h-10 pr-14 rounded focus:outline-none"
                  placeholder="Search for your goal"
                />
                <button className="bg-primary absolute sm:h-[50px] h-10 right-0 py-3 sm:px-5 px-3 rounded hover:opacity-75">
                  <FaMagnifyingGlass className="text-white" />
                </button>
              </div>
            </div>
          </section>

          {/* Goals section  */}
          <section className=" max-w-[1200px]  px-4 mx-auto sm:pt-20 sm:pb-14 py-12">
            <div className="flex -mx-4 gap-y-8">
              <div className="w-full px-4">
                {filteredGoals &&
                  filteredGoals.map((goal, index) => (
                    <div
                      className="flex flex-col gap-[18px] mb-[40px] last:mb-0 md:text-start"
                      id={goal?._id}
                    >
                      <h2
                        className={` ${goal._id === selectedGoal
                          ? "font-bold font-primary text-primary "
                          : " font-semibold font-primary sm:text-xl"
                          }font-bold font-primary sm:text-xl  `}
                      >
                        {goal?.name}
                      </h2>
                      <div className="flex flex-wrap sm:gap-x-8 sm:gap-y-6 gap-5">
                        {goal.courseFields.map((course, key) => (
                          <div className="sm:h-[125px] sm:w-[228px] w-[150px] h-[111px] " key={key}>
                            <div
                              onClick={(e) =>
                                handleClick(course.courseId, course._id)
                              }
                              className="flex flex-col border-2 border-transparent justify-center shadow shadow-[#00000040] rounded-lg p-6 hover:shadow-lg hover:border-primary cursor-pointer transition duration-300 h-full w-full"
                            >
                              <img
                                src={process.env.REACT_APP_BASE_URL + course.iconName.fileUrl}
                                alt=""
                                className="sm:h-10 sm:w-10 h-8 w-8 object-contain"
                              />

                              <h3 className="text-sm sm:text-base font-medium font-secondary text-[#383838] sm:mt-4 mt-2">
                                {course.name}{" "}
                              </h3>
                            </div>
                            {/* } */}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
              </div>
              {isMobile ? "" :
                <div className="relative">
                  <div className="sticky top-24 ">
                    {/* right category scroll bar  */}
                    <div className="p-4 w-[362px] shadow shadow-[#00000040] rounded-md overflow-y-auto lg:h-[420px]">
                      {/* <h3 className="font-bold font-primary text-base text-primary mb-2">
                   GATE, ESE and IIT-JAM
                 </h3> */}
                      <ul className="font-secondary">
                        {selectYourGoal &&
                          selectYourGoal.map((goal, index) => (
                            <li
                              key={goal._id}
                              onClick={() => {
                                handleSelectGoal(goal?._id);
                                setSelectedGoal(goal._id);
                              }}
                              className={` cursor-pointer hover:font-medium text-base hover:text-primary mb-4 ${goal?._id === selectedGoal
                                ? "font-bold font-primary text-primary"
                                : "font-normal font-secondary"
                                }`}
                            >
                              {goal?.name}
                            </li>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              }
            </div>
          </section>

          {/* popup language section*/}
          <div
            className={`fixed w-full h-full bottom-0 right-0 bg-[#555F65] bg-opacity-60 z-10 font-secondary ${isActive ? "" : "hidden"
              } `}
            onClick={handleClickClose}
          >
            {/* test  */}
              <div
                id="modal"
                className="flex flex-col justify-center bg-white sm:p-16 p-8 rounded-lg shadow right-1/2 bottom-1/2 absolute translate-y-2/4 translate-x-2/4 text-center"
                onClick={handleClickStop}
              >
                <span>
                  {/* language component  */}

                  <div className="text-black sm:text-[32px] text-2xl font-semibold uppercase font-primary mb-1">
                    Choose <span className="text-secondary font-bold">Language</span>
                  </div>
                  <p className="text-xs font-primary font-medium text-[#555F65] sm:mb-8 mb-6">
                    Click to visit your prefer study language
                  </p>
                  <div className="flex gap-8 justify-center w-full">
                      {selectLanguage.map((language, index) =>
                          (language?.name.toLowerCase() === "english" || language?.name.toLowerCase() === "hindi" || language?.name === "ENGLISH" || language?.name === "HINDI" || language?.name.toLowerCase() === "higlish" ||language?.name === "HIGLISH" ) ?

                        <div
                          key={language._id}
                            onClick={() => {
                              setLanguageId(language._id);
                              setIsExam(true);
                              setIsActive(!isActive);
                            }}
                          className={`flex gap-2 items-center justify-center bg-[#2E90EF1A] px-4 py-6 sm:py-16 rounded-lg sm:h-[200px] sm:w-[250px] shadow-md shadow-[#A3A3A340]
                          w-full text-black font-medium text-sm sm:text-2xl cursor-pointer hover:border-primary hover:border-2 border-transparent border-2 ${language?.name === "English" ? "bg-[#2E90EF1A]" : "bg-[#EC4A5E1A]"} `}
                        >
                          {language?.name === "HINDI" ?
                            <svg width={isMobile ? "30" : "64"} height={isMobile ? "30" : "64"} viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <g filter="url(#filter0_d_2009_324)">
                                <circle cx="32" cy="32" r="30" fill="white" />
                              </g>
                              <path d="M22.1827 20.656C25.8147 20.656 29.7187 21.24 33.8947 22.408V25.36C29.7187 24.128 25.8467 23.512 22.2787 23.512C20.8387 23.512 20.1187 24.152 20.1187 25.432V26.896H22.5187V29.584H20.1187V43H16.7347V29.584H14.3347V26.896H16.7347V25.408C16.7347 23.984 17.2067 22.84 18.1507 21.976C19.0947 21.096 20.4387 20.656 22.1827 20.656ZM33.3967 33.376H27.2767C26.6847 33.376 26.3887 33.592 26.3887 34.024C26.3887 34.44 26.6127 34.824 27.0607 35.176C27.6687 35.048 28.3247 34.984 29.0287 34.984C30.4847 34.984 31.6687 35.328 32.5807 36.016C33.4927 36.704 33.9487 37.568 33.9487 38.608C33.9487 39.216 33.8767 39.68 33.7327 40H30.4207C30.5807 39.696 30.6607 39.296 30.6607 38.8C30.6607 38.496 30.5007 38.248 30.1807 38.056C29.8607 37.848 29.4367 37.744 28.9087 37.744C28.0927 37.744 27.4687 37.896 27.0367 38.2C26.6047 38.504 26.3887 38.904 26.3887 39.4C26.3887 40.696 27.4767 41.344 29.6527 41.344C30.4687 41.344 31.5727 41.192 32.9647 40.888V43.648C31.6847 43.92 30.5327 44.056 29.5087 44.056C27.3967 44.056 25.7807 43.592 24.6607 42.664C23.5407 41.72 22.9807 40.624 22.9807 39.376C22.9807 38.112 23.5087 37.072 24.5647 36.256C23.4447 35.6 22.8847 34.72 22.8847 33.616C22.8847 32.688 23.2287 31.976 23.9167 31.48C24.6047 30.984 25.4927 30.736 26.5807 30.736H30.0367V29.584H21.0847V26.896H35.7487V29.584H33.3967V33.376ZM39.9674 30.832V32.896C40.2714 32.224 40.7354 31.696 41.3594 31.312C41.9994 30.912 42.7594 30.712 43.6394 30.712C44.8874 30.712 45.8634 31.088 46.5674 31.84C47.2714 32.576 47.6234 33.664 47.6234 35.104V43H44.1194V35.752C44.1194 35.032 43.9514 34.488 43.6154 34.12C43.2794 33.736 42.8074 33.544 42.1994 33.544C41.5594 33.544 41.0234 33.8 40.5914 34.312C40.1754 34.808 39.9674 35.56 39.9674 36.568V43H36.4634V30.832H39.9674Z" fill="#EC4A5E" />
                              <defs>
                                <filter id="filter0_d_2009_324" x="0.717949" y="0.717949" width="62.5641" height="62.5641" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                  <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                  <feOffset />
                                  <feGaussianBlur stdDeviation="0.641026" />
                                  <feComposite in2="hardAlpha" operator="out" />
                                  <feColorMatrix type="matrix" values="0 0 0 0 0.819608 0 0 0 0 0.835294 0 0 0 0 0.847059 0 0 0 0.5 0" />
                                  <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2009_324" />
                                  <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2009_324" result="shape" />
                                </filter>
                              </defs>
                            </svg>
                            :
                            <svg
                              width={isMobile ? "30" : "64"}
                              height={isMobile ? "30" : "64"}
                              viewBox="0 0 64 64"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g filter="url(#filter0_d_2809_79280)">
                                <circle
                                  cx="32"
                                  cy="32.1035"
                                  r="30"
                                  fill="white"
                                />
                              </g>
                              <path
                                d="M23.7917 38.1515H30.1277V41.1035H20.0717V24.9275H30.0317V27.8795H23.7917V31.5515H29.3597V34.4795H23.7917V38.1515ZM36.5104 28.9355V30.9995C36.8144 30.3275 37.2784 29.7995 37.9024 29.4155C38.5424 29.0155 39.3024 28.8155 40.1824 28.8155C41.4304 28.8155 42.4064 29.1915 43.1104 29.9435C43.8144 30.6795 44.1664 31.7675 44.1664 33.2075V41.1035H40.6624V33.8555C40.6624 33.1355 40.4944 32.5915 40.1584 32.2235C39.8224 31.8395 39.3504 31.6475 38.7424 31.6475C38.1024 31.6475 37.5664 31.9035 37.1344 32.4155C36.7184 32.9115 36.5104 33.6635 36.5104 34.6715V41.1035H33.0064V28.9355H36.5104Z"
                                fill="#2E90EF"
                              />
                              <defs>
                                <filter
                                  id="filter0_d_2809_79280"
                                  x="0.717949"
                                  y="0.821464"
                                  width="62.5641"
                                  height="62.5641"
                                  filterUnits="userSpaceOnUse"
                                  color-interpolation-filters="sRGB"
                                >
                                  <feFlood
                                    flood-opacity="0"
                                    result="BackgroundImageFix"
                                  />
                                  <feColorMatrix
                                    in="SourceAlpha"
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha"
                                  />
                                  <feOffset />
                                  <feGaussianBlur stdDeviation="0.641026" />
                                  <feComposite in2="hardAlpha" operator="out" />
                                  <feColorMatrix
                                    type="matrix"
                                    values="0 0 0 0 0.819608 0 0 0 0 0.835294 0 0 0 0 0.847059 0 0 0 0.5 0"
                                  />
                                  <feBlend
                                    mode="normal"
                                    in2="BackgroundImageFix"
                                    result="effect1_dropShadow_2809_79280"
                                  />
                                  <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_dropShadow_2809_79280"
                                    result="shape"
                                  />
                                </filter>
                              </defs>
                            </svg>
                          }
                          <span>{language.name}</span>
                        </div>
                        : ""
                    )}
                  </div>

                  {/* academic component  */}
                </span>
              </div>
            
          </div>
<div className={`fixed w-full h-full bottom-0 right-0 bg-[#555F65] bg-opacity-60 z-10 font-secondary ${isExam ? "" : "hidden"
              } `}
            onClick={handleClickClose}
            >
              <div
                id="ExamModal"
                className="flex flex-col justify-center bg-white sm:p-16 p-6 px-8 rounded-lg shadow right-1/2 bottom-1/2 absolute translate-y-2/4 translate-x-2/4 text-center"
                onClick={handleClickStop}
              >
                <span className="">
                  <div className="text-black sm:text-[32px] text-lg font-semibold uppercase font-primary sm:mb-2 mb-1">
                    Select Exam <span className="text-secondary font-bold">Academic</span>
                  </div>
                  <p className="text-xs font-secondary font-medium text-[#555F65] sm:mb-8 mb-4">
                    Click to select your academic level
                  </p>
                  <div className="grid md:grid-cols-2 sm:grid-cols-1 justify-center gap-5 sm:pl-8  ">
                    {selectAcademic?.map((academic, index) => (
                      <div
                        key={academic?._id}
                        onClick={() => {
                          academicHandler(academic._id);
                        }}
                        className="h-14 px-2 w-60  flex items-center border shadow-sm shadow-[#0770BF40] rounded-md text-[#555F65] text-sm font-medium font-secondary hover:border-blue-400 hover:cursor-pointer bg-white roundedsm"
                      >
                        <div className="flex gap-3 items-center">
                          <div className="h-10 w-10 bg-white rounded-full border shadow-sm shadow-[#0770BF40] p-1">
                            <img
                              src={process.env.REACT_APP_BASE_URL +  academic?.iconName?.fileUrl}
                              className="h-full w-full"
                            />
                          </div>
                          <div>{academic?.name}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                </span>
              </div>
              </div>
        </div>
      )}
    </>
  );
};

export default Goals;
