import React from "react";
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const ProgressBarModal = ({ openModal, heading, subHeading, btnName, handleBtn, percentage, percentageValue }) => {
    return (
        <React.Fragment>
            {openModal ? (
                <>
                    <div className=''>
                        <div className="bg-[#555F65] bg-opacity-60  flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-auto my-6 mx-auto">
                                <div className="h-[400px] w-[533px] border-0 rounded-xl shadow-lg relative flex flex-col bg-white outline-none focus:outline-none">
                                    <div className="h-52 w-52 mx-[163px] mt-9">
                                        <CircularProgressbarWithChildren value={percentage}
                                            styles={buildStyles({
                                                strokeLinecap: "butt",
                                                textColor: "#1DB448",
                                                pathColor: "#17B243",
                                                trailColor: "#F1F1F1"
                                            })}>
                                            <div className="font-primary">
                                                <p className="text-base font-semibold">{heading}</p>
                                                <p className="font-bold text-5xl text-[#1DB448]">{percentageValue}</p>
                                            </div>
                                        </CircularProgressbarWithChildren>
                                    </div>
                                    <div className=" mx-8 my-4 text-center font-secondary font-normal">
                                        <p className="text-base">{subHeading}</p>
                                    </div>
                                    <div className="flex items-center justify-center gap-4">
                                        <button
                                            className="bg-primary text-white rounded py-2 px-4  font-primary font-medium text-base shadow-md shadow-[#00000040] "
                                            onClick={() => handleBtn()}>
                                            {btnName}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            ) : ""}
        </React.Fragment>
    );

}
export default ProgressBarModal;