import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ReactComponent as BookmarkIcon } from "../../assets/SVG/bookmark.svg";
import { ReactComponent as PauseIcon } from "../../assets/SVG/pauseIcon.svg";
import { ReactComponent as PlayIcon } from "../../assets/SVG/pre_subscription_courses.svg";
import { ReactComponent as VideoIcon } from "../../assets/SVG/videoIcon.svg";
import { ReactComponent as SVGsave } from "../../assets/SVG/videoSave.svg";
import { ReactComponent as StopIcon } from "../../assets/SVG/videoStopIcon.svg";
import { getPlaylistBySubject, resetData } from "../../features/playlistSlice";
import { ToastContainer, toast } from 'react-toastify';
import saveicon from '../../../src/assets/images/bookmark.png'
import VideoPlayer from "./VideoPlayer";
import ReactHlsPlayer from "react-hls-player";
import NoDataFound from "../../components/NoDataFound";
import PDFview from "../../components/PDFViewer/PDFviewer";
import useEnrolledData from "../../components/hooks/useEnrolledData";
import { addBookmark, removeBookmark } from "../../features/bookmarkSlice";
import { askDoubt, doubtBySubject } from "../../features/doubtSlice";
import { getTest, resetStudyMaterial } from "../../features/testSlice";
import { userData } from "../../features/userSlice";
import 'react-toastify/dist/ReactToastify.css';
import UserProfile from "../../assets/images/UserProfile.png";
import '../../assets/css/App.css'

import {
  currentVidDetails,
  getOverviewBySubject,
  getVideoById,
  resetUrl,
} from "../../features/videoSlice";
import "./test.css";

export const DoubtsComponent = ({ subjectId }) => {
  const user = useSelector(userData);
  // console.log(user, 'user')
  const { selectedId, selectedSubcourse } = useEnrolledData();

  const [img, setImg] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleRemoveImage = (indexToRemove) => {
    const updatedImages = Array.from(img).filter((_, index) => index !== indexToRemove);
    setImg(updatedImages);
  };


  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const videoRef = useRef(null);

  const doubtHandler = async (e) => {
    e.preventDefault();


    if (!selectedSubcourse?.isPaid) {
      return navigate("/plans");
    }

    try {
      const form = new FormData();
      form.append("subjectId", subjectId);
      form.append("courseFieldId", selectedId);
      form.append("studentId", user?._id);
      form.append("message", message);

      // Append all images to the same key "imageUrl"
      for (let i = 0; i < img.length; i++) {
        form.append("imageUrl", img[i]);
      }

      // Dispatch the action to submit the doubt
      await dispatch(askDoubt(form));

      // Clear the state after submitting the doubt
      setImg([]);
      setMessage("");

      // Dispatch the action to refresh doubts after submitting
      dispatch(doubtBySubject({ subjectId: subjectId, limit: 9 }));
    } catch (error) {
      console.error("Error submitting doubt:", error);
      // Handle the error as needed
    }
  };




  return (

    <div>
      <ToastContainer />
      <h1 className="text-2xl md:text-3xl font-semibold capitalize font-primary">
        Ask a <span className="text-secondary"> doubt</span>{" "}
      </h1>
      <div className="mt-5 ">
        <form onSubmit={doubtHandler}>
          <div>
            <input
              type="text"
              required
              value={message}
              placeholder="Ask Question..."
              className="border border-[#C3C3C3] w-full py-2 px-3 outline-none rounded"
              onChange={(e) => setMessage(e.target.value)}
            />
          </div>
          <div className="mt-5">
            <input
              type="file"
              accept="image/png, image/jpeg"
              name=""
              id="upload-img"
              className="hidden"
              onChange={(e) => {
                const files = e.target.files;
                const validFiles = Array.from(files).filter((file) => {
                  const fileExtension = file.type.split("/")[1];
                  return fileExtension === "png" || fileExtension === "jpeg";
                });

                if (validFiles.length === files.length) {
                  setImg(validFiles);
                  setErrorMessage(""); // Clear the error message if all files are valid
                } else {
                  setImg([]);
                  setErrorMessage("Please select only JPG or PNG files.");
                }
              }}
              multiple
            />

            {errorMessage && <p className="text-red-500">{errorMessage}</p>}

            <label htmlFor="upload-img" className="cursor-pointer">
              <div className="rounded-xl w-full h-56 flex flex-col justify-center items-center border-dotted border-2 border-[#C3C3C3]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="31"
                  viewBox="0 0 40 31"
                  fill="none"
                >
                  <path
                    d="M20.1054 22.078C23.1537 22.078 25.6249 19.6069 25.6249 16.5585C25.6249 13.5102 23.1537 11.0391 20.1054 11.0391C17.0571 11.0391 14.5859 13.5102 14.5859 16.5585C14.5859 19.6069 17.0571 22.078 20.1054 22.078Z"
                    fill="#C9C9C9"
                  />
                  <path
                    d="M37.3478 5.51948H30.1897C29.931 5.51948 29.6102 5.35217 29.3601 5.08827L27.0074 1.39712C26.0501 -3.28987e-07 25.6189 0 24.0666 0H16.1323C14.58 0 14.0625 0 13.194 1.39798L10.8388 5.08827C10.6473 5.29698 10.3782 5.51948 10.0954 5.51948V4.13961C10.0954 3.95663 10.0227 3.78114 9.8933 3.65175C9.76391 3.52236 9.58842 3.44968 9.40544 3.44968H5.95576C5.77278 3.44968 5.59729 3.52236 5.46791 3.65175C5.33852 3.78114 5.26583 3.95663 5.26583 4.13961V5.51948H2.85106C2.30211 5.51948 1.77565 5.73755 1.38748 6.12571C0.999318 6.51388 0.78125 7.04034 0.78125 7.58929V28.2873C0.78125 28.8363 0.999318 29.3627 1.38748 29.7509C1.77565 30.1391 2.30211 30.3571 2.85106 30.3571H37.3478C37.8968 30.3571 38.4232 30.1391 38.8114 29.7509C39.1995 29.3627 39.4176 28.8363 39.4176 28.2873V7.58929C39.4176 7.04034 39.1995 6.51388 38.8114 6.12571C38.4232 5.73755 37.8968 5.51948 37.3478 5.51948ZM20.4884 24.829C18.8196 24.9074 17.1662 24.4791 15.7454 23.6005C14.3245 22.7218 13.2027 21.4339 12.5274 19.9059C11.852 18.3779 11.6547 16.6813 11.9614 15.0391C12.2681 13.3969 13.0644 11.8859 14.2456 10.7046C15.4269 9.52336 16.9379 8.72707 18.5801 8.4204C20.2223 8.11374 21.9189 8.31104 23.4469 8.98639C24.9749 9.66174 26.2628 10.7835 27.1415 12.2044C28.0201 13.6252 28.4484 15.2786 28.37 16.9474C28.2712 19.0058 27.4091 20.9537 25.9519 22.4109C24.4946 23.8681 22.5468 24.7302 20.4884 24.829Z"
                    fill="#C9C9C9"
                  />
                </svg>
                <p className="font-primary font-medium mt-1">
                  Click a photo of your doubt
                </p>
              </div>
            </label>
          </div>

          {img && img.length > 0 ? (
            <div className="mt-4 flex flex-wrap">
              {Array.from(img).map((image, index) => (
                <div key={index} className="mr-4 mb-4 h-20 w-20 relative ">
                  <img
                    src={URL.createObjectURL(image)}
                    className="h-full w-full rounded "
                    alt={`Uploaded Image ${index + 1}`}
                  />
                  <button
                    className="absolute -top-2 -right-2 bg-[#F6F6F6] rounded-full h-5 w-5"
                    onClick={() => handleRemoveImage(index)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M7.05469 12.9457L12.948 7.05408M7.05469 7.05408L12.948 12.9457"
                        stroke="#333333"
                        strokeWidth="1.2"
                        strokeLinecap="round"
                      />
                    </svg>
                  </button>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}

          <div>
            <button
              className="bg-primary text-white font-medium uppercase mt-5 px-4 py-2 rounded"
              type="submit"
            >
              SEND DOUBTS
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};


const Videos = () => {
  const currentId = useSelector((state) => state?.videos?.currentVideo?.[0]?._id);
  console.log(currentId, 'currentIdstate')
  const location = useLocation();

  const videoRef = useRef(null);
  const videoId = location?.state?._id; // video id coming from my learning
  // const bookmarks = useSelector(state => state?.videos?.currentVideo[0]?.bookmarks || []);
  // Determine if the video is bookmarked
  const isBookmark = useSelector((state) => state?.videos?.currentVideo[0]?.bookmarks[0]?.isActive);
  const [isBookmarked, setIsBookmarked] = useState(isBookmark);
  const [renderedIcon, setRenderedIcon] = useState(null);

  useEffect(() => {
    setRenderedIcon(isBookmark ? <img src={saveicon} alt="Save Icon" style={{ width: '24px', height: '24px' }} /> : <SVGsave />);
  }, [isBookmark]);

  const handleBookmarkClick = () => {
    const updatedBookmarkState = !isBookmarked;
    setIsBookmarked(updatedBookmarkState);
    setRenderedIcon(updatedBookmarkState ? <img src={saveicon} alt="Save Icon" style={{ width: '24px', height: '24px' }} /> : <SVGsave />);

    if (isBookmarked) {
      dispatch(
        removeBookmark({
          videoId: currentId || videoID || videoId,
          courseFieldId: selectedId,
        })

      );
    } else {
      // console.log(currentId,'currentId')
      // console.log(videoID,'videoID')
      // console.log(videoId,'videoId222')
      // console.log(selectedId,'courseFieldId')
      dispatch(

        addBookmark({
          videoId: currentId || videoID || videoId,
          courseFieldId: selectedId,
        })
      );
    }
  };



  const videoID = location?.state; // video id coming from main page
  const playlistType = location?.state?.playlistType;

  const { id } = useParams(); // subject id to fetch overview and playlist
  const navigate = useNavigate();
  const { loadingState, selectedId, subCourses, selectedSubcourse } =
    useEnrolledData();
  const [tabId, setTabId] = useState(0);
  const [tabs, setTabs] = useState();

  const [currentVideoId, setCurrentId] = useState("");

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const [selected, setSelect] = useState(0);

  const [isOpen, setIsOpen] = useState({}); // Initialize an object to keep track of the open state for each playlist

  const toggle = (i) => {
    setIsOpen((prevState) => ({
      ...prevState,
      [i]: !prevState[i],
    }));

    if (selected === i) {
      // If the clicked playlist is already open, close it
      setSelect(null);
    } else {
      // Otherwise, open the clicked playlist
      setSelect(i);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if the input field is not focused
      if (event.key === 'f' && document.activeElement.tagName !== 'INPUT') {
        toggleFullScreen();
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);


  const toggleFullScreen = () => {
    const videoElement = videoRef.current;

    if (videoElement) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        videoElement.requestFullscreen().catch((err) => {
          console.error('Error attempting to enable full-screen mode:', err);
        });
      }
    }
  };
  const playlistCollection = useSelector(
    (state) => state?.playlist?.videoPageData?.data
  );
  console.log(playlistCollection, 'playlistCollection')

  const url = useSelector((state) => state?.videos?.videoUrl);



  const overviewData = useSelector((state) => state?.videos?.overview);



  const checkid = playlistCollection && playlistCollection?.[0]?.videosDetails?.[0]?._id;

  const [abortController, setController] = useState(null);

  useEffect(() => {
    const controller = new AbortController();
    setController(controller);

    dispatch(

      getPlaylistBySubject({

        subjectId: id,

        playlistType: playlistType ? playlistType : "recorded",

      })

    ).then(({ payload }) => {

      if (payload?.data?.length > 0 && selectedSubcourse?.isPaid) {

        dispatch(getVideoById({ videoId: videoId || videoID || checkid }));

        setCurrentId(videoId || videoID || checkid);

      } else {

        dispatch(

          getVideoById({ videoId: payload?.data?.[0]?.videosDetails?.[0]?._id })

        );

        setCurrentId(payload?.data?.[0]?.videosDetails?.[0]?._id);

      }

    });

    dispatch(getOverviewBySubject({ courseFieldId: selectedId }));



    // if (abortController) {

    //   abortController.abort();

    // }



    // if (selectedSubcourse?.isPaid ) {

    //   dispatch(getVideoById({ videoId: videoId || videoID || checkid }));

    // } else {

    //   console.log("check id",checkid);

    //   console.log( "Playls",playlistCollection?.[0]?.videosDetails[0]?._id)

    //   dispatch(getVideoById({ videoId: checkid }));

    // }



    return () => {

      dispatch(resetUrl());

      dispatch(resetData());

    };

  }, []);


  const videoDetails = useSelector(currentVidDetails);

  const [pdfurl, setPdfUrl] = useState("");



  const { title, description, secondaryTitle, secondaryDescription } =

    overviewData || "";



  const handleClick = (item) => {

    dispatch(getTest(item));

    navigate("/videoTest", { state: item?._id });

    if (!document.fullscreenElement) {

      document.documentElement.requestFullscreen();

    }

  };



  const PdfMaterials = () => {

    return (

      <div className="mt-10">

        {videoDetails.pdfdata?.length ? (

          videoDetails.pdfdata.map((pdffile, i) => {
            console.log(videoDetails, 'videoDetailswdfsdfsad')

            return (

              <>

                <div

                  key={i}

                  className="w-full max-h-20 mb-5 flex items-center justify-between gap-8 bg-white shadow shadow-[#0770BF40] px-3 py-4 rounded-md cursor-pointer "

                  onClick={() => {

                    setPdfUrl(pdffile.fileUrl);

                    // dispatch(

                    //   getStudyMaterialByID({

                    //     studyMaterialId: pdffile._id,

                    //   })

                    // )

                  }}

                >

                  <div className="w-full">

                    <h1 className="font-primary font-semibold text-base">

                      {videoDetails?.title || ''}
                    </h1>

                    <div className="flex justify-between items-center mt-1 ">

                      <p className="font-medium font-primary text-xs text-primary">

                        {videoDetails?.subject?.subjectName || "Chemistry"}

                      </p>

                      <span className="font-semibold font-primary text-xs">

                        {/* {new Date(createdAt).toLocaleDateString()} */}

                      </span>

                    </div>

                  </div>

                </div>

                {pdfurl ? (

                  <div className="bg-[#555F65] bg-opacity-60 flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">

                    <div className="relative bg-white outline-none focus:outline-none">

                      <PDFview

                        pdfUrl={pdfurl}

                        handleClose={() => {

                          dispatch(resetStudyMaterial());

                          setPdfUrl("");

                        }}

                      />

                    </div>

                  </div>

                ) : null}

              </>

              // <div key={pdffile._id}>{pdffile.filename}</div>

            );

          })

        ) : (

          <NoDataFound />

        )}

      </div>

    );

  };







  // console.log(playlistCollection[0].courseFieldId._id === selectedId)



  return (

    <div className="">

      {/* header section  */}



      <section className="bg-course bg-cover bg-no-repeat md:bg-center h-[242px] w-full relative">

        <div className="container max-w-[1200px] mx-auto px-4">

          <div className="absolute bottom-1/2 translate-y-2/4 font-primary font-bold text-white">

            <ol className="flex gap-2 items-center text-sm mb-4">

              <li>

                <a href="/">Home</a>

              </li>

              <li>

                <svg

                  width="7"

                  height="12"

                  viewBox="0 0 7 12"

                  fill="none"

                  xmlns="http://www.w3.org/2000/svg"

                >

                  <path

                    d="M1 1L6 6L1 11"

                    stroke="white"

                    stroke-width="1.5"

                    stroke-linecap="round"

                    stroke-linejoin="round"

                  />

                </svg>

              </li>

              <a href="/mylearning">

                <li>Courses</li>
              </a>

              <li>

                <svg

                  width="7"

                  height="12"

                  viewBox="0 0 7 12"

                  fill="none"

                  xmlns="http://www.w3.org/2000/svg"

                >

                  <path

                    d="M1 1L6 6L1 11"

                    stroke="white"

                    stroke-width="1.5"

                    stroke-linecap="round"

                    stroke-linejoin="round"

                  />

                </svg>

              </li>

              <li>Course Details</li>

            </ol>

            <h1 className="lg:text-5xl text-3xl">Course Details</h1>

          </div>

        </div>

      </section>



      {/*  creating tabs */}

      <div className="container max-w-[1200px] mx-auto my-14">

        {/* video and playlist section  */}



        {/*  button tabs to swtich sections */}

        <div className="flex gap-5 items-center">
          {/* {["Lectures", "Study Material", "MCQ's"].map((item, i) => { */}


          {["Lectures", "Study Material"].map((item, i) => {

            return (

              <button

                className={` font-medium text-sm font-primary p-2 px-5 border-2 border-primary rounded  

              ${tabId === i ? ` bg-primary text-white` : `bg-white text-primary`

                  }`}

                onClick={() => setTabId(i)}

              >

                {item}

              </button>

            );

          })}

        </div>

        {tabId === 0 ? (

          <section className="w-2/3 sm:container mx-auto mt-10 justify-between sm:grid flex flex-col sm:grid-cols-2 md:grid-cols-12">

            <div className="col-span-7">

              {url ? (

                <div style={{ width: "92%" }}>
                  <div className="relative  rounded-lg overflow-hidden flex justify-end">
                    <span className="absolute m-3 cursor-pointer z-10" onClick={handleBookmarkClick}>
                      {renderedIcon}
                    </span>
                    {url && url?.includes(".mp4") ? (

                      <div className="rounded-md overflow-hidden">


                        <VideoPlayer url={url} />


                        <h1 className="text-xl font-semibold mt-5 font-primary">

                          {videoDetails && videoDetails.title}

                        </h1>

                      </div>

                    ) : (

                      <div className="rounded-md overflow-hidden ">

                        <ReactHlsPlayer

                          src={process.env.REACT_APP_BASE_URL + url}

                          autoPlay={true}

                          controls={true}

                          width="746px"

                          height="550"

                        />

                      </div>

                    )}

                  </div>

                </div>

              ) : (
                <div className="bg-gradient-to-r from-[#d0d0d0] to-[#f9f9f9] h-96"></div>
              )}
              {/* overview section   */}
              <section className=" mt-10 col-span-8">
                <div>
                  <h1 className="text-2xl font-semibold font-primary ">
                    {title}
                  </h1>
                </div>
                <div className="my-3">
                  <p className="  text-sm md:text-lg font-normal font-secondary text-justify text-[#383838]">
                    {description}
                  </p>
                </div>
                <div className="text-2xl font-primary font-semibold my-3 ">
                  {secondaryTitle}
                </div>
                <div>
                  <p className="  text-sm md:text-lg font-normal font-secondary text-justify text-[#383838]">
                    {secondaryDescription}
                  </p>
                </div>
                <div className="mt-10">
                  <DoubtsComponent subjectId={id} />
                </div>
              </section>
            </div>
            <div className="md:col-span-1"></div>
            <div className="col-span-3 ">
              <div className="flex gap-2">
                <h1 className="text-[#333] text-2xl font-semibold mb-3 font-primary">
                  Course Playlist
                </h1>
                <PlayIcon />
              </div>

              {playlistCollection &&
                playlistCollection.map((playlist, i) => {
                  return (
                    <div
                      className="border-2 border-[#F7FBFF] rounded-sm shadow-sm mb-3"
                      key={i}
                    >
                      <div
                        onClick={() => toggle(i)}
                        key={i}
                        className={`upper-section cursor-pointer flex items-center justify-between mx-auto ${selected === i ? "bg-[#F7FBFF]" : ""
                          } px-3 py-2 rounded`}
                      >
                        <div className="flex gap-6 items-center">
                          <BookmarkIcon />
                          <div>
                            <p className="font-primary font-medium text-[#555F65] text-sm">
                              {playlist?.playlistName}{" "}
                            </p>
                            <div className="flex items-center gap-2 text-[#555F65] text-sm">
                              <VideoIcon /> {playlist?.videosDetails?.length} videos
                            </div>
                          </div>
                        </div>
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19"
                            height="11"
                            viewBox="0 0 19 11"
                            fill="none"
                            className={isOpen[i] ? "rotate-180" : ""} // Add a rotate class if the playlist is open
                          >
                            <path
                              d="M17.5977 9.82969L9.83203 1.95312L2.06641 9.82969"
                              stroke="#D9D9D9"
                              strokeWidth="2.21875"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </div>
                      </div>
                      <div
                        className={selected === i ? "content show" : "content"}
                      >
                        {playlist.videosDetails &&


                          playlist?.videosDetails.map((video, index) => {



                            const { thumbnail, title, timeDuration, _id } = video;
                            // console.log(video, 'video')

                            const { courseFieldId, teacherDetails, videosDetails } = playlist;
                            const fullname = video?.teacherId?.fullname || "Unknown Teacher";

                            const tecaherimage = video?.teacherId?.image;
                            { console.log(tecaherimage, 'tecaherimage') }

                            return (
                              <div
                                key={_id}
                                className="flex gap-1 items-center  px-3 py-1 mt-2 justify-between  border-b-2 border-b-[#F7FBFF] cursor-pointer hover:bg-[#F7FBFF]"
                                onClick={() => {
                                  setCurrentId(_id);

                                  dispatch(resetUrl());
                                  if (
                                    selectedSubcourse?.isPaid === true &&
                                    courseFieldId?._id === selectedId
                                  ) {
                                    dispatch(getVideoById({ videoId: _id }));
                                  } else if (
                                    (selectedSubcourse?.isPaid === false &&
                                      i !== 0) ||
                                    index !== 0
                                  ) {
                                    navigate("/plans");
                                  } else if (
                                    selectedSubcourse?.isPaid === false &&
                                    courseFieldId._id !== selectedId &&
                                    i === 0 &&
                                    index === 0
                                  ) {
                                    dispatch(getVideoById({ videoId: _id }));
                                  } else {
                                    navigate("/plans");
                                  }
                                }}
                              >
                                <div className="relative col-span-1">
                                  <div className="absolute bottom-0 bg-black text-white text-sm w-full text-center rounded bg-opacity-40">
                                    {" "}
                                    {timeDuration}
                                  </div>
                                  <div className="w-[50px] h-[50px] relative">
                                    <img
                                      src={process.env.REACT_APP_BASE_URL + tecaherimage}
                                      onError={(e) => {
                                        e.target.onerror = null; // Prevent infinite loop if fallback image also fails to load
                                        e.target.src = UserProfile; // Set fallback image URL
                                      }}
                                      className="h-full rounded-sm w-full mr-1 object-cover relative"
                                      alt=""
                                    />

                                    {(selectedSubcourse?.ispaid === false ||
                                      selectedId !== courseFieldId._id) &&
                                      (i !== 0 || index !== 0) && (
                                        <div className="absolute top-0 flex justify-center items-center h-full w-full bg-gradient-to-r from-white to-transparent bg-opacity-30  ">
                                          {" "}
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="20"
                                            height="19"
                                            viewBox="0 0 20 19"
                                            fill="none"
                                          >
                                            <path
                                              d="M5.45739 6.68421H6.22055V5.15789C6.22055 3.05158 7.93002 1.3421 10.0363 1.3421C12.1427 1.3421 13.8521 3.05158 13.8521 5.15789V6.68421H14.6153C15.4548 6.68421 16.1416 7.37105 16.1416 8.21052V15.8421C16.1416 16.6816 15.4548 17.3684 14.6153 17.3684H5.45739C4.61792 17.3684 3.93108 16.6816 3.93108 15.8421V8.21052C3.93108 7.37105 4.61792 6.68421 5.45739 6.68421ZM12.3258 5.15789C12.3258 3.89105 11.3032 2.86842 10.0363 2.86842C8.7695 2.86842 7.74686 3.89105 7.74686 5.15789V6.68421H12.3258V5.15789ZM5.45739 15.8421H14.6153V8.21052H5.45739V15.8421ZM10.0363 13.5526C9.19686 13.5526 8.51002 12.8658 8.51002 12.0263C8.51002 11.1868 9.19686 10.5 10.0363 10.5C10.8758 10.5 11.5627 11.1868 11.5627 12.0263C11.5627 12.8658 10.8758 13.5526 10.0363 13.5526Z"
                                              fill="white"
                                            />
                                          </svg>
                                        </div>
                                      )}
                                  </div>
                                </div>

                                <div className="flex flex-col col-span-3 text-xs ml-3">
                                  <p className="font-semibold text-[#555F65]">
                                    {title.slice(0, 50)}
                                  </p>
                                  <span className="text-[#EC4A5E] font-primary text-[12px] font-normal mt-1">
                                    {" "}
                                    By {fullname}
                                  </span>
                                </div>
                                <div className="flex-1"></div>

                                <div className="">
                                  {_id === currentVideoId ? (
                                    <PauseIcon />
                                  ) : (
                                    <StopIcon />
                                  )}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  );
                })}
            </div>
          </section>
        ) : tabId === 1 ? (
          <PdfMaterials />
        ) : tabId === 2 ? (
          <>
            {videoDetails?.qnalistsId ? (
              <div className="w-full max-h-20 mb-5 mt-10 flex items-center justify-between gap-8 bg-white shadow shadow-[#0770BF40] px-5 py-4 rounded-md cursor-pointer ">
                <div className="w-full">
                  <div className="flex justify-between items-center mt-1 ">
                    <div>
                      <h1 className="font-primary font-semibold text-base">
                        {videoDetails?.qnalistsId?.title?.length > 130
                          ? `${videoDetails?.qnalistsId?.title.slice(
                            0,
                            130
                          )}...`
                          : videoDetails?.qnalistsId?.title}
                      </h1>
                      <div className="flex gap-5">
                        <p className="font-medium font-primary text-xs text-primary">
                          {videoDetails?.subject?.subjectName || "Chemistry"}
                        </p>
                        <span className="font-semibold font-primary text-xs text-[#8F98A8]">
                          {new Date(
                            videoDetails?.qnalistsId?.createdAt
                          ).toLocaleDateString()}
                        </span>
                      </div>
                    </div>
                    <div>
                      <button
                        className={`py-2 px-2 text-sm mt-auto inline-block rounded font-medium font-primary hover:opacity-75 uppercase text-white bg-primary `}
                        onClick={() => {
                          handleClick(videoDetails?.qnalistsId);
                        }}
                      >
                        {"Start Test"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <NoDataFound />
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default Videos;
