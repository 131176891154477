import React, { useRef } from "react";
import { getLiveById, streamInfo } from "../../features/liveSlice";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { io } from "socket.io-client";
import { useEffect } from "react";
import { FiPaperclip } from "react-icons/fi";
import UserProfile from "../../assets/images/UserProfile.png";
import ImageViewer from "../../components/Modal/imageViewer";

const ChatBox = ({ id, courseFieldId }) => {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [image, setImage] = useState("");

  const [message, setMessage] = useState("");
  const [roomId, setRoomId] = useState("");
  const [chat, setChat] = useState([]);
  const [roomCount, setRoomCount] = useState(1);
  const [chathistory, setChathistory] = useState([])

  const chatBoxRef = useRef(null);
  useEffect(() => {
    // Scroll to the bottom when messages change
    scrollToBottom();
  }, [chat]);

  const scrollToBottom = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  };

  const [socketInstance, setSocketInstance] = useState("");

  const [file, setFile] = useState("");
  const stream = useSelector(streamInfo);

  // console.log(stream,'infor')

  const { subject } = stream || {};

  // console.log(subject);

  const actualIp = "13.126.94.67:1000";

  const user = useSelector((state) => state?.user?.profileData[0]);
  // console.log(user,'user')

  const sendChat = (e) => {
    e.preventDefault();

    // Determine if there's any content to send
    const hasContent = message.trim() || file;

    if (hasContent) {
      socketInstance.emit("message", {
        livestreamId: id,
        coursefieldId: courseFieldId,
        studentId: user?._id,
        subjectId: subject?._id,
        message: message.trim() || "Doubt",
        file: file,
        roomId: roomId,
      });

      setMessage("");
      setFile("");
    }
  };


  useEffect(() => {
    dispatch(getLiveById({ liveStreamId: id }));
  }, []);

  useEffect(() => {
    const socket = io("http://api.cotginedu.com");
    setSocketInstance(socket);

    let test = "";

    if (id) {
      test = socket.on("connect", () => {
        if (courseFieldId) {
          socket.emit("connected", {
            livestreamId: id,
            coursefieldId: courseFieldId,
            studentId: user?._id,
          });

        }
      });

      socket.on("connected", (data) => {
        // console.log(data, "test connected");
        const { roomId } = data;
        // console.log(roomId, "roomid");

        socket.emit("joinRoom", {
          roomId: roomId,
          livestreamId: id,
        });

        socket.emit("chathistory", {
          roomId: roomId,
          livestreamId: id,
          coursefieldId: courseFieldId,
        });

        setRoomId(roomId);
      });

      socket.on("chathistory", (data) => {
        setChat(data);
      });

      socket.on("joinRoom", (data) => {
        setRoomCount(data?.livecount);
        // console.log(data, "on join room event ");
      });
    }

    socket.on("message", (data) => {
      // console.log(data, " message");
    });

    test.on("teacher_msg", (data) => {
      // console.log(data,'teacher_msg')
    })

    test.on("message", (data) => {
      setChat((prev) => [...prev, data]);
    });

    socket.on("disconnect", () => {
      socket.close();
    });

    return () => {
      socket.close();
    };
  }, [id]);

  return (
    <div className="font-primary lg:w-[300px] border p-1 py-2 rounded-sm lg:h-full">
      <div className="flex text-base font-semibold gap-1 items-center h-full">
        <div className="animate-ping">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
          >
            <circle cx="4.5" cy="4.5" r="3.5" fill="red" />
          </svg>
        </div>
        <h1 className=" bg-secondary text-white w-fit px-1 rounded text-sm">
          Live{" "}
        </h1>
        <span className="">Chat</span>
      </div>
      <div className="flex items-center gap-1 my-1 text-sm">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          viewBox="0 0 15 15"
          fill="none"
        >
          <g clip-path="url(#clip0_11_22092)">
            <path
              d="M7.47188 6.01357C8.77665 6.01357 9.83438 4.95585 9.83438 3.65107C9.83438 2.3463 8.77665 1.28857 7.47188 1.28857C6.1671 1.28857 5.10937 2.3463 5.10937 3.65107C5.10937 4.95585 6.1671 6.01357 7.47188 6.01357Z"
              stroke="#383838"
              stroke-width="0.885938"
            />
            <path
              d="M12.1982 10.7388C12.2 10.6419 12.2 10.5433 12.2 10.4435C12.2 8.97575 10.0844 7.78564 7.475 7.78564C4.86562 7.78564 2.75 8.97575 2.75 10.4435C2.75 11.9112 2.75 13.1013 7.475 13.1013C8.79268 13.1013 9.743 13.0085 10.4281 12.8432"
              stroke="#383838"
              stroke-width="0.885938"
              stroke-linecap="round"
            />
          </g>
          <defs>
            <clipPath id="clip0_11_22092">
              <rect
                width="14.175"
                height="14.175"
                fill="white"
                transform="translate(0.382812 0.107422)"
              />
            </clipPath>
          </defs>
        </svg>
        <p>{roomCount || 0}</p>
      </div>
      <div
        className="h-[400px] overflow-y-auto bg-[#f3f8ffbb]"
        ref={chatBoxRef}
      >
        {chat.map((text, i) => {
          const { message, studentId, fileurl, teacherId } = text;
          // console.log(studentId,'1111111')

          return (
            <div key={i} className="flex gap-2 my-2 rounded p-1">
   <img
  src={
    teacherId?.image ? process.env.REACT_APP_BASE_URL + teacherId.image :
    studentId?.profile_img ? process.env.REACT_APP_BASE_URL + studentId.profile_img :
    UserProfile
  }
  alt=""
  className="h-8 w-10 rounded-full mt-2"
/>




              <div className="bg-[#eaf4ff] px-1 text-[#8194BF] font-medium w-full rounded">
                <h1 className="text-black font-semibold text-sm">
                  {teacherId ? teacherId?.fullname : studentId?.fullname}
                </h1>
                <p>{message}</p>
                <img
                  src={process.env.REACT_APP_BASE_URL + (fileurl ? fileurl : "")}
                  className="mb-1 max-h-[50px] cursor-pointer"
                  onClick={() => {
                    setIsOpen(true);
                    setImage(fileurl);
                  }}
                  alt=""
                />
              </div>
            </div>
          );
        })}
      </div>
      <form onSubmit={sendChat}>
        <div className="flex pt-1 w-full ">
          <div className="flex items-center px-2  border border-[#c3c3c3] rounded-3xl w-full">
            <input
              type="text"
              name="message"
              value={message}
              className="outline-none lg:w-[240px]"
              placeholder="Type Something..."
              id=""
              onChange={(e) => setMessage(e.target.value)}
            />
            <div className="mr-2">
              <label htmlFor="fileUpload">
                {" "}
                <FiPaperclip />{" "}
              </label>
              <input
                type="file"
                accept="image/*"
                className="hidden"
                id="fileUpload"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
            </div>
            <div></div>
            <div
              className=""
              onClick={(e) => {
                if (message?.trim()) {
                  sendChat(e);
                  setMessage("");
                  setFile("");
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 35 35"
                fill="none"
              >
                <path
                  d="M7.44474 17.62L6.58857 9.91308C6.34335 7.70745 8.61418 6.08725 10.6199 7.03839L27.5506 15.0586C29.7123 16.082 29.7123 19.158 27.5506 20.1814L10.6199 28.2017C8.61418 29.1514 6.34335 27.5326 6.58857 25.327L7.44474 17.62ZM7.44474 17.62L17.3672 17.62"
                  stroke="#2E90EF"
                  stroke-width="2.12625"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          {/* 
          <button
            onClick={() => {
              sendChat();
              setMessage("");
            }}
          >
            click me
          </button> */}
        </div>
      </form>

      {file && <div className="break-words">{file.name}</div>}
      <div className="">
        <ImageViewer openModal={isOpen} image={image} setIsOpen={setIsOpen} />
      </div>
    </div>
  );
};

export default ChatBox;
