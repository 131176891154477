import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../app/AxiosHandler";

export const quickSolutionPlaylist = createAsyncThunk(
  "quick/get",
  async (data, { rejectWithValue }) => {
    try {
      const res = await api
        .post("quicksolution/getplaylist", data)
        .then((res) => res.data);
      return res.data;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const quickVideos = createAsyncThunk(
  "quick/video",
  async (data, { rejectWithValue }) => {
    try {
      const res = await api
        .post("quicksolution/getvideos", data)
        .then((res) => res.data);
      return res.data;
    } catch (error) {
      rejectWithValue(error.message)

    }
  }
);

const initialState = {
  isLoading: false,
  isError: false,
  error: "",
  playlistData: "",
  videosData: "",
};

const quicksolutionSlice = createSlice({
  name: "quickSolution",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(quickSolutionPlaylist.fulfilled, (state, action) => {
        state.playlistData = action.payload;
        state.isLoading = false;
      })
      .addCase(quickSolutionPlaylist.rejected, (state, action) => {
        state.isError = true;
        state.error = action.payload;
        state.playlistData = []
      })
      .addCase(quickSolutionPlaylist.pending, (state, action) => {
        state.isLoading = true;
      });

    builder
      .addCase(quickVideos.fulfilled, (state, action) => {
        state.videosData = action.payload;
        state.isLoading = true;
      })
      .addCase(quickVideos.rejected, (state, action) => {
        state.isError = true;
        state.error = action.payload;
      })
      .addCase(quickVideos.pending, (state, action) => {
        state.isLoading = true;
      });
  },
});

export const quickPlaylist = (state) => state?.quickSolution?.playlistData;

export const videos = (state) => state?.quickSolution?.videosData

export default quicksolutionSlice.reducer;
