import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllBookmark,
  removeBookmark,
  studyMaterialBookmark,
  videoBookmark,
} from "../../features/bookmarkSlice";
import CourseCard from "../../components/CourseCard/CourseCard";
import SavedCard from "../../components/CourseCard/SavedCard";
import SavedIcon from '../../assets/images/savedIcon.png';
import Loader from "../../components/Loader";
import useEnrolledData from "../../components/hooks/useEnrolledData";
import NoDataFound from "../../components/NoDataFound";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PDFViewer from "../../components/PDFViewer/PDFviewer";
import PDFview from "../../components/PDFViewer/PDFviewer";

const Saved = () => {

  const loading = useSelector((state) => state?.bookmarks?.isLoading);
  const { selectedId } = useEnrolledData()

  const [selectedFileUrl, setSelectedFileUrl] = useState(null);

  const handleFileUrlClick = (File) => {
    setSelectedFileUrl(File);
  };
  const handleClose = () => {
    setSelectedFileUrl(null);
  };
  const [activeTab, setTab] = useState(0);
  const savedVideo = useSelector(videoBookmark);
  // const studyMaterial = useSelector(studyMaterialBookmark);
  // const studyMaterialdata = useSelector((state) => state?.bookmarks?.studyMaterialBookmark?.video);
  const studyMaterialdata = useSelector((state) => state?.bookmarks?.studyMaterialBookmark?.studyMaterial);



  // const file =studyMaterialdata?.pdfdata[0]?.fileUrl

  const tabs = [
    {
      name: "Lectures",
      function: () => {
        return (

          <React.Fragment>
            {loading ?
              <Loader />
              :
              <>
                {
                  savedVideo?.length > 0 ?
                    <div className="grid grid-cols-3">
                      {savedVideo &&
                        savedVideo.map((video, i) => {
                          const teacherFullname = video?.videoId?.teacherId?.fullname || "Unknown";
                          const teacherbgimage = video?.videoId?.teacherId?.image || "Unknown";
                          const teacheriamge = video?.videoId?.teacherId?.teacherBgImage || "Unknown";
                          const data = {
                            videoId: video?.videoId?._id,
                            subId: video?.videoId?.subject?._id,
                            subjectName: video?.videoId?.subject?.subjectName,
                            teacherFullname: teacherFullname,
                            teacherbgimage: teacherbgimage,
                            teacheriamge: teacheriamge,
                            thumbnail: video?.videoId?.thumbnail,
                            title: video?.videoId?.title,
                          };
                          return (
                            <div key={i}>
                              <SavedCard props={data} section={"lectures"} />
                            </div>
                          );
                        })}
                    </div>
                    :
                    <NoDataFound />
                }
              </>
            }
          </React.Fragment>

        );
      },
    },
    {
      name: "Study Material",
      function: () => {
        return (
          <React.Fragment>
            {loading ? (
              <Loader />
            ) : (
              <>
                  
                  {console.log(studyMaterialdata, 'studyMaterialdata') }
                  {studyMaterialdata?.length > 0 ? (
                    
                  studyMaterialdata.map((material, i) => {
                    const studymaterialId = material;
                    if (studymaterialId) {
                      const { createdAt, fileUrl, _id, } = studymaterialId;
                      const title = studymaterialId.studymaterialId.title;
                      const subjectName = studymaterialId?.studymaterialId?.subjectId?.subjectName;
                      const File = studymaterialId?.videoId?.pdfdata?.[0]?.fileUrl;

                      console.log(title,'title')
                      return (
                        <div key={_id} className="w-full max-h-20 mb-5 flex items-center justify-between gap-8 bg-white shadow shadow-[#0770BF40] px-3 py-4 rounded-md">
                          <div className="w-full" onClick={() => handleFileUrlClick(File)}>
                            <h1 className="font-primary font-semibold text-base">
                              <h1 className="font-primary font-semibold text-base">
                                {title ? (title.length > 130 ? `${title.slice(0, 130)}...` : title) : 'No Title'}
                              </h1>            </h1>
                            <div className="flex justify-between items-center mt-1">
                              <p className="font-medium font-primary text-xs text-primary">{subjectName || '0'}</p>
                              <span className="font-semibold font-primary text-xs">{new Date(createdAt).toLocaleDateString()}</span>
                            </div>
                          </div>
                          {/* <button
                            onClick={() =>
                              dispatch(
                                removeBookmark({ bookmarkId: material._id })
                              )
                            }
                          >
                            <img src={SavedIcon} />
                          </button> */}
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })
                ) : (
                  <NoDataFound />
                )}
              </>
            )}
            {selectedFileUrl && (
              <div className="bg-[#555F65] bg-opacity-60 flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" onClick={handleClose}>
                <div className="relative bg-white outline-none focus:outline-none">
                  {/* Render PDF viewer if selectedFileUrl is not null */}
                  <PDFview pdfUrl={selectedFileUrl} handleClose={handleClose} />
                </div>
              </div>
            )}
          </React.Fragment>

        );
      },
    },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllBookmark({ courseFieldId: selectedId }));
  }, []);

  return (
    <div>
      <ToastContainer />

      <div className="flex gap-5 items-center">
        {tabs.map((tab, i) => {
          return (
            <button
              key={i}
              className={` font-medium text-sm font-primary p-2 px-5 border-2 border-primary rounded  
               ${activeTab === i
                  ? ` bg-primary text-white`
                  : `bg-white text-primary`
                }`}
              onClick={() => {
                setTab(i);
                tab.function();
              }}
            >
              {tab.name}{" "}
            </button>
          );
        })}
      </div>

      <div className="mt-5">{tabs[activeTab].function()}</div>
    </div>
  );
};

export default Saved;
