import React from "react";
import TestCardPic from "../../assets/images/TestCard.png";
import { getTest } from "../../features/testSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

const TestCard = ({ props, btnName, examType, activeTab, setTab }) => {
  const {
    title,
    subjectId: { subjectName },
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    }
  };

  return (
    <div className="px-3 pb-5 pt-6 shadow shadow-[#00000040] border rounded lg:w-[268px] lg:h-[179px] flex flex-col">
      {activeTab === "Completed Exams" ? (
        <div className="flex justify-between mb-1">
          <div className="border border-primary font-primary font-semibold p-0.5 text-primary text-[11px] leading-[13.41px] rounded-sm uppercase h-full">
            SSC - CGL
          </div>
          <div className="h-full p-0.5 font-primary font-semibold text-[11px] text-[#383838] leading-[13.41px] border border-[#383838CC]">
            {" "}
            12 April, 2023
          </div>
        </div>
      ) : (
        <div className="flex justify-between mb-1">
          <div className="h-[18px]">
            {subjectName ? (
              <div className="border border-primary font-primary font-semibold p-0.5 text-primary text-[11px] leading-[13.41px] rounded-sm uppercase h-full">
                {subjectName || "physics"}
              </div>
            ) : (
              ""
            )}
          </div>
          {props?.courseFieldId?.name ? (
            <div className="h-full p-0.5 font-primary font-semibold text-[11px] text-[#383838] leading-[13.41px] border border-[#383838CC]">
              {" "}
              {props?.courseFieldId?.name || ""}
            </div>
          ) : (
            ""
          )}
        </div>
      )}
      <h3 className="text-black font-primary font-bold text-base leading-[19.5px] break-words mt-1">
        <p>{title && title?.slice(0, 70)}</p>
      </h3>
      <div className="flex-1"></div>
      <div className="flex flex-col">
        {/* button conditions */}
        {activeTab === "Completed Exams" ? (
          <div>
            <button
              disabled
              className={`py-2 px-2 text-sm mt-auto inline-block rounded font-medium font-primary hover:opacity-75 uppercase text-white bg-primary `}
            >
              {"RaNK: 23"}
            </button>
          </div>
        ) : (
          <div>
            {examType === "Exam" ? (
              <>
                {props?.isExpired && props?.isCompleted === false ? (
                  <button
                    disabled
                    style={{
                      backgroundColor: "white",
                      border: "2px solid lightgray",
                      color: "gray",
                    }}
                    className={`py-2 px-2 text-sm mt-auto inline-block rounded font-medium font-primary hover:opacity-75 uppercase}`}
                  >
                    Ended
                  </button>
                ) : props?.isCompleted ? (
                  <button
                    disabled
                    className={`py-2 px-2 text-xs mt-auto inline-block rounded font-medium font-primary uppercase text-[#4BB543] border-2 border-[#4BB543] h-9`}
                  >
                    Completed
                  </button>
                ) : new Date(props?.startTime)?.setMinutes(
                    new Date(props?.startTime)?.getMinutes() - 10
                  ) > new Date() ? (
                  <div className="flex justify-between items-center w-full">
                    <button
                      disabled
                      className={`py-2 px-4 mt-auto rounded font-medium font-primary uppercase text-white bg-[#C3C3C3]`}
                    >
                      Start Test
                    </button>
                    <div className="flex items-center gap-2">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 41 41"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M20.5 40.18C31.369 40.18 40.18 31.369 40.18 20.5C40.18 9.631 31.369 0.82 20.5 0.82C9.631 0.82 0.82 9.631 0.82 20.5C0.82 31.369 9.631 40.18 20.5 40.18Z"
                          stroke="#333333"
                          stroke-width="1.64"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M20.5 1.86372V5.0882"
                          stroke="#2E90EF"
                          stroke-width="1.64"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M20.5 35.912V39.1365"
                          stroke="#2E90EF"
                          stroke-width="1.64"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M1.86328 20.5001H5.08776"
                          stroke="#2E90EF"
                          stroke-width="1.64"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M35.1367 20.5001H39.1345"
                          stroke="#2E90EF"
                          stroke-width="1.64"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M7.32031 7.3223L9.37387 9.37585"
                          stroke="#333333"
                          stroke-width="1.64"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M31.625 31.6244L33.6786 33.6779"
                          stroke="#333333"
                          stroke-width="1.64"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M33.6786 7.32222L31.625 9.37585"
                          stroke="#333333"
                          stroke-width="1.64"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9.37387 31.6243L7.32031 33.6779"
                          stroke="#333333"
                          stroke-width="1.64"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M20.5 11.0946V21.8668H27.8193"
                          stroke="#2E90EF"
                          stroke-width="1.75714"
                          stroke-miterlimit="10"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <circle
                          cx="20.4994"
                          cy="21.9643"
                          r="1.46429"
                          fill="#2E90EF"
                        />
                      </svg>
                      <div>
                        <p className="font-primary font-semibold text-xs">
                          {new Date(props?.startTime).toLocaleTimeString()}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <button
                    className={`py-2 px-2 text-sm mt-auto inline-block rounded font-medium font-primary hover:opacity-75 uppercase text-white bg-primary `}
                    onClick={() => {
                      dispatch(getTest(props));
                      // navigate('/practiseTest', { state: { examType: examType, startTime: new Date() } })
                      // handleClick()
                      setTab("Instructions");
                    }}
                  >
                    {btnName}
                  </button>
                )}
              </>
            ) : examType === "UpcomingExam" ? (
              <button
                disabled
                className={`py-2 px-2 text-sm mt-auto inline-block rounded font-medium font-primary hover:opacity-75 uppercase text-white bg-primary `}
              >
                {"Start Test"}
              </button>
            ) : (
              <button
                className={`py-2 px-2 text-sm mt-auto inline-block rounded font-medium font-primary hover:opacity-75 uppercase text-white bg-primary `}
                onClick={() => {
                  dispatch(getTest(props));
                  navigate("/practiseTest", {
                    state: { examType: examType, startTime: new Date() },
                  });
                  handleClick();
                }}
              >
                {"Start Test"}
              </button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default TestCard;
