import React from "react";
import Blog from "../../components/Blog/Blog";
import { useDispatch, useSelector } from "react-redux";
import { teacherByCourseField, teachers } from "../../features/teacherSlice";
import { getNews } from "../../features/doubtSlice";
import useEnrolledData from "../../components/hooks/useEnrolledData";
import Instructor from "../../components/Instructor/Instructor";
import { useEffect } from "react";

const ApplyCertificate = () => {

    const dispatch = useDispatch();

    const { selectedId, subCourses, loadingState } = useEnrolledData();

    const teachersData = useSelector(teachers);
    const newsData = useSelector((state) => state?.doubt?.newsData);

    useEffect(() => {
        dispatch(getNews({
            "courseFieldId": selectedId
        }));
        dispatch(teacherByCourseField({ courseFieldId: selectedId }));
    }, [selectedId, dispatch]);

    return (
        <React.Fragment>
            <div className="container max-w-[1200px] mx-auto md:px-4 mt-8">
                <h2 className="text-black font-primary font-semibold text-3xl mb-5">
                    Meet Our <span className="text-secondary font-bold">Instructors</span>
                </h2>
                <div className="flex flex-wrap my-6 lg:gap-[30px]">
                    {teachersData &&
                        teachersData.slice(0, 6)?.map((teacher, i) => {
                            const { teacherId } = teacher;

                            return <Instructor teacherData={teacherId} />;
                        })}
                </div>
                <div className="text-center mt-8">
                    <a
                        href="/insturctors"
                        className="py-2 px-4 text-white bg-primary inline-block rounded font-medium font-primary text-base hover:opacity-75 lg:w-[142px] text-center"
                    >
                        VIEW MORE
                    </a>
                </div>
            </div>
            <div className="container max-w-[1200px] mx-auto md:px-4 py-8">
                <h2 className="text-black font-primary font-semibold text-3xl mb-5">
                    Find <span className="text-secondary font-bold">Latest News</span>
                </h2>
                <div className="grid sm:grid-cols-2 md:grid-cols-3 grid-cols-1  -mx-4 gap-y-4">
                    {newsData &&
                        newsData.slice(0, 3).map((news, i) => {
                            const { title, newsImg, _id, createdAt, description } = news;
                            return (
                                <div key={_id}>
                                    <Blog
                                        title={title}
                                        img={newsImg}
                                        date={createdAt}
                                        _id={_id}
                                        description={description}
                                    />
                                </div>
                            );
                        })}
                </div>
                <div className="text-center mt-8">
                    <a
                        href="/news"
                        className="py-2 px-4 text-white bg-primary inline-block rounded font-medium font-primary text-base hover:opacity-75 lg:w-[142px] text-center"
                    >
                        VIEW MORE
                    </a>
                </div>
            </div>
        </React.Fragment>
    );
}
export default ApplyCertificate;