import { useState } from "react";
import UpperFooter from "../../components/UpperFooter/UpperFooter";
import useWindowDimensions from "../../components/useWindowDimensions";
import contactLeft from "../../assets/images/contact-banner.webp"

const ContactUs = () => {

  const { isMobile } = useWindowDimensions();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");

  //error state
  const [error, setError] = useState(false);

  const handleOnChange = (e, type) => {
    let value = e.target.value;
    switch (type) {
      case "name":
        setName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "number":
        setNumber(value);
        break;
      case "message":
        setMessage(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    if (name && email && number) {

    } else {
      setError(true);
    }
  };

  return (
    <div>
      <section className="bg-about-us bg-center bg-no-repeat md:bg-center w-full pt-5">
        <div className="max-w-[1200px]  mx-auto px-4">
          <div className="flex justify-between items-center flex-wrap">
            <div className="about-left py-7 sm:py-0 font-primary font-bold text-white">
              <ol className="flex gap-2 items-center text-sm sm:mb-4 mb-2">
                <li>
                  <a href="#">Home</a>
                </li>
                <li>
                  <svg
                    width="7"
                    height="12"
                    viewBox="0 0 7 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L6 6L1 11"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </li>
                <li>Contact Us</li>
              </ol>
              <h1 className="lg:text-5xl text-xl font-bold">Contact Us</h1>
            </div>
            <div className="about-right">
              <img src={contactLeft} alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className="text-gray-600 body-font relative box-border">
        <div className="container max-w-[1200px] md:px-5 px-2 md:pt-20 pt-10 mx-auto flex sm:flex-nowrap flex-wrap">
          <div
            className="bg-gray-300 rounded-lg overflow-hidden lg:mr-10 sm:mr-5 h-[300px] sm:h-[500px] md:w-6/12  w-full"
          // style={{ height: "500px", width: "600px" }}
          >
            <iframe
              width="100%"
              height="100%"
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              title="map"
              scrolling="no"
              src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d448514.76540668105!2d76.80940977907983!3d28.569113950218657!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x390d0377bc04b6b9%3A0x1c40fb16bb1f179a!2s21%2C%20WEST%2C%20Arihant%20Nagar%2C%20Punjabi%20Bagh%2C%20New%20Delhi%2C%20Delhi%2C%20110026!3m2!1d28.675371!2d77.124584!5e0!3m2!1sen!2sin!4v1716377175859!5m2!1sen!2sin"
            ></iframe>
          </div>
          <div
            className="bg-white flex flex-col md:pb-8 mt-8 md:mt-0 h-[400px] sm:h-[500px] md:w-6/12 w-full justify-center"
          // style={{ width: "485.43px", height: "500px" }}
          >
            <h1
              className="font-semibold mb-2 font-primary sm:text-[32px] text-2xl"
            >
              <span style={{ color: "#EC4A5E", fontWeight: 700 }}>Get in Touch</span> With Us
            </h1>
            <div className="relative mb-4  ">
              <input
                type="text"
                value={name}
                onChange={(e) => handleOnChange(e, "name")}
                placeholder={error && !name ? "Name Required." : "Your Name*"}
                id="name"
                name="name"
                style={{ borderRadius: "0", borderBottom: "1px solid gray" }}
                className={`w-full bg-white rounded ${error && !name ? " placeholder-red-500" : ""
                  } text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out`}
              />
            </div>
            <div className="relative mb-4">
              <input
                type="email"
                value={email}
                onChange={(e) => handleOnChange(e, "email")}
                id="email"
                placeholder={
                  error && !email ? "Email Required." : "Your Email*"
                }
                name="email"
                style={{ borderRadius: "0", borderBottom: "1px solid gray" }}
                className={`w-full bg-white rounded ${error && !email ? " placeholder-red-500" : ""
                  } text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out`}
              />
            </div>
            <div className="relative mb-4">
              <input
                type="text"
                value={number}
                onChange={(e) => handleOnChange(e, "number")}
                id="number"
                placeholder={
                  error && !number ? "Number Required." : "Your Phone Number*"
                }
                name="number"
                style={{ borderRadius: "0", borderBottom: "1px solid gray" }}
                className={`w-full bg-white rounded ${error && !number ? " placeholder-red-500" : ""
                  } text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out`}
              />
            </div>
            <div className="relative mb-4">
              <textarea
                id="message"
                value={message}
                onChange={(e) => handleOnChange(e, "message")}
                name="message"
                rows={isMobile ? "4" : "6"}
                placeholder="Your Message"
                style={{ borderRadius: "0", borderBottom: "1px solid gray" }}
                className="w-full bg-white text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
              ></textarea>
            </div>
            <button
              className="text-white border-0 py-4 font-medium focus:outline-none hover:bg-indigo-600 rounded text-sm"
              onClick={handleSubmit}
              style={{
                backgroundColor: "#2E90EF",
                width: "163px",
                height: "50px",
              }}
            >
              SEND DETAILS
            </button>
          </div>
        </div>
      </section>
      <div classNameName="max-w-[1200px] mx-auto">
        <UpperFooter />
      </div>
    </div>
  );
};
export default ContactUs;
