import React from 'react'
import { PageBanner } from './RefundPolicy'

const TermsConditions = () => {
  return (
    <div>
      <PageBanner link={"Terms & Conditions"} mainTitle={"Terms & Conditons"} bgimage={"liveClasses"} />


      <section className='max-w-[1200px] mx-auto text-justify py-8 px-3'>

        <h1 class="font-primary font-bold text-xl mb-2">1. Introduction</h1>
        <p class="font-secondary font-normal text-base mb-4 pl-5 pr-3">Welcome to Cotgin EDU, Beyond the Books. These Terms and Conditions govern your use of our website, mobile application, and services including the sale of test series, books, notes, courses, and other educational services related to competitive, entrance, and other exams.</p>

        <h1 class="font-primary font-bold text-xl mb-2">2. Acceptance of Terms</h1>
        <p class="font-secondary font-normal text-base mb-4 pl-5 pr-3">By accessing our website or using our services, you agree to be bound by these Terms and Conditions, including our Privacy Policy.</p>

        <h1 class="font-primary font-bold text-xl mb-2">3. Services Description</h1>
        <p class="font-secondary font-normal text-base mb-4 pl-5 pr-3">Cotgin EDU, Beyond the Books offers various educational products and services. A detailed description of each service or product is available on our platform.</p>

        <h1 class="font-primary font-bold text-xl mb-2">4. User Accounts</h1>
        <p class="font-secondary font-normal text-base mb-4 pl-5 pr-3">To access certain services, you may be required to create an account. You agree to provide accurate information and keep your account secure.</p>

        <h1 class="font-primary font-bold text-xl mb-2">5. Intellectual Property Rights</h1>
        <p class="font-secondary font-normal text-base mb-4 pl-5 pr-3">All content on our platforms, including text, graphics, logos, and software, is the property of Cotgin EDU, Beyond the Books or its content suppliers and is protected by intellectual property laws.</p>

        <h1 class="font-primary font-bold text-xl mb-2">6. User Conduct</h1>
        <p class="font-secondary font-normal text-base mb-4 pl-5 pr-3">Users are expected to use our services responsibly and ethically. Any form of cheating, plagiarism, or violation of academic integrity is strictly prohibited.</p>

        <h1 class="font-primary font-bold text-xl mb-2">7. Prohibited Activities</h1>
        <p class="font-secondary font-normal text-base mb-4 pl-5 pr-3">You are prohibited from using our platform for any unlawful purpose or in any way that could damage, disable, overburden, or impair the website or interfere with any other party’s use.</p>

        <h1 class="font-primary font-bold text-xl mb-2">8. Security</h1>
        <p class="font-secondary font-normal text-base mb-4 pl-5 pr-3">Any attempt to hack, alter, or otherwise interfere with our website or mobile application is strictly prohibited and may result in legal action.</p>

        <h1 class="font-primary font-bold text-xl mb-2">9. Disclaimer of Warranties</h1>
        <p class="font-secondary font-normal text-base mb-4 pl-5 pr-3">Our services are provided “as is” without any warranties of any kind, either express or implied.</p>

        <h1 class="font-primary font-bold text-xl mb-2">10. Limitation of Liability</h1>
        <p class="font-secondary font-normal text-base mb-4 pl-8 pr-3">Cotgin EDU, Beyond the Books shall not be liable for any direct, indirect, incidental, special, or consequential damages resulting from the use or inability to use our services.</p>

        <h1 class="font-primary font-bold text-xl mb-2">11. Indemnification</h1>
        <p class="font-secondary font-normal text-base mb-4 pl-7 pr-3">You agree to indemnify and hold harmless Cotgin EDU, Beyond the Books from any claims, damages, or expenses arising from your use of our services.</p>

        <h1 class="font-primary font-bold text-xl mb-2">12. Modification of Terms</h1>
        <p class="font-secondary font-normal text-base mb-4 pl-8 pr-3">We reserve the right to modify these Terms and Conditions at any time. Your continued use of the platform constitutes your acceptance of these changes.</p>

        <h1 class="font-primary font-bold text-xl mb-2">13. Governing Law</h1>
        <p class="font-secondary font-normal text-base mb-4 pl-8 pr-3">These Terms and Conditions shall be governed by and construed in accordance with the laws of India/Delhi</p>

        <h1 class="font-primary font-bold text-xl mb-2">14. Contact Information</h1>
        <p class="font-secondary font-normal text-base mb-4 pl-8 pr-3">For any questions regarding these Terms and Conditions, please contact us at support@Cotgin EDU.co.in</p>


      </section>

    </div>
  )
}

export default TermsConditions