import React from "react";
import {
  Area,
  AreaChart,
  CartesianGrid,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const AreaGraphTest = () => {
  //   const data = [
  //     {
  //       name: "JAN",
  //       uv: 0,
  //     },
  //     {
  //       name: "FEB",
  //       uv: 0,
  //     },
  //     {
  //       name: "MARCH",
  //       uv: 0,
  //     },
  //     {
  //       name: "APR",
  //       uv: 0,
  //     },
  //     {
  //       name: "MAY",
  //       uv: 0,
  //     },
  //     {
  //       name: "JUN",
  //       uv: 0,
  //       //   "pv": 3800,
  //       //   "amt": 2500
  //     },
  //     {
  //       name: "JUL",
  //       uv: 0,
  //       //   "pv": 4300,
  //       //   "amt": 2100
  //     },
  //     {
  //       name: "AUG",
  //       uv: 0,
  //       //   "pv": 4300,
  //       //   "amt": 2100
  //     },
  //     {
  //       name: "SEP",
  //       uv: 0,
  //       //   "pv": 4300,
  //       //   "amt": 2100
  //     },
  //     {
  //       name: "OCT",
  //       uv: 0,
  //       //   "pv": 4300,
  //       //   "amt": 2100
  //     },
  //     {
  //       name: "NOV",
  //       uv: 0,
  //       //   "pv": 4300,
  //       //   "amt": 2100
  //     },
  //     {
  //       name: "DEC",
  //       uv: 0,
  //       //   "pv": 4300,
  //       //   "amt": 2100
  //     },
  //   ];

  const data = [
    { name: "JAN", uv: 100 },
    { name: "FEB", uv: 200 },
    { name: "MARCH", uv: 150 },
    { name: "APR", uv: 300 },
    { name: "MAY", uv: 250 },
    { name: "JUN", uv: 400 },
    { name: "JUL", uv: 350 },
    { name: "AUG", uv: 450 },
    { name: "SEP", uv: 200 },
    { name: "OCT", uv: 300 },
    { name: "NOV", uv: 150 },
    { name: "DEC", uv: 350 },
  ];
  return (
    <AreaChart
      width={750}
      height={500}
      data={data}
      margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
    >
      <defs>
        <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
          <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
        </linearGradient>
        <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
          <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
          <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
        </linearGradient>
      </defs>
      <XAxis dataKey="name" />
      <YAxis type="number" domain={[0, 1000]} />
      <CartesianGrid strokeDasharray="3 3" />
      <Tooltip />
      <Area
        type="monotone"
        dataKey="uv"
        stroke="#8884d8"
        fillOpacity={1}
        fill="url(#colorUv)"
      />
      {/* <Area
        type="monotone"
        dataKey="pv"
        stroke="#82ca9d"
        fillOpacity={1}
        fill="url(#colorPv)"
      /> */}
    </AreaChart>
  );
};

export default AreaGraphTest;
