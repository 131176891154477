import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { newsById } from "../../features/doubtSlice";

const ReadNews = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const news = useSelector((state) => state?.doubt?.singleNews);
  const { title, newsImg, description, createdAt } = news;

  useEffect(() => {
    dispatch(newsById({ newsId: id }));
  }, [dispatch, id]);

  useEffect(() => {
    const handleBackNavigation = () => {
      navigate("/");
    };

    window.addEventListener("popstate", handleBackNavigation);

    return () => {
      window.removeEventListener("popstate", handleBackNavigation);
    };
  }, [navigate]);

  return (
    <div className="">
      <section className="bg-singleNews bg-cover bg-no-repeat md:bg-center lg:h-[400px] w-full relative "></section>
      <div className="bg-white w-full flex flex-col max-w-[1100px] mx-auto relative -mt-[250px] mb-12 py-[100px] px-[97px] shadow shadow-gray-300">
        <h1 className="text-center justify-center font-primary text-xs font-semibold">
          Posted: {createdAt?.slice(0, 10)}
        </h1>
        <h1 className="font-bold text-[#333] font-primary text-4xl capitalize mt-6 text-center">
          {" "}
          {title}
        </h1>
        <hr className="w-36 border-2 rounded justify-center mx-auto border-primary my-6" />
        <div
          className="font-secondary font-normal text-base"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  );
};

export default ReadNews;