import React from 'react'

export const PageBanner = ({ link, mainTitle, bgimage }) => {
    return (
        <section
            className={`bg-${bgimage} bg-cover bg-no-repeat md:bg-center h-[100px] sm:h-60 w-full relative`}
        >
            <div className=" max-w-[1200px]  mx-auto px-4">
                <div className="absolute bottom-1/2 translate-y-2/4 font-primary font-bold text-white">
                    <ol className="flex gap-2 items-center text-sm sm:mb-4">
                        <li>
                            <a href="#">Home</a>
                        </li>
                        <li>
                            <svg
                                width="7"
                                height="12"
                                viewBox="0 0 7 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1 1L6 6L1 11"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </li>
                        <li>{link}</li>
                    </ol>
                    <h1 className="lg:text-5xl text-xl">{mainTitle}</h1>
                </div>
            </div>
        </section>
    );
};
const HowDeactiveUser = () => {
    return (
        <>

            <PageBanner
                link={"Deactive User"}
                mainTitle={"Deactive User"}
                bgimage={"liveClasses"}
            />
            <section className="max-w-[1200px] mx-auto py-8 px-3 ">
                <h3 className="text-2xl font-bold mb-4"> INSTRUCTION ABOUT DELETE ACCOUNT</h3>

                <div className="bg-gray-100 p-4 rounded-md shadow-xl">
                    <h4 className="text-xl font-semibold mb-2">Steps:</h4>
                    <ol className="list-decimal pl-5">
                        <li className="mb-2">Login to your account.</li>
                        <li className="mb-2">Click on your account profile.</li>
                        <li className="mb-2">Go to account settings.</li>
                        <li className="mb-2">Click on the delete button.</li>
                        <li className="mb-2">Confirm the deletion by clicking 'Yes'.</li>
                        <li>Your account will be deleted from everywhere.</li>
                    </ol>
                </div>
            </section>

        </>
    )
}

export default HowDeactiveUser