import React from "react";
import { io } from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";

const DoubtChatTest = () => {
  const [message, setMessage] = useState("");
  const [roomId, setRoomId] = useState("");
  const [chat, setChat] = useState([]);
  const satendraIp = "192.168.1.19:1000";
  const socket = io("http://api.cotginedu.com");

  var id = "655b550f0a33f5920cf084a9";
  var studentId = "654c6a7d54022f1e59cb38bb";
  var subjectId = "65448393e1c61c85e76b1176";
  var coursefieldId = "65447d159beaf5d72c11c7a2";

  const [files, setFile] = useState("");

  const sendChat = () => {
    const data = {
      livestreamId: id,
      coursefieldId: coursefieldId,
      studentId: studentId,
      subjectId: subjectId,
      file: files,
      message: message,
      roomId: roomId,
    };

    socket.emit("message", data);
  };

  useEffect(() => {
    let test = "";

    test = socket.on("connect", () => {
      socket.emit("connected", {
        livestreamId: id,
        coursefieldId: coursefieldId,
        studentId: studentId,
      });
    });

    socket.on("connected", (data) => {
      console.log(data, " connected event");
      const { roomId } = data;
      console.log(roomId);

      socket.emit("joinRoom", {
        roomId: roomId,
        livestreamId: "656421657b7af3d7df5f6180",
      });

      socket.on("joinRoom", (data) => {
        console.log(data, "on join room event ");
      });
      setRoomId(roomId);
    });

    // test.on("teacher_msg" , (data) =>{
    // console.log(data)})

    test.on("message", (data) => {
      console.log(data)
      setChat((prev) => [...prev, data]);
    });

    socket.on("disconnect", () => {});

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div>
      Live doubt chat test
      <div className="h-56  overflow-y-scroll bg-gray-300">
        {chat.map((text, i) => {
          const { message, studentId, fileurl } = text;

          return (
            <div key={i} className="flex gap-2 my-4">
              <img
                src={studentId?.profile_img}
                alt=""
                className="h-10 w-10 rounded-full"
              />
              <div className="bg-blue-400 px-1 rounded-sm text-white font-normal w-full ">
                <p>{studentId?.fullname}</p>
                <p>{message}</p>
                {fileurl ? (
                  <img src={fileurl} className="h-48 w-48" alt="" />
                ) : (
                  ""
                )}
              </div>
            </div>
          );
        })}
      </div>
      <input
        type="text"
        name="message"
        value={message}
        id=""
        onChange={(e) => setMessage(e.target.value)}
      />
      <input type="file" onChange={(e) => setFile(e.target.files[0])} />
      <button
        onClick={() => {
          sendChat();

          setMessage("");
          setFile("");
        }}
        className="bg-blue-400"
      >
        {" "}
        Send Message
      </button>
    </div>
  );
};

export default DoubtChatTest;
