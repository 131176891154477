import React from "react";
import { FaMagnifyingGlass } from "react-icons/fa6";

export const PageBanner = ({ link, mainTitle, bgimage }) => {
  return (
    <section
      className={`bg-${bgimage} bg-cover bg-no-repeat md:bg-center h-[100px] sm:h-60 w-full relative`}
    >
      <div className=" max-w-[1200px]  mx-auto px-4">
        <div className="absolute bottom-1/2 translate-y-2/4 font-primary font-bold text-white">
          <ol className="flex gap-2 items-center text-sm sm:mb-4">
            <li>
              <a href="#">Home</a>
            </li>
            <li>
              <svg
                width="7"
                height="12"
                viewBox="0 0 7 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L6 6L1 11"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </li>
            <li>{link}</li>
          </ol>
          <h1 className="lg:text-5xl text-xl">{mainTitle}</h1>
        </div>
      </div>
    </section>
  );
};

const RefundPolicy = () => {
  return (
    <>
      <PageBanner
        link={"Refund Policy"}
        mainTitle={"Refund Policy"}
        bgimage={"liveClasses"}
      />

      <section className="max-w-[1200px] mx-auto py-8 px-3">
        <h1 class="font-primary font-bold text-xl mb-2">
          At Cotgin EDU, “Beyond the books”
        </h1>
        <p class="font-secondary font-normal text-base mb-4">
          We are committed to providing high-quality educational materials and
          services, including test series, books, notes, courses, and other
          related services for competitive, entrance, and other exams. To
          maintain the integrity and value of our products and services, we
          adhere to a strict No Refund Policy.
        </p>

        <h1 class="font-primary font-bold text-xl mb-2">Policy Details:</h1>

        <h1 class="font-primary font-bold text-xl mb-2">
          1. All Sales Are Final:
        </h1>
        <p class="font-secondary font-normal text-base mb-4 pl-5">
          Once a purchase is made, it is considered final. We do not offer
          refunds, credits, or exchanges for any product or service purchased
          through our platform.
        </p>

        <h1 class="font-primary font-bold text-xl mb-2">
          2. Digital Products and Services:
        </h1>
        <p class="font-secondary font-normal text-base mb-4 pl-5">
          Due to the nature of digital products and services, including but not
          limited to downloadable notes, digital books, online courses, and test
          series, all sales are non-refundable and non-transferable.
        </p>

        <h1 class="font-primary font-bold text-xl mb-2">
          3. Course Enrollment:
        </h1>
        <p class="font-secondary font-normal text-base mb-4 pl-5">
          Once enrolled in a course, participants are not eligible for a refund.
          We encourage our customers to review the course details, including the
          syllabus and prerequisites, thoroughly before enrolling.
        </p>

        <h1 class="font-primary font-bold text-xl mb-2">
          4. Test Series and Evaluation Services:
        </h1>
        <p class="font-secondary font-normal text-base mb-4 pl-5">
          Payments for test series, mock tests, or any evaluation services are
          non-refundable. We ensure the quality and relevance of these tests for
          the specified exams.
        </p>

        <h1 class="font-primary font-bold text-xl mb-2">
          5. Exceptional Circumstances:
        </h1>
        <p class="font-secondary font-normal text-base mb-4 pl-5">
          Refund requests due to exceptional circumstances, such as technical
          issues directly attributable to our platform, will be considered on a
          case-by-case basis. However, the granting of a refund in such
          instances is at the sole discretion of Imthian, Beyond the Books”.
        </p>

        <h1 class="font-primary font-bold text-xl mb-2">
          6. Customer Responsibility:
        </h1>
        <p class="font-secondary font-normal text-base mb-4 pl-5">
          It is the customer’s responsibility to ensure that they have the
          necessary technology, internet access, and compatibility to use our
          digital products and services.
        </p>

        <h1 class="font-primary font-bold text-xl mb-2">
          7. Updates to Policy:
        </h1>
        <p class="font-secondary font-normal text-base mb-4 pl-5">
          Cotgin EDU, Beyond the books” reserves the right to modify this No Refund
          Policy at any time. All changes will be effective immediately upon
          posting and apply to all transactions made post-update.
        </p>

        <h1 class="font-primary font-bold text-xl mb-2">8. Contact Us:</h1>
        <p class="font-secondary font-normal text-base mb-4 pl-5">
          For any queries or concerns regarding this policy, please contact our
          support team at support@CotginEDU.co.in
        </p>

        <p class="font-secondary font-normal text-base mb-4 pl-5">
          By making a purchase through Cotgin EDU, Beyond the books, you
          acknowledge that you have read, understood, and agree to be bound by
          this No Refund Policy.
        </p>
      </section>
    </>
  );
};

export default RefundPolicy;
