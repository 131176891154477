import React from 'react'
import instructorImg from '../../assets/images/instructorImg.png'
import { FaAddressCard, FaStar } from "react-icons/fa6";

const aboutInstructor = (props) => {
    const { name,
        designation,
        qualification,
        experience,
        subjects,
        image,
        additionalInfo, teacherBgImage } = props

        // console.log(teacherBgImage, "line13")
    return (
        <section className="pt-16">
            <div className="container mx-auto px-4">
                <div className="flex flex-wrap -mx-4 gap-y-8">
                    {/* left column */}
                    <div className="lg:w-4/12 w-full px-4">
                        <div className="bg-white shadow-lg py-8 px-4 text-center font-primary rounded-lg">
                            <div className='h-40 w-40 rounded-full overflow-hidden mx-auto mb-3'
                                style={{
                                    backgroundImage: `url(${process.env.REACT_APP_BASE_URL + teacherBgImage})`,
                                    backgroundSize: "cover",
                                }}>
                                <img src={image} alt="" className=" px-4 pt-3 " />
                            </div>
                            <h4 className="text-secondary font-bold text-2xl">{name}</h4>
                            <p className="text-black font-semibold mb-1">{designation}</p>
                            <p className="text-black flex justify-center gap-1">
                                <span className="text-primary font-semibold">Experience: </span>
                                <p className='hover:text-primary'>{experience}</p>
                            </p>
                            <p className="text-black flex justify-center gap-1">
                                <span className="text-primary font-semibold">Subject: </span>
                                <p className='hover:text-primary'>{subjects?.length ? subjects[0]?.subjectName : null|| "unknown Subject"}</p>
                            </p>

                            <p className="text-black flex justify-center gap-1">
                                <span className="text-primary font-semibold">Qualification: </span>
                                <p className='hover:text-primary'>{qualification}</p>
                            </p>

                        </div>
                        {/* <div className="bg-white shadow-lg py-8 px-4 text-center rounded-lg">
                            <ul>
                                <li className='flex justify-between text-black font-medium mb-2'>
                                    <span>Total Course</span>
                                    <span className='text-end'>30</span>
                                </li>
                                <li className='flex justify-between text-black font-medium mb-2'>
                                    <span>Ratings</span>
                                    <span className='flex justify-end gap-1'>
                                        <FaStar fill='#FFCE01' />
                                        <FaStar fill='#FFCE01' />
                                        <FaStar fill='#FFCE01' />
                                        <FaStar fill='#FFCE01' />
                                        <FaStar fill='#FFCE01' />
                                        <span className='text-[#FFCE01]'>5.0</span>
                                    </span>
                                </li>
                                <li className='flex justify-between text-black font-medium mb-2'>
                                    <span>Experiences</span>
                                    <span className='text-end'>12 Years</span>
                                </li>
                                <li className='flex justify-between text-black font-medium mb-2'>
                                    <span>Graduated</span>
                                    <span className='text-end'>Yes</span>
                                </li>
                                <li className='flex justify-between text-black font-medium'>
                                    <span>Languages</span>
                                    <span className='text-end'>Hindi, English</span>
                                </li>
                            </ul>
                        </div> */}
                    </div>
                    {/* right column */}
                    <div className="lg:w-8/12 w-full px-4 lg:text-start text-center">
                        <h2 className="text-black font-primary font-bold md:text-3xl text-2xl mb-4">
                            About <span className="text-secondary">{name}</span>
                        </h2>
                        <p className="text-black font-primary">{additionalInfo ? additionalInfo : ''} </p>
                        {/* <h3 className="font-primary font-bold text-2xl mb-4">Certification</h3>
                        <p className="text-black">{props.certificate ? props.certificate : 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable so urce. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32. Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at Hampden-Sydney College in Virginia, looked up one of the more obscure Latin words, consectetur, from a Lorem Ipsum passage, and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum" (The Extremes of Good and Evil) by Cicero, written in 45 BC. This book is a treatise on the theory of ethics, very popular during the Renaissance. The first line of Lorem Ipsum, "Lorem ipsum dolor sit amet..", comes from a line in section 1.10.32.'}</p> */}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default aboutInstructor
