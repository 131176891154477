import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSubjects } from "../../features/getCourseSlice";
import {
  quickPlaylist,
  quickSolutionPlaylist,
  quickVideos,
  videos,
} from "../../features/quicksolutionSlice";
import { Link } from "react-router-dom";
import PlayBtn from "../../assets/images/playBtn.png";
import useEnrolledData from "../../components/hooks/useEnrolledData";
import Loader from "../../components/Loader";
import NoDataFound from "../../components/NoDataFound";

const QuickSolution = () => {
  const { loadingState, selectedId, subCourses } = useEnrolledData();

  // const studentGoalsData = useSelector(
  //   (state) => state?.enroll?.studentData[0]
  // );

  // const getSelectedId = studentGoalsData?.courses
  //   .flatMap((course) => course.courseFields)
  //   .find((courseField) => courseField.isSelected);

  const subjectsData = useSelector((state) => state?.course?.subjectData);

  const [tabId, setTabId] = useState(
    subjectsData && subjectsData?.data[0]?._id
  );

  const playlistData = useSelector(quickPlaylist);
  const videosData = useSelector(videos);

  const [playlistName, setPlaylistName] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSubjects({ courseFieldId: selectedId })).then(({ payload }) => {
      const { data } = payload;
      // setTabId()

      if (data) {
        dispatch(
          quickSolutionPlaylist({
            subjectId: data?.[0]?._id,
          })
        ).then(({ payload }) => {
          if (payload?.length > 0) {
            dispatch(quickVideos({ playlistId: payload?.[0]?._id }));
          } else {
            // return Promise.reject();
            
          }
        });
      }
    });
  }, []);

  const [activeTab, setTab] = useState(0);
  return (
    <div>
      <div className="justify-center sm:justify-start flex flex-wrap gap-5 lg:min-w-fit">
        {subjectsData &&
          subjectsData?.data?.map((item, i) => {
            return (
              <button
                key={i}
                className={`font-medium text-sm font-primary p-2 px-5 border-2 border-primary rounded
                ${
                  activeTab === i
                    ? ` bg-primary text-white`
                    : `bg-white text-primary`
                }`}
                onClick={() => {
                  setTab(i);
                  setTabId(item?._id);
                  dispatch(
                    quickSolutionPlaylist({ subjectId: item?._id })
                  ).then(({ payload }) => {
                    if (payload && payload?.length > 0) {
                      console.log(payload?.[0]?.playlistName);
                      setPlaylistName(payload?.[0]?.playlistName);

                      dispatch(quickVideos({ playlistId: payload?.[0]?._id }));
                    }
                  });
                }}
              >
                {item?.subjectName}
              </button>
            );
          })}
      </div>

      {loadingState ? (
        <Loader />
      ) : (
        <>
          {playlistData ? (
            <div className="mt-5">
              <div className="flex justify-between items-center ">
                <h1 className=" font-bold text-2xl font-primary ">
                  {" "}
                  {playlistName ||
                    (playlistData && playlistData[0]?.playlistName)}
                </h1>
                <select
                  name=""
                  className="border border-[#C3C3C3] rounded p-2 lg:w-52 font-primary text-sm font-medium text-black outline-none"
                  onChange={(e) => {
                    dispatch(quickVideos({ playlistId: e.target.value })).then(
                      ({ payload }) => {
                        setPlaylistName(payload?.[0]?.playlistName);
                      }
                    );
                  }}
                >
                  {playlistData.map((item, i) => {
                    return (
                      <option
                        key={item?._id}
                        value={item?._id}
                        className="px-3 py-1 border border-[#C3C3C3] rounded-none"
                      >
                        {item?.playlistName}{" "}
                      </option>
                    );
                  })}
                </select>
              </div>

              {/* <div className="mt-5">
            {videosData &&
              videosData[0]?.videosDetails.map((video, i) => {
                const { title, createdAt, _id, subject } = video;

                console.log(video); */}

              <div className="mt-5">
                {videosData &&
                  videosData[0]?.videosDetails.map((video, i) => {
                    const { title, createdAt, _id, subject } = video;

                    return (
                      <div
                        key={_id}
                        className="w-full max-h-20 mb-5 flex items-center justify-between gap-8 bg-white shadow shadow-[#0770BF40] px-3 py-4 rounded-md"
                      >
                        <div className="w-full">
                          <h1 className="font-primary font-semibold text-base">
                            {title?.length > 130
                              ? `${title.slice(0, 130)}...`
                              : title}
                          </h1>
                          <p className="font-semibold font-primary text-xs text-[#C3C3C3] ">
                            {new Date(createdAt).toLocaleDateString()}
                          </p>
                        </div>

                        <div className="">
                          <Link
                            to={`/videos/${subject?._id}`}
                            state={{ _id: _id, playlistType: "quickSolution" }}
                          >
                            <img src={PlayBtn} />
                          </Link>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : (
            <NoDataFound />
          )}
        </>
      )}
    </div>
  );
};

export default QuickSolution;
