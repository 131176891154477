import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNews } from "../../features/doubtSlice";
import Blog from "../../components/Blog/Blog";
import { Link } from "react-router-dom";
import useEnrolledData from "../../components/hooks/useEnrolledData";
import Blognew from "../../components/Blog/Blognew";

const News = () => {
  const dispatch = useDispatch();
  const newsData = useSelector((state) => state?.doubt?.newsData);

  const { selectedId, subCourses, loadingState } = useEnrolledData();

  useEffect(() => {
    dispatch(getNews({
      "courseFieldId": selectedId
    }));
  }, [dispatch, selectedId]);

  let sampleArray = [
    {
      img: "https://iamlearning-dev-bucket.s3.ap-south-1.amazonaws.com/news%2F1698215771872_Thumbnail.jpg",
      title: "Lorem ipsum dolor sit amet, consectetur Dolor sit lorem ipsum dolor sit amet."
    },
    {
      img: "https://iamlearning-dev-bucket.s3.ap-south-1.amazonaws.com/news%2F1698233878275_daily-news-and-latest-updates-live-25-october-2023.webp",
      title: "Lorem ipsum dolor sit amet, consectetur Dolor sit"
    },
    {
      img: "https://iamlearning-dev-bucket.s3.ap-south-1.amazonaws.com/news%2F1698215771872_Thumbnail.jpg",
      title: "Lorem ipsum dolor sit amet, consectetur Dolor sit lorem ipsum dolor sit amet, consectetur Dolor sit"
    },
    {
      img: "https://iamlearning-dev-bucket.s3.ap-south-1.amazonaws.com/news%2F1698233878275_daily-news-and-latest-updates-live-25-october-2023.webp",
      title: "Lorem ipsum dolor sit amet, consectetur Dolor sit"
    },
    {
      img: "https://iamlearning-dev-bucket.s3.ap-south-1.amazonaws.com/news%2F1698215771872_Thumbnail.jpg",
      title: "Lorem ipsum dolor sit amet, consectetur Dolor sit lorem ipsum dolor sit amet, consectetur Dolor sit"
    }
  ]

  return (
    <div>
      <section className="bg-news bg-cover bg-no-repeat md:bg-center lg:h-[242px] w-full relative">
        <div className="max-w-[1200px]  mx-auto px-4">
          <div className="absolute bottom-1/2 translate-y-2/4 font-primary font-bold text-white">
            <ol className="flex gap-2 items-center text-sm mb-4">
              <li>
                <a href="#">Home</a>
              </li>
              <li>
                <svg
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L6 6L1 11"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </li>
              <li>News Updates</li>
            </ol>
            <h1 className="lg:text-5xl text-3xl">News Updates</h1>
          </div>
        </div>
      </section>

      <div className="max-w-[1200px] flex flex-wrap mx-auto my-8 ">
        <div className="w-8/12">
          <h1 className="text-lg font-normal font-secondary pl-3 text-white bg-primary ml-4  p-2 rounded ">
            Latest News
          </h1>
          <div className="grid sm:grid-cols-2 grid-cols-1 gap-4 mt-4">
            {newsData &&
              newsData?.map((news, i) => {
                const { title, newsImg, _id, createdAt, description } = news;
                return (
                  <div key={_id}>
                    <Blog
                      title={title}
                      img={newsImg}
                      date={createdAt}
                      _id={_id}
                      description={description}
                      page={"newsList"}
                    />
                  </div>
                );
              })}
          </div>
        </div>
        <div className="flex-1 "></div>

        <div className="w-3/12 ">
          <div className="bg-primary flex justify-between items-center px-3 rounded">
            <h1 className="text-lg font-normal font-secondary text-white py-2 ">
              Recommended For You
            </h1>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M12.0529 14.8288L13.4669 16.2428L17.7109 11.9998L13.4679 7.75684L12.0539 9.17184L13.8809 10.9998L6.34394 10.9998L6.34394 12.9998L13.8809 12.9998L12.0529 14.8288Z"
                  fill="white"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M19.778 19.778C24.074 15.482 24.074 8.518 19.778 4.222C15.482 -0.0740002 8.518 -0.0740005 4.222 4.222C-0.0739997 8.518 -0.074 15.482 4.222 19.778C8.518 24.074 15.482 24.074 19.778 19.778ZM18.364 18.364C20.0518 16.6762 21.0001 14.387 21.0001 12C21.0001 9.61304 20.0518 7.32384 18.364 5.636C16.6762 3.94816 14.387 2.99994 12 2.99994C9.61304 2.99994 7.32384 3.94816 5.636 5.636C3.94816 7.32384 2.99994 9.61304 2.99994 12C2.99994 14.387 3.94816 16.6762 5.636 18.364C7.32384 20.0518 9.61304 21.0001 12 21.0001C14.387 21.0001 16.6762 20.0518 18.364 18.364Z"
                  fill="white"
                />
              </svg>
            </span>
          </div>
          <div className="mt-5">
            {newsData && newsData?.map((news, i) => {
              const { title, newsImg, _id, createdAt, description } = news;
              return (
                
                <div key={_id}>
                    <Blognew
                      title={title}
                      img={newsImg}
                      date={createdAt}
                      _id={_id}
                      page={"newsList"}
                    />
                  </div>
              );
            })}
          </div>

        </div>
      </div>
    </div>
  );
};

export default News;
