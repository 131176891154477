import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import {
  enrolledData,
  loading,
  studentCourses,
} from "../../features/enrollSlice";

const useEnrolledData = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(studentCourses());
  }, []);

  const data = useSelector(enrolledData);

  const loadingState = useSelector(loading);

  const hasCourse = useSelector((state)=> state?.enroll?.hasCourse);

  const { courses: courseFieldId } =data &&  data?.data?.at(0) || "";

  const selectedId = courseFieldId?.filter(
    (item, i) => item.isSelected === true
  )[0]?._id;

  const selectedSubcourse = courseFieldId?.filter(
    (item) => item.isSelected === true
  )[0];






  return {
    subCourses: courseFieldId,
    loadingState,
    selectedId,
    selectedSubcourse,
    hasCourse
  };
};

export default useEnrolledData;

