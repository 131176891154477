import React from "react";
import { PageBanner } from "./RefundPolicy";

const PrivacyPolicy = () => {
  return (
    <>
      <PageBanner
        link={"Privacy Policy"}
        mainTitle={"Privacy Policy"}
        bgimage={"liveClasses"}
      />
      <section className="max-w-[1200px] mx-auto text-justify py-8 px-3">
        <h1 className="font-primary font-bold text-xl mb-2">1. Introduction</h1>
        <p className="font-secondary font-normal text-base mb-4 pl-5 pr-3">
          Welcome to Cotgin EDU, We are committed to safeguarding
          the privacy of our customers and users. This Privacy Policy explains
          how we collect, use, disclose, and safeguard your information when you
          visit our website, use our mobile application, or engage in our
          services, including but not limited to test series, books, notes,
          courses, and other educational services.
        </p>

        <h1 className="font-primary font-bold text-xl mb-2">
          2. Information Collection and Use
        </h1>
        <p className="font-secondary font-normal text-base mb-4 pl-5 pr-3">
          We collect information that you provide to us directly, such as
          personal details, contact information, and payment information. We
          also collect indirect information through our website and mobile app
          usage, which may include IP addresses, device information, and
          browsing history.
        </p>

        <h1 className="font-primary font-bold text-xl mb-2">
          3. Purpose of Information Collection
        </h1>
        <p className="font-secondary font-normal text-base mb-4 pl-5 pr-3">
          The information collected is used to provide and improve our services,
          process transactions, communicate with you, and for internal analysis
          to enhance user experience.
        </p>

        <h1 className="font-primary font-bold text-xl mb-2">
          4. Information Sharing and Disclosure
        </h1>
        <p className="font-secondary font-normal text-base mb-4 pl-5 pr-3">
          We do not sell, trade, or rent your personal identification
          information to others. We may share generic aggregated demographic
          information not linked to any personal identification information
          regarding visitors and users with our business partners, trusted
          affiliates, and advertisers.
        </p>

        <h1 className="font-primary font-bold text-xl mb-2">
          5. Data Security
        </h1>
        <p className="font-secondary font-normal text-base mb-4 pl-5 pr-3">
          We adopt appropriate data collection, storage, and processing
          practices and security measures to protect against unauthorized
          access, alteration, disclosure, or destruction of your personal
          information and data stored on our site and systems.
        </p>

        <h1 className="font-primary font-bold text-xl mb-2">6. User Rights</h1>
        <p className="font-secondary font-normal text-base mb-4 pl-5 pr-3">
          Users have the right to access, correct, or delete their personal
          information. Users can also opt-out of receiving marketing
          communications from us.
        </p>

        <h1 className="font-primary font-bold text-xl mb-2">
          7. Protection Against Misuse and Unauthorized Access
        </h1>
        <p className="font-secondary font-normal text-base mb-4 pl-5 pr-3">
          Any attempt to hack, damage, or access our website or mobile
          application without authorization is strictly prohibited and will be
          subject to legal action.
        </p>

        <h1 className="font-primary font-bold text-xl mb-2">
          8. Changes to This Privacy Policy
        </h1>
        <p className="font-secondary font-normal text-base mb-4 pl-5 pr-3">
          Cotgin EDU, Beyond the Books has the discretion to update this privacy
          policy at any time. When we do, we will revise the updated date at the
          top of this page. We encourage users to frequently check this page for
          any changes.
        </p>

        <h1 className="font-primary font-bold text-xl mb-2">
          9. Contacting Us
        </h1>
        <p className="font-secondary font-normal text-base mb-4 pl-5 pr-3">
          If you have any questions about this Privacy Policy, the practices of
          this site, or your dealings with this site, please contact us at
          support@cotginedu.com
        </p>
      </section>
    </>
  );
};

export default PrivacyPolicy;
