import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../app/AxiosHandler";

export const getTestlist = createAsyncThunk(
  "test/getTestlist",
  async (data, thunkApi) => {
    try {
      // Simulate an API call with data parameter
      // Replace this with your actual API call
      const response = await api.post(process.env.REACT_APP_GET_TESLIST, data);

      return response.data;
    } catch (error) {
      // Handle network errors
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const submitTest = createAsyncThunk(
  "test/submit",
  async (data, thunkApi) => {
    try {
      const response = await api.post(
        process.env.REACT_APP_SUBMIT_TESLIST,
        data
      );
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const submitExam = createAsyncThunk(
  "exam/submit",
  async (data, thunkApi) => {
    try {
      const response = await api.post(process.env.REACT_APP_SUBMIT_EXAM, data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const submitVideoTest = createAsyncThunk(
  "exam/submit",
  async (data, thunkApi) => {
    try {
      const response = await api.post('attempt/createInBulk', data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getStudyMaterial = createAsyncThunk(
  "studymateria/bysubject",
  async (data, thunkApi) => {
    try {
      const response = await api.post(
        "studymaterial/byplaylistid",
        data
      );
      return response.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getStudyMaterialByID = createAsyncThunk(
  "studymaterial/byID",
  async (data, thunkApi) => {
    try {
      const response = await api
        .post(process.env.REACT_APP_GET_STUDYMATERIAL_BY_ID, data)
        .then((res) => {
          if (res.data && res.data.data && res.data.data.file) {
            const fileUrl = res.data.data.file;
            console.log("File URL:", fileUrl);
            return fileUrl;
          } else {
            return thunkApi.rejectWithValue("Invalid response format");
          }
        });
      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);


export const getTestAnswers = createAsyncThunk(
  "getTestAnswers",
  async (data, thunkApi) => {
    try {
      const response = await api.post(
        process.env.REACT_APP_TEST_ANSWERS_GET,
        data
      );
      console.log(response.data, '22222')
      return response.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getExamAnswers = createAsyncThunk(
  "getExamAnswers",
  async (data, thunkApi) => {
    try {
      const response = await api.post(
        process.env.REACT_APP_EXAMS_ANSWERS_GET,
        data
      );
      return response.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getExamQuestions = createAsyncThunk(
  "getExamQuestions",
  async (data, thunkApi) => {
    try {
      const response = await api.post(
        process.env.REACT_APP_EXAMS_QUESTIONS,
        data
      );
      return response.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getVideoTestQuestions = createAsyncThunk(
  "getQuestions",
  async (data, thunkApi) => {
    try {
      const response = await api.post(
        'qna/qnabyqnalistid',
        data
      );
      return response.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getVideoTestAnswers = createAsyncThunk(
  "getAnswers",
  async (data, thunkApi) => {
    try {
      const response = await api.post(
        'qna/qnalistanswer',
        data
      );
      return response.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getLeaderboard = createAsyncThunk(
  "leaderboard",
  async (data, { rejectWithValue }) => {
    try {
      const res = await api
        .post("attempttest/overallrank", data)
        .then((res) => res.data);

      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getLeaderboardByTest = createAsyncThunk(
  "leaderboardbytest",
  async (data, { rejectWithValue }) => {
    try {
      const res = await api
        .post("attempttest/rankbytestlist", data)
        .then((res) => res.data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const completedExams = createAsyncThunk(
  "completedexam",
  async (data, { rejectWithValue }) => {
    try {
      const res = await api.post("testlists/completedexamlist", data);
      console.log("API Response:", res.data);
      return res.data;
    } catch (error) {
      console.error("API Error:", error.message);
      return rejectWithValue(error.message);
    }
  }
);


export const getchart = createAsyncThunk(
  "examchart",
  async (data, { rejectWithValue }) => {
    try {
      const res = await api
        .post("attempttest/studentchart", data)
        .then((res) => res.data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const initialState = {
  testList: [],
  isLoading: false,
  error: null,
  singleTest: [],
  studyMaterial: [],
  studyMaterialPdf: "",
  submitResponse: "",
  answersData: [],
  questionsData: [],
  mainLeaderBoard: "",
  secondLeaderboard: "",
  completedExams: "",
  leaderChart: "",
};

const testSlice = createSlice({
  name: "test",
  initialState,
  reducers: {
    getTest: (state, action) => {
      state.singleTest = action.payload;
    },
    resetExamData: (state, action) => {
      return {
        testList: [],
        isLoading: false,
        error: null,
        // singleTest: [],
        studyMaterial: [],
        submitResponse: "",
        answersData: [],
        questionsData: [],
        completedExams: "",
      };
    },
    resetStudyMaterial: (state, action) => {
      state.studyMaterialPdf = "";
      // state.studyMaterial=""
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTestlist.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getTestlist.fulfilled, (state, action) => {
        state.isLoading = false;
        state.testList = action.payload;
      })
      .addCase(getTestlist.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.testList = [];
      });

    builder
      .addCase(getStudyMaterial.fulfilled, (state, action) => {
        console.log({ action })
        state.studyMaterial = action.payload;
        state.isLoading = false;
      })
      .addCase(getStudyMaterial.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getStudyMaterial.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
        state.studyMaterial = [];
      });

    builder
      .addCase(getStudyMaterialByID.fulfilled, (state, action) => {
        state.studyMaterialPdf = action.payload;
        state.isLoading = false;
      })
      .addCase(getStudyMaterialByID.pending, (state, action) => {
        state.isLoading = true;
        state.studyMaterialPdf = "";
      })
      .addCase(getStudyMaterialByID.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });

    builder
      .addCase(getTestAnswers.fulfilled, (state, action) => {
        state.answersData = action.payload;
        state.isLoading = false;
      })
      .addCase(getTestAnswers.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getTestAnswers.pending, (state, action) => {
        state.isLoading = true;
      });

    builder
      .addCase(getExamAnswers.fulfilled, (state, action) => {
        state.answersData = action.payload;
        state.isLoading = false;
      })
      .addCase(getExamAnswers.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getExamAnswers.pending, (state, action) => {
        state.isLoading = true;
      });

    builder
      .addCase(getExamQuestions.fulfilled, (state, action) => {
        state.questionsData = action.payload;
        state.isLoading = false;
      })
      .addCase(getExamQuestions.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getExamQuestions.pending, (state, action) => {
        state.isLoading = true;
      });

    builder
      .addCase(getVideoTestQuestions.fulfilled, (state, action) => {
        state.questionsData = action.payload;
        state.isLoading = false;
      })
      .addCase(getVideoTestQuestions.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getVideoTestQuestions.pending, (state, action) => {
        state.isLoading = true;
      });

    builder
      .addCase(getVideoTestAnswers.fulfilled, (state, action) => {
        state.answersData = action.payload;
        state.isLoading = false;
      })
      .addCase(getVideoTestAnswers.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(getVideoTestAnswers.pending, (state, action) => {
        state.isLoading = true;
      });

    builder
      .addCase(submitTest.fulfilled, (state, action) => {
        state.submitResponse = action.payload;
        state.isLoading = false;
      })
      .addCase(submitTest.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(submitTest.pending, (state, action) => {
        state.isLoading = true;
      });

    builder
      .addCase(submitExam.fulfilled, (state, action) => {
        state.submitResponse = action.payload;
        state.isLoading = false;
      })
      .addCase(submitExam.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(submitExam.pending, (state, action) => {
        state.isLoading = true;
      });

    builder
      .addCase(getLeaderboard.fulfilled, (state, action) => {
        state.mainLeaderBoard = action.payload;
        state.isLoading = false;
      })
      .addCase(getLeaderboard.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(getLeaderboard.pending, (state, action) => {
        state.isLoading = true;
      });

    builder
      .addCase(completedExams.fulfilled, (state, action) => {
        state.completedExams = action.payload;

        state.isLoading = false;
      })
      .addCase(completedExams.rejected, (state, action) => {
        state.error = action.payload;
        state.completedExams = "";
        state.isLoading = false;
      })
      .addCase(completedExams.pending, (state, action) => {
        state.isLoading = true;
      });

    builder
      .addCase(getLeaderboardByTest.fulfilled, (state, action) => {
        state.secondLeaderboard = action.payload;
        state.isLoading = false;
      })
      .addCase(getLeaderboardByTest.rejected, (state, action) => {
        state.secondLeaderboard = false;
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(getLeaderboardByTest.pending, (state, action) => {
        state.isLoading = true;
      });

    builder
      .addCase(getchart.fulfilled, (state, action) => {
        state.leaderChart = action.payload;
        state.isLoading = false;
      })
      .addCase(getchart.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getchart.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      });
  },
});

export const { getTest, resetExamData, resetStudyMaterial } = testSlice.actions;

export const mainleaderboard = (state) => state.testlist.mainLeaderBoard;
export const secondLeaderboard = (state) => state.testList.secondLeaderboard;
export const completedExamsLists = (state) => state.testList.completedExams;
export const chart = (state) => state?.testList?.leaderChart;

export default testSlice.reducer;
