import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getUpcomingExams } from "../../features/examSlice";
import TestCard from "./TestCard";
import NoDataFound from "../../components/NoDataFound";

const UpComingExamList = ({ subjectId, exams }) => {

    const dispatch = useDispatch();



    console.log(exams, "line18")

    return (
        <React.Fragment>
            <div className="mt-8" >
                <p className="font-semibold text-3xl font-primary" >Upcoming
                    <span className="text-secondary"> Test Series</span>
                </p>
                <div className="mt-8">
                    {exams && exams?.length ?
                        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 md:gap-y-7 opacity-40 ">
                            {exams?.map((test, i) => {
                                return (
                                    <div key={i}>
                                        <TestCard props={test} btnName={'Start Test'} examType={'UpcomingExam'} />
                                    </div>
                                );
                            })}
                        </div>
                        :
                        <NoDataFound />
                    }
                </div>
            </div>
        </React.Fragment>
    );

}

export default UpComingExamList;