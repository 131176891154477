import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PDFview from "../../components/PDFViewer/PDFviewer";
import {
  addBookmark,
  removeBookmark,
  resetSuccess,
} from "../../features/bookmarkSlice";
import { getSubjects } from "../../features/getCourseSlice";
import {
  getStudyMaterial,
  getStudyMaterialByID,
  resetStudyMaterial,
} from "../../features/testSlice";

import { useNavigate } from "react-router-dom";
import UnSavedIcon from "../../assets/images/bookmark_icon.png";
import SavedIcon from "../../assets/images/savedIcon.png";
import Loader from "../../components/Loader";
import NoDataFound from "../../components/NoDataFound";
import useEnrolledData from "../../components/hooks/useEnrolledData";
import { quickSolutionPlaylist } from "../../features/quicksolutionSlice";

const StudyMaterial = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const subjectsData = useSelector((state) => state?.course?.subjectData);

  const [activeTab, setTab] = useState(0);
  const [tabId, setTabId] = useState(
    subjectsData && subjectsData?.data[0]?._id
  );

  const StudyMaterialData = useSelector(
    (state) => state?.testlist?.studyMaterial
  );

  const pdfurl = useSelector((state) => state?.testlist?.studyMaterialPdf);
  console.log(pdfurl,'1111111')

  const loading = useSelector((state) => state?.testlist?.isLoading);

  const [pdfFile, setPdfFile] = useState("");
  const { loadingState, selectedId, selectedSubcourse } = useEnrolledData();

  const { addBookmarkSuccess, removeBookmarkSuccess } = useSelector(
    (state) => state?.bookmarks
  );

  const [chapters, setChapters] = useState("");
  const [chapterName, setChapterName] = useState("");
  const [selectedChapter, setSelectedChapter] = useState("");
  console.log(chapterName);
  useEffect(() => {
    if (chapters && chapters?.length > 0) {
      setChapterName(chapters[0].playlistName);
      setSelectedChapter(chapters[0]?._id);
    } else {
      setChapterName("");
      setSelectedChapter("");
    }
  }, [chapters]);

useEffect(() => {
 if (loadingState === false) {
    dispatch(getSubjects({ courseFieldId: selectedId })).then(({ payload }) => {
      // Check if payload and payload.data are defined before accessing payload.data.length
      if (payload && payload.data && payload.data.length > 0) {
        dispatch(
          quickSolutionPlaylist({
            subjectId: payload.data[0]._id,
            playlistType: "recorded",
          })
        ).then(({ payload }) => {
          // Check if payload is defined before accessing payload[0]._id
          if (payload && payload.length > 0) {
            dispatch(
              getStudyMaterial({
                playlistId: payload[0]._id,
              })
            );
            setChapters(payload);
          } else {
            setChapters("");
          }
        });
      }
    });
 }
 return () => dispatch(resetStudyMaterial());
}, [selectedId, dispatch, loadingState]); // Include loadingState in the dependency array

  // useEffect(() => {
  //   return () => {
  //     dispatch(resetStudyMaterial());
  //   };
  // }, [dispatch]);

  useEffect(() => {
    if (addBookmarkSuccess || removeBookmarkSuccess) {
      dispatch(getStudyMaterial({ playlistId: selectedChapter }));
      dispatch(resetSuccess());
    }
  }, [addBookmarkSuccess, removeBookmarkSuccess, selectedChapter]);

  return (
    <div>
      <div className="justify-center sm:justify-start flex flex-wrap gap-5 lg:min-w-fit">
        {subjectsData &&
          subjectsData?.data?.map((item, i) => {
            return (
              <div
                key={i}
                className={`font-medium text-sm font-primary p-2 px-5 border-2 border-primary rounded  
                ${
                  activeTab === i
                    ? ` bg-primary text-white`
                    : `bg-white text-primary`
                }`}
                onClick={() => {
                  setTab(i);
                  setTabId(item._id);

                  dispatch(
                    quickSolutionPlaylist({
                      subjectId: item._id,
                      playlistType: "recorded",
                    })
                  ).then(({ payload }) => {
                  if (payload && payload.length > 0) {
                    setChapters(payload);
                    dispatch(
                      getStudyMaterial({
                        playlistId: payload[0]._id,
                      })
                    );
                  } else {
                    setChapters("");
                    dispatch(resetStudyMaterial());
                  }
                });
                }}
              >
                {item?.subjectName}
              </div>
            );
          })}
      </div>

      <div className="flex justify-between mt-5 items-center">
        <p className="font-primary font-semibold text-2xl">
          {chapterName ? chapterName : "Chapter Name"}
        </p>
        <select
          name=""
          value={selectedChapter}
          placeholder="Select Chapter"
          className="border border-[#C3C3C3] rounded p-2 lg:w-52 font-primary text-sm font-medium text-text-black outline-none"
          onChange={(e) => {
            console.group(e.target.dataset);
            dispatch(getStudyMaterial({ playlistId: e.target.value }));

            setSelectedChapter(e.target.value);
          }}
        >
          {chapters &&
            chapters?.map((item, i) => {
              return (
                <option
                  key={item._id}
                  value={item._id}
                  data-playlistname={item.playlistName}
                >
                  {" "}
                  {item.playlistName}{" "}
                </option>
              );
            })}
        </select>
      </div>

      {!loading ? (
        <>
         {StudyMaterialData?.length && chapterName ? (
    <div className="mt-5">
        {StudyMaterialData?.map((item, i) => {
            const {
                title,
                subjectId: { subjectName },
                createdAt,
                fileUrl,
                _id,
            } = item;
            return (
                <div
                    key={i}
                    className="w-full max-h-20 mb-5 flex items-center justify-between gap-8 bg-white shadow shadow-[#0770BF40] px-3 py-4 rounded-md cursor-pointer "
                    onClick={() => {
                        if (selectedSubcourse.isPaid === false) {
                            navigate("/plans");
                        } else {
                            dispatch(
                                getStudyMaterialByID({
                                    studyMaterialId: _id,
                                })
                            );
                        }
                    }}
                >
                    <div className="w-full">
                        <h1 className="font-primary font-semibold text-base">
                            {title?.length > 130
                                ? `${title.slice(0, 130)}...`
                                : title}
                        </h1>
                        <div className="flex justify-between items-center mt-1 ">
                            {subjectName && (
                                <p className="font-medium font-primary text-xs text-primary">
                                    {subjectName}
                                </p>
                            )}
                            <span className="font-medium font-primary text-xs">
                                {new Date(createdAt).toLocaleDateString()}
                            </span>
                        </div>
                    </div>
                    {item?.isBookmark ? (
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                dispatch(
                                    removeBookmark({
                                        bookmarkId:
                                            (item?.bookmarks &&
                                                item?.bookmarks[0]?._id) ||
                                            "",
                                    })
                                );
                            }}
                        >
                            <img src={SavedIcon} alt="Saved Icon" />
                        </button>
                    ) : (
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                dispatch(
                                    addBookmark({
                                        studymaterialId: item?._id,
                                        courseFieldId: selectedId,
                                    })
                                );
                            }}
                        >
                            <div className="h-14 w-14">
                                <img src={UnSavedIcon} alt="Unsaved Icon" />
                            </div>
                        </button>
                    )}
                </div>
            );
        })}
    </div>
) : (
    <NoDataFound />
)}


          {pdfurl ? (
            <div className="bg-[#555F65] bg-opacity-60 flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
              <div className="relative bg-white outline-none focus:outline-none">
                <PDFview
                  pdfUrl={pdfurl}
                  handleClose={() => dispatch(resetStudyMaterial())}
                />
              </div>
            </div>
          ) : null}
        </>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default StudyMaterial;
