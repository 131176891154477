import React, { useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import '../../assets/css/App.css';

const LiveStreamingPlayer = ({ url, playing, controls, width, height }) => {
    const playerRef = useRef(null);
    const [progress, setProgress] = useState(0);
    const [isSeeking, setIsSeeking] = useState(false);

    const handleSeekMouseDown = () => {
        setIsSeeking(true);
    };

    const handleSeekChange = (e) => {
        const seekTime = parseFloat(e.target.value);
        setProgress(seekTime);
    };

    const handleSeekMouseUp = () => {
        setIsSeeking(false);
        playerRef.current.seekTo(progress);
    };

    const handleProgress = (state) => {
        if (!isSeeking) {
            setProgress(state.played);
        }
    };

    return (
        <div className="custom-player">
            <ReactPlayer
                ref={playerRef}
                url={url}
                playing={playing}
                controls={false}
                width={width}
                height={height}
                onProgress={handleProgress}
            />
            <input
                type="range"
                min={0}
                id='myRange'
                max={1}
                step="any"
                value={progress}
                className='bar-point'
                onMouseDown={handleSeekMouseDown}
                onChange={handleSeekChange}
                onMouseUp={handleSeekMouseUp}
                style={{
                    width: '100%',
                    background: 'linear-gradient(to right, #ff0000, #ff0000 ' + (progress * 100) + '%, #d3d3d3 ' + (progress * 100) + '%, #d3d3d3)',
                    color: '#ff0000',
                    appearance: 'none',
                    outline: 'none',
                    cursor: 'pointer',
                    height: '5px',
                    borderRadius: '5px'
                }}
            />
        </div>
    );
};

export default LiveStreamingPlayer;
