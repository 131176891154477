import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUpcomingStreamBySubjects } from "../../features/liveSlice";
import { useEffect } from "react";
import VideoCard from "../../components/Card/VideoCard";
import NoDataFound from "../../components/NoDataFound";

const UpComingLiveClasses = ({ subjectId, UpcomingLiveStreams }) => {

  return (
    <div className="">
      <div className="" >
        <p className="text-2xl md:text-3xl font-semibold capitalize font-primary">Upcoming
          <span className="text-secondary"> Live Classes</span>
        </p>
        <div className="opacity-40">
          {UpcomingLiveStreams && UpcomingLiveStreams?.length ?
            <div className="grid md:grid-cols-2 lg:grid-cols-3 lg:flex-wrap gap-y-7 mt-8 w-full">
              {UpcomingLiveStreams?.map((item, i) => {
                return (
                  <React.Fragment key={i}>
                    <VideoCard
                      thumbnail={item?.teacherId?.image}
                      backgroundImg={item?.teacherId?.teacherBgImage}
                      title={item?.title}
                      fullname={item?.teacherId?.fullname}
                      showSubjectDetails={true}
                      subjectName={item?.subject?.subjectName}
                      courseName={item?.courseFieldId?.name}
                      btnName="Watch Class"
                      type={'Upcoming'}
                      classTime={new Date(item?.timeSchedule).toLocaleString()}
                    />
                  </React.Fragment>
                );
              })
              }
            </div>
            :
            <NoDataFound />
          }
        </div>
      </div>
    </div>
  );

}

export default UpComingLiveClasses;