import Close from '../../assets/images/closeIcon.png';

const ImageViewer = ({ openModal, image, setIsOpen }) => {
    return (
        <div className=''>
            {openModal ? (
                <div className="bg-[#555F65] bg-opacity-60 flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                    <div className="flex justify-center items-center w-auto my-6 mx-auto">
                        <div className='lg:min-h-1/2 px-16 rounded relative bg-white shadow shadow-[#00000040] border-[#635f5f40]'>
                            <div className="absolute right-0 top-2">
                                <button className="h-10 w-10 mx-2 text-white"
                                    onClick={() => setIsOpen(false)}>
                                    <img src={Close} className='' />
                                </button>
                            </div>
                            <img src={process.env.REACT_APP_BASE_URL + image} className='py-14 max-h-[800px]' />
                        </div>
                    </div>
                </div>
            ) : ""}
        </div>
    );
}

export default ImageViewer;