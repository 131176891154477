import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

export default function useWindowDimensions() {

  const [isMobile, setisMobile] = useState(false);
  const [isTablet, setisTablet] = useState(false);
  const [isDesktop, setisDesktop] = useState(false);

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);



  useEffect(() => {
    if (windowDimensions?.width <= 640) {
      setisMobile(true);
      setisTablet(false);
      setisDesktop(false);
    }
    else if (windowDimensions?.width >= 640 && windowDimensions?.width <= 1024) {
      setisTablet(true);
      setisMobile(false);
      setisDesktop(false);
    }
    else {
      setisDesktop(true);
      setisMobile(false);
      setisTablet(false);
    }
  }, [windowDimensions?.width])

  return { windowDimensions, isMobile, isTablet, isDesktop };
}
