import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = 0;

const myLearningSlice = createSlice({
    name: "myLearning",
    initialState,
    reducers: {
        setActiveTab: (state, action) => {
            return state = action.payload
        },
        ResetTab: (state, action) => {
            return state = action.payload
        }
    },
});

export const { setActiveTab, ResetTab } = myLearningSlice.actions

export default myLearningSlice.reducer;
