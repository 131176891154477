import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../app/AxiosHandler";

export const getExamList = createAsyncThunk(
  "exams/get",
  async (data, thunkApi) => {
    try {
      const res = await api
        .post("/testlists/examlist", data)
        .then((res) => res.data);

      return res.data;
    } catch (error) {
      thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getUpcomingExams = createAsyncThunk(
  "upcomingExams",
  async (data, thunkApi) => {
    try {
      const res = await api
        .post("/testlists/upcomingexam", data)
        .then((res) => res?.data);
      console.log(res, "line27")
      return res?.data;

    } catch (error) {
      console.log(error, "line31", error?.message)
      return thunkApi.rejectWithValue(error?.message);
    }
  }
);

const initialState = {
  isLoading: false,
  isError: false,
  testList: [],
  upcomingExams: []
};

const examSlice = createSlice({
  name: "exams",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getExamList.fulfilled, (state, action) => {
        state.testList = action.payload;
        state.isLoading = false;
      })
      .addCase(getExamList.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getExamList.rejected, (state, action) => {
        state.isError = action.payload;
      });

    builder
      .addCase(getUpcomingExams.fulfilled, (state, action) => {
        console.log(action.payload, "err")
        state.upcomingExams = action.payload;
        state.isLoading = false;
      })
      .addCase(getUpcomingExams.pending, (state, action) => {
        // state.isLoading = true;
      })
      .addCase(getUpcomingExams.rejected, (state, action) => {
        state.isError = true
        state.upcomingExams = []
      });
  },
});

export const examQues = (state) => state?.exams

export default examSlice.reducer;
