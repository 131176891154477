import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../app/AxiosHandler";
import { useSearchParams } from "react-router-dom";

export const getStreamBySubjectId = createAsyncThunk(
  "getStreamBySubjectId",
  async (data, { rejectWithValue }) => {
    try {
      const res = await api
        .post(process.env.REACT_APP_GET_STREAM_BY_SUBJECTS, data)
        .then((res) => res.data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getUpcomingStreamBySubjects = createAsyncThunk(
  "getUpcomingStreamBySubjects",
  async (data, { rejectWithValue }) => {
    try {
      const res = await api
        .post(process.env.REACT_APP_GET_UPCOMING_STREAM_BY_SUBJECTS, data)
        .then((res) => res.data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const playLive = createAsyncThunk(
  "stream/play",
  async (data, { rejectWithValue }) => {
    try {
      const res = await api.post("stream/onplay", data).then((res) => res.data);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getLiveById = createAsyncThunk(
  "stream/getbyid",
  async (data, { rejectWithValue }) => {
    try {
      const res = await api
        .post("stream/bylivestreamid", data)
        .then((res) => res.data);
      return res.data;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

const initialState = {
  data: [],
  isLoading: false,
  error: false,
  streamData: "",
  url: "",
  upcomingClassData: [],
};

const streamSlice = createSlice({
  name: "liveStreams",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStreamBySubjectId.fulfilled, (state, action) => {
        state.data = action.payload;
        state.isLoading = false;
      })
      .addCase(getStreamBySubjectId.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
        state.data = [];
      })
      .addCase(getStreamBySubjectId.pending, (state, action) => {
        state.isLoading = true;
      });
    builder
      .addCase(getUpcomingStreamBySubjects.fulfilled, (state, action) => {
        state.upcomingClassData = action.payload;
        state.isLoading = false;
      })
      .addCase(getUpcomingStreamBySubjects.rejected, (state, action) => {
        state.error = action.payload;
        state.upcomingClassData = [];
        state.isLoading = false;
      })
      .addCase(getUpcomingStreamBySubjects.pending, (state, action) => {
        state.isLoading = true;
      });
    builder
      .addCase(playLive.fulfilled, (state, action) => {
        state.url = action.payload;
        state.isLoading = false;
      })
      .addCase(playLive.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(playLive.pending, (state, action) => {
        state.isLoading = true;
      });

    builder.addCase(getLiveById.fulfilled, (state, action) => {
      state.streamData = action.payload;
    });
  },
});

export const streamInfo = (state) => state?.liveStreams?.streamData;

export default streamSlice.reducer;
