import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getSyllabus,
  getSyllabusById,
  resetSyllabus,
} from "../../features/getCourseSlice";
import PDFview from "../../components/PDFViewer/PDFviewer";
import Loader from "../../components/Loader";
import useEnrolledData from "../../components/hooks/useEnrolledData";
import NoDataFound from "../../components/NoDataFound";


const Syllabus = () => {
  const { loadingState, selectedId, subCourses } = useEnrolledData();

  const [showpdf, setShowpdf] = useState(true);


  const syllabusData = useSelector((state) => state?.course?.syllabusData);
  if (syllabusData) {
    console.log("Syllabus Data:", syllabusData);
  } else {
    console.log("Syllabus Data is undefined or null");
  }
  
  
  const dispatch = useDispatch();

  useEffect(() => {
    if (loadingState === false)
      dispatch(getSyllabus({ courseFieldId: selectedId }));
  }, [selectedId, dispatch]);

  useEffect(() => {
    pdfurl && setShowpdf(true);
  }, [showpdf]);

  let pdfurl = useSelector((state) => state?.course?.syllabusUrl);
  console.log(pdfurl,'pdfurl11')
  const loading = useSelector((state) => state?.course?.isLoading);

  const colors = [
    "border-l-red-500",
    "border-l-lime-500",
    "border-l-[#FF9A6C]",
    "border-l-[#EC4A5E]",
    "border-l-[#1DB448]",
    "border-l-[#FF9A6C]",
    "border-l-[#EC4A5E]",
    "border-l-red-500",
    "border-l-[#EC4A5E]",
  ];

  const handleOnClick = (item) => {
    console.log(item,"line56")
    if (item?.syllabuses && item?.syllabuses?.length > 0) {
      dispatch(getSyllabusById({ syllabusId: item?.syllabuses[0]?._id }));
    }
  }

  return (
    <React.Fragment>
      {!loading ?
        <>
          <h1 className="text-3xl font-semibold capitalize font-primary">
            Lorem Ipsum <span className="text-secondary"> Dolor Sit amet</span>{" "}
          </h1>
          <div>
            <div>

              {syllabusData ?
                <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 mt-6 gap-10">
                  {syllabusData[0]?.subjects?.map((item, i) => {
                    const { subjectName } = item;
                    return (
                      <div
                        key={i}
                        className={`bg-[#FAFAFA] cursor-pointer hover:drop-shadow-lg lg:w-[261px] rounded-md border-l-[5px] ${colors[i]}   flex  items-center`}
                        onClick={() => handleOnClick(item)} >
                        <p className="ml-7 py-8 text-xl font-semibold font-primary text-[#383838]">
                          {subjectName}
                        </p>
                      </div>
                    );
                  })}
                </div>
                :
                <NoDataFound />
              }

            </div>

            {pdfurl ?
              <div className="bg-[#555F65] bg-opacity-60 flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none" >
                <div className="relative bg-white outline-none focus:outline-none">
                  <PDFview pdfUrl={pdfurl} handleClose={() => dispatch(resetSyllabus())} />
                  {console.log(pdfurl,'pdffff')}

                </div>
              </div>
              : null}
         
          </div>
        </>
        : <Loader />
      }
    </React.Fragment>
  );
};

export default Syllabus;
