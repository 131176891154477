import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { teacherByCourseField, teachers } from "../../features/teacherSlice";
import Instructor from "../../components/Instructor/Instructor";
import useEnrolledData from "../../components/hooks/useEnrolledData";
import Blog from "../../components/Blog/Blog";
import UpperFooter from "../../components/UpperFooter/UpperFooter";
import { useEffect } from "react";
import { getNews } from "../../features/doubtSlice";
import { Link } from "react-router-dom";

const InstructorList = () => {

    const dispatch = useDispatch();

    const { selectedId, subCourses, loadingState } = useEnrolledData();

    const teachersData = useSelector(teachers);
    const newsData = useSelector((state) => state?.doubt?.newsData);

    useEffect(() => {
        dispatch(getNews({
            "courseFieldId": selectedId
        }));
        dispatch(teacherByCourseField({ courseFieldId: selectedId }));
    }, [selectedId, dispatch]);

    return (
        <React.Fragment>
            <section className="bg-teachers bg-cover bg-no-repeat md:bg-center lg:h-[242px] w-full relative">
                <div className="max-w-[1200px] mx-auto px-4">
                    <div className="absolute bottom-1/2 translate-y-2/4 font-primary font-bold text-white">
                        <ol className="flex gap-2 items-center text-sm mb-4">
                            <li>
                                <a href="#">Home</a>
                            </li>
                            <li>
                                <svg
                                    width="7"
                                    height="12"
                                    viewBox="0 0 7 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 1L6 6L1 11"
                                        stroke="white"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </li>
                            <li>Teachers</li>
                        </ol>
                        <h1 className="lg:text-5xl text-3xl font-bold">Teachers</h1>
                    </div>
                </div>
            </section>
            <div className="container max-w-[1200px] mx-auto md:px-4 mt-8">
                <div className="flex flex-wrap my-6 lg:gap-[30px]">
                    {teachersData &&
                        teachersData.slice(0, 6)?.map((teacher, i) => {
                            const { teacherId } = teacher;

                            return <Instructor teacherData={teacher} />;
                        })}
                </div>
            </div>
            <section style={{ backgroundColor: "#F7FAFD" }} className="mt-8" >
                <div className="container max-w-[1200px] mx-auto md:px-4 py-8">
                    <h2 className="text-black font-primary font-semibold text-3xl mb-5">
                        Find <span className="text-secondary font-bold">Latest News</span>
                    </h2>
                    <div className="grid sm:grid-cols-2 md:grid-cols-3 grid-cols-1  -mx-4 gap-y-4">
                        {newsData &&
                            newsData.slice(0, 3).map((news, i) => {
                                const { title, newsImg, _id, createdAt, description } = news;
                                return (
                                    <div key={_id}>
                                        <Blog
                                            title={title}
                                            img={newsImg}
                                            date={createdAt}
                                            _id={_id}
                                            description={description}
                                        />
                                    </div>
                                );
                            })}
                    </div>
                    <div className="text-center mt-8">
                        <Link to={"/news"}
                            className="py-2 px-4 text-white bg-primary inline-block rounded font-medium font-primary text-base hover:opacity-75 lg:w-[142px] text-center"
                        >
                            VIEW MORE
                        </Link>
                    </div>
                </div>
            </section>

            <div className="max-w-[1200px] mx-auto">
                <UpperFooter />
            </div>
        </React.Fragment>
    );
}

export default InstructorList;