import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import api from "../app/AxiosHandler";

const initialState = {
  isLoading: false,
  AllCourses: [],
  language: "",
  syllabusData: "",
  subjectResult: "",
  syllabusUrl: "",
  subjectData: "",
  error: "",
  bannersData: []
};

export const getAllCourses = createAsyncThunk("course/getCourses", async () => {
  const response = await api
    .post("courses/get/allcourse")
    .then((res) => res.data);
  return response.data;
});

//get banner
export const getAllBanners = createAsyncThunk("course/getBanner",
  async (data) => {
    const response = await api
      .post("files/bannerbycoursefield", data)
      .then((res) => res.data);
    return response.data;
  });

export const searchSUbject = createAsyncThunk(
  "course/search",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api
        .post(process.env.REACT_APP_SEARCH_SUBJECT, data)
        .then((res) => res.data);
      return response?.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getSyllabus = createAsyncThunk(
  "syllabus/get",
  async (data, thunkApi) => {
    try {
      const url = process.env.REACT_APP_GETSYLLABUS;
      const response = await api.post(url, data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

// const SAMPLE="eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImdlbmRlciI6Ik1hbGUiLCJfaWQiOiI2NDc5Y2VlMGUxNmFiMTA3ZjNkNjAzMmYiLCJtb2JpbGUiOjkxNzUzNjA1MTM2OSwiZW1haWwiOiJzYXRlbmRyYUBnbWFpbC5jb20iLCJiaW8iOiIiLCJwcm9maWxlX2ltZyI6Imh0dHBzOi8vaWFtbGVhcm5pbmctZGV2LWJ1Y2tldC5zMy5hcC1zb3V0aC0xLmFtYXpvbmF3cy5jb20vdXNlcnNQcm9maWxlL21hbGVBdmF0YXJJbWFnZS5wbmciLCJmdWxsbmFtZSI6IlNhdGVuZHJhIFNpbmdoIiwiaXNBY3RpdmUiOnRydWUsImlzRGVsZXRlIjpmYWxzZSwiY3JlYXRlZEF0IjoiMjAyMy0wNi0wMlQxMToxMzozNi45NjNaIiwidXBkYXRlZEF0IjoiMjAyMy0wOC0wNFQwNzoyOToxNy42NTdaIiwiX192IjowLCJhY2FkZW1pY0lkIjoiNjRhM2E4ZmY2MzNkOWVlY2JmYTg4YmQ5IiwiZW5yb2xsZWRDb3Vyc2VzIjpbeyJjb3Vyc2VJZCI6IjY0OWViM2QwOGJmZGM1NzVhMThjNjRiZSIsImNvdXJzZUZpZWxkSWQiOiI2NDllYjRmNzhiZmRjNTc1YTE4YzY0Y2IiLCJpc1NlbGVjdGVkIjpmYWxzZSwibGFuZ3VhZ2VJZCI6IjY0N2VlZjMzMzliZTBhMDBjYmI2N2JlNSIsIl9pZCI6IjY0YzhmMDM1MjUyZjY5MzQyZGU0NjhmNSJ9XSwib3RwIjoiMTI4NDY3In0sImlhdCI6MTY5MTEzNDE4MX0.JMGxM3kY7S16pMg-6_np7c7xVAlg3oWDs9NPqNN4Fms"

export const getSyllabusById = createAsyncThunk(
  "syllabus/byId",
  async (id, thunkApi) => {
    try {
      const response = await api
        .post(process.env.REACT_APP_SYLLABUS_BYID, id)
        .then((res) => {
          if (res.data && res.data.data && res.data.data.file) {
            const fileUrl = res.data.data.file;
            console.log("File URL:", fileUrl);
            return fileUrl;
          } else {
            return thunkApi.rejectWithValue("Invalid response format");
          }
        });

      return response;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);



export const getSyllabusByCourseField = createAsyncThunk(
  "syllabus/subcourse",
  async (data, thunkApi) => {
    try {
      const response = await api.post(process.env.URL, data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

export const getSubjects = createAsyncThunk(
  "subjects/get",
  async (data, thunkApi) => {
    try {
      const response = await api.post(process.env.REACT_APP_GET_SUBJECTS, data);
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.message);
    }
  }
);

const courseSlice = createSlice({
  name: "course/getCourses",
  initialState,
  reducers: {
    resetSyllabus: (state, action) => {
      state.syllabusUrl = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllCourses.fulfilled, (state, action) => {
      state.AllCourses = action.payload;
      state.language = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getAllCourses.pending, (state, action) => {
      state.isLoading = true;
    });

    builder.addCase(getAllBanners.fulfilled, (state, action) => {
      state.bannersData = action.payload;
      state.isLoading = false;
    });
    builder.addCase(getAllBanners.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(getAllBanners.rejected, (state, action) => {
      state.bannersData = [];
    });

    builder
      .addCase(getSyllabus.fulfilled, (state, action) => {
        state.syllabusData = [...action.payload.data];
        state.isLoading = false;
      })
      .addCase(getSyllabus.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getSyllabus.rejected, (state, action) => {
        state.error = action.payload;
      });

    builder
      .addCase(searchSUbject.fulfilled, (state, action) => {
        state.subjectResult = action.payload;
      })
      .addCase(searchSUbject.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(searchSUbject.rejected, (state, action) => {
        state.isLoading = false;
      });

    builder
      .addCase(getSubjects.fulfilled, (state, action) => {
        state.subjectData = action.payload;
        state.isLoading = false;
      })
      .addCase(getSubjects.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getSubjects.rejected, (state, action) => {
        state.error = action.payload;
      });

    builder.addCase(getSyllabusByCourseField.fulfilled, (state, action) => {
      state.syllabusData = action.payload;
    });

    builder
      .addCase(getSyllabusById.fulfilled, (state, action) => {
        state.syllabusUrl = action.payload;
        state.isLoading = false;
        
   
      })
      .addCase(getSyllabusById.rejected, (state, action) => {
        state.error = action.payload;
        state.syllabusUrl = "";
      })
      .addCase(getSyllabusById.pending, (state, action) => {
        state.isLoading = true;
      });
  },
});

export const { resetSyllabus } = courseSlice.actions;

export default courseSlice.reducer;
