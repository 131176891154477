import React, { useEffect, useRef, useState } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import HomeHeader from "../../assets/SVG/homeHeader.webp";
import Learning1 from "../../assets/images/Learning1.jpg";
import Learning2 from "../../assets/images/Learning2.png";
import Learning3 from "../../assets/images/Learning3.png";
import Pablo from "../../assets/images/Pablo.png";
import curveArrow from "../../assets/images/arrowGroup.png";
import groupImg from "../../assets/images/groupImg.png";
import icon01 from "../../assets/images/icon_1.png";
import icon02 from "../../assets/images/icon_2.png"
import icon03 from "../../assets/images/icon_3.png"
import icon04 from "../../assets/images/icon_4.png"
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../assets/CotginEDU.png";
import { useNavigate } from "react-router-dom";
import UpperFooter from "../../components/UpperFooter/UpperFooter";
import { getAllCourses } from "../../features/getCourseSlice";
import { loginNumber, updateNumber } from "../../features/userSlice";
import AimCard from "./AimCard";
import Counter from "./Counter";
import LoginModal from "./LoginModal";
import useWindowDimensions from "../../components/useWindowDimensions";
import { toast } from "react-toastify";
import { ToastContainer } from 'react-toastify';
import OTPpage from "./OTPpage";
import ultimate from "../../assets/SVG/ultimate.webp";
import testi01 from "../../assets/images/testi01.png";
import testi02 from "../../assets/images/testi02.png";
import testi03 from "../../assets/images/testi03.png";
import quote from "../../assets/images/Quotes.png";
import { FaStar } from "react-icons/fa";
import Slider from "react-slick";
import { MdNavigateNext } from "react-icons/md";
import { MdNavigateBefore } from "react-icons/md";
import image from "../../assets/images/image.png";
import play_btn from "../../assets/images/play-btn.png";
import learnEDU from "../../assets/images/learn-edu-right.webp";
import { GoDotFill } from "react-icons/go";

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isMobile } = useWindowDimensions();

  const [isNumTrue, setIsNumTrue] = useState(false);
  const [error, setError] = useState(false);

  // const state = useSelector((state) => state.user.user);
  const token = useSelector((state) => state.user.token);
  // const isNumberCorrect = useSelector((state) => state.user.isNumberCorrect);
  const [loginModal, setLoginModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpVerify, setOtpVerify] = useState("");

  function validate(phone) {
    // const regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    const regex =
      /^(?!([0-9])\1{2}-?\1{3}-?\1{4}$)\(?\d{3}\)?[-. ]?\d{3}[-. ]?\d{4}$/;
    return regex.test(phone);
  }

  const handleLoginNumber = (e, type) => {
    e.preventDefault();
    const progress = validate(phoneNumber);
    console.log(progress);
    if (progress) {
      dispatch(updateNumber(phoneNumber));
      dispatch(loginNumber(phoneNumber));
      if (type !== "Modal") {
        setIsNumTrue(true);
        toast.success("Otp Sent on your number")
      }
    } else {
      setError(true);
    }
  };

  const loginFormRef = useRef(null);

  const scrollToLoginForm = () => {
    setLoginModal(true);
  };

  const popularCourses = useSelector(
    (state) => state?.course?.AllCourses?.selectYourGoal
  );
  useEffect(() => {
    dispatch(getAllCourses());
  }, []);

  var settings = {
    autoplay: true,
    autoplaySpeed: 2000,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <MdNavigateBefore />,
    nextArrow: <MdNavigateNext />,
    responsive: [
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          dots: false,
          arrows:false,
        },
      },
    ],
  };

  return (
    <>
      <ToastContainer />
      {loginModal && (
        <LoginModal
          setLoginModal={setLoginModal}
          setPhoneNumber={setPhoneNumber}
          handleLoginNumber={handleLoginNumber}
          error={error}
        />
      )}
      {/* 1ST SECTION  */}
      <div className="bg-[#0868C408] pt-6 pb-6 sm:pb-12 banner-box" ref={loginFormRef}>
        {!isNumTrue ? (
          <section className="grid grid-cols md:grid-cols-2 sm:gap-20 items-center w-full container  px-4 max-w-[1200px] mx-auto">
            {/* Left */}
            <div className="flex flex-col order-1 lg:order-1 sm:spacing-y-4 flex-1">
              <img
                src={HomeHeader}
                alt=""
              />
              <p className="text-black font-medium text-sm sm:text-lg font-secondary mb-5 lg:text-start text-center">
                Become lifelong learners with personalized learning programs
                {/* <span className="text-secondary font-semibold">
                  India's best tutors, anytime, anywhere
                </span>
                . */}
              </p>
              {isMobile ? (
                ""
              ) : (
                <div className="flex lg:justify-start justify-center flex-wrap items-center gap-6 ">
                  <button
                    className="bg-primary rounded font-bold font-primary uppercase text-white px-5 py-3 hover:opacity-75"
                    onClick={() => setLoginModal(true)}
                  >
                    Join Course
                  </button>
                  <button className="flex items-center gap-3 font-primary font-medium text-black">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="50"
                      height="51"
                      viewBox="0 0 50 51"
                      fill="none"
                    >
                      <circle cx="25" cy="25.1511" r="25" fill="#ec4a5e" />
                      <path
                        d="M34.2109 25.1511L20.3951 34.2671L20.3951 16.035L34.2109 25.1511Z"
                        fill="white"
                      />
                    </svg>
                    <span> See how it works?</span>
                  </button>
                </div>
              )}
            </div>

            {/* Right  */}
            <div className=" flex flex-col order-2 lg:order-2 self-center flex-1 bg-white items-center gap-5 py-md-7 px-md-2.5 p-5 w-full ml-auto rounded-2xl shadow-xl shadow-[#0000000D]">
              <h1 className="text-lg-2xl text-sm-xl lg:text-[32px] text-[25px]  font-primary  text-center  font-semibold">
                Ace Your {" "}
                <span className="text-secondary font-bold">Learning</span> with {""}
                <span className="text-secondary font-bold">
                  India’s Top Educators
                </span>
              </h1>
              <form
                onSubmit={handleLoginNumber}
                className="flex flex-col w-full mx-auto gap-4 px-3"
              >
                {/* <div className="flex gap-4 sm:gap-7 items-center border border-[#C3C3C3] w-full rounded focus:outline-none focus:ring focus:ring-primary p-3">
                  <input
                    type="text"
                    value={phoneNumber}
                    maxLength={10}
                    className="w-full focus:outline-none"
                    onChange={(e) => {
                      if (!isNaN(Number(e.target.value))) {
                        setPhoneNumber(e.target.value);
                      }
                    }}
                    placeholder="Enter Exam Preference..."
                    autoFocus={true}
                  />
                  <span className="grey-color"><IoSearch /></span>
                </div> */}
                <div className="flex gap-4 sm:gap-7 items-center border border-[#C3C3C3] w-full rounded focus:outline-none focus:ring focus:ring-primary p-3">
                  <span className="font-medium select-none">+91</span>
                  <input
                    type="text"
                    value={phoneNumber}
                    maxLength={10}
                    className="w-full focus:outline-none"
                    onChange={(e) => {
                      if (!isNaN(Number(e.target.value))) {
                        setPhoneNumber(e.target.value);
                      }
                    }}
                    placeholder="Enter Your Mobile Number"
                    autoFocus={true}
                  />
                  <span className="grey-color">Verify</span>
                </div>

                <button
                  className="bg-secondary font-primary rounded font-bold uppercase text-white p-3 hover:opacity-75"
                // type="submit"
                >
                  Join Now
                </button>
                {error && (
                  <span className="text-secondary">
                    Please enter correct Number !!
                  </span>
                )}
              </form>
            </div>
          </section>
        ) : (
          <OTPpage
            otpVerify={'otpVerify'}
            setOtpVerify={setOtpVerify}
            phoneNumber={phoneNumber}
            setOTPMOdal={setIsNumTrue}
            setLoginModal={setLoginModal}
          />
        )}
      </div>

      {/* 2ND SECTION  */}
      <section className="container mx-auto flex flex-col items-center max-w-[1200px] edu-icon-box">
        <div className="grid grid-cols grid-cols-2 lg:grid-cols-4 w-full text-center">
          <span
            onClick={scrollToLoginForm}
          >
            <div className="edu-icon">
              <img src={icon01} alt="" className="sm:h-20 sm:w-20 h-[50px] w-[71px] mx-auto object-contain" />
              <h4 className="text-[#ffff] font-semibold font-primary text-base">Daily Live Classes</h4>
              <p>Lorem Ipsum dolor study</p>
            </div>
          </span>

          <span
            onClick={scrollToLoginForm}
          >
            <div className="edu-icon no-border">
              <img src={icon02} alt="" className="sm:h-20 sm:w-20 h-[50px] w-[71px] mx-auto object-contain" />
              <h4 className="text-[#ffff] font-semibold font-primary text-base">Top Educators</h4>
              <p>Lorem Ipsum dolor study</p>
            </div>
          </span>
          <span
            onClick={scrollToLoginForm}
          >
            <div className="edu-icon">
              <img src={icon03} alt="" className="sm:h-20 sm:w-20 h-[50px] w-[71px] mx-auto object-contain" />
              <h4 className="text-[#ffff] font-semibold font-primary text-base">Doubts & Solutions</h4>
              <p>Lorem Ipsum dolor study</p>
            </div>
          </span>
          <span
            onClick={scrollToLoginForm}
          >
            <div style={{ marginBlock: "2rem" }} >
              <img src={icon04} alt="" className="sm:h-20 sm:w-20 h-[50px] w-[71px] mx-auto object-contain" />
              <h4 className="text-[#ffff] font-semibold font-primary text-base">Learn Any Course</h4>
              <p>Lorem Ipsum dolor study</p>
            </div>
          </span>
        </div>
      </section>

      <section className="container mx-auto flex flex-col items-center max-w-[1200px] best-course">
        <div className="best-course-heading">
          <h2>Explore Our World's Best Courses</h2>
        </div>
        <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-6">
          <div className="best-course-box">
            <div className="best-padding">
              <div className="best-course-img">
                <img src={image} alt="" width="100%" />
                <div className="play_btn">
                  <img src={play_btn} alt="" />
                </div>
              </div>

              <div className="best-course-content">
                <ul>
                  <li className="best-red">EN</li>
                  <li className="best-blue">Design</li>
                </ul>
                <h3>Chemical Equilibrium - Final Practice - JEE MAIN April 2023</h3>

              </div>
            </div>
            <div className="best-footer">
              <p>By Ridhan Sharma</p>
            </div>
          </div>
          <div className="best-course-box">
            <div className="best-padding">
              <div className="best-course-img">
                <img src={image} alt="" width="100%" />
                <div className="play_btn">
                  <img src={play_btn} alt="" />
                </div>
              </div>

              <div className="best-course-content">
                <ul>
                  <li className="best-red">EN</li>
                  <li className="best-blue">Design</li>
                </ul>
                <h3>Chemical Equilibrium - Final Practice - JEE MAIN April 2023</h3>

              </div>
            </div>
            <div className="best-footer">
              <p>By Ridhan Sharma</p>
            </div>
          </div>
          <div className="best-course-box">
            <div className="best-padding">
              <div className="best-course-img">
                <img src={image} alt="" width="100%" />
                <div className="play_btn">
                  <img src={play_btn} alt="" />
                </div>
              </div>

              <div className="best-course-content">
                <ul>
                  <li className="best-red">EN</li>
                  <li className="best-blue">Design</li>
                </ul>
                <h3>Chemical Equilibrium - Final Practice - JEE MAIN April 2023</h3>

              </div>
            </div>
            <div className="best-footer">
              <p>By Ridhan Sharma</p>
            </div>
          </div>
          <div className="best-course-box">
            <div className="best-padding">
              <div className="best-course-img">
                <img src={image} alt="" width="100%" />
                <div className="play_btn">
                  <img src={play_btn} alt="" />
                </div>
              </div>

              <div className="best-course-content">
                <ul>
                  <li className="best-red">EN</li>
                  <li className="best-blue">Design</li>
                </ul>
                <h3>Chemical Equilibrium - Final Practice - JEE MAIN April 2023</h3>

              </div>
            </div>
            <div className="best-footer">
              <p>By Ridhan Sharma</p>
            </div>
          </div>
          <div className="best-course-box">
            <div className="best-padding">
              <div className="best-course-img">
                <img src={image} alt="" width="100%" />
                <div className="play_btn">
                  <img src={play_btn} alt="" />
                </div>
              </div>

              <div className="best-course-content">
                <ul>
                  <li className="best-red">EN</li>
                  <li className="best-blue">Design</li>
                </ul>
                <h3>Chemical Equilibrium - Final Practice - JEE MAIN April 2023</h3>

              </div>
            </div>
            <div className="best-footer">
              <p>By Ridhan Sharma</p>
            </div>
          </div>
          <div className="best-course-box">
            <div className="best-padding">
              <div className="best-course-img">
                <img src={image} alt="" width="100%" />
                <div className="play_btn">
                  <img src={play_btn} alt="" />
                </div>
              </div>

              <div className="best-course-content">
                <ul>
                  <li className="best-red">EN</li>
                  <li className="best-blue">Design</li>
                </ul>
                <h3>Chemical Equilibrium - Final Practice - JEE MAIN April 2023</h3>

              </div>
            </div>
            <div className="best-footer">
              <p>By Ridhan Sharma</p>
            </div>
          </div>
          <div className="best-course-box">
            <div className="best-padding">
              <div className="best-course-img">
                <img src={image} alt="" width="100%" />
                <div className="play_btn">
                  <img src={play_btn} alt="" />
                </div>
              </div>

              <div className="best-course-content">
                <ul>
                  <li className="best-red">EN</li>
                  <li className="best-blue">Design</li>
                </ul>
                <h3>Chemical Equilibrium - Final Practice - JEE MAIN April 2023</h3>

              </div>
            </div>
            <div className="best-footer">
              <p>By Ridhan Sharma</p>
            </div>
          </div>
          <div className="best-course-box">
            <div className="best-padding">
              <div className="best-course-img">
                <img src={image} alt="" width="100%" />
                <div className="play_btn">
                  <img src={play_btn} alt="" />
                </div>
              </div>

              <div className="best-course-content">
                <ul>
                  <li className="best-red">EN</li>
                  <li className="best-blue">Design</li>
                </ul>
                <h3>Chemical Equilibrium - Final Practice - JEE MAIN April 2023</h3>

              </div>
            </div>
            <div className="best-footer">
              <p>By Ridhan Sharma</p>
            </div>
          </div>
        </div>
      </section>

      {/* 3RD SECTION */}
      <section style={{ backgroundColor: "#00477C", color: "#ffff" }}>
        <div className="container mx-auto max-w-[1200px] ">
          <div className="flex flex-wrap items-center">
            {isMobile ? (
              ""
            ) : (
              <div className="md:w-5/12">
                <img src={ultimate} alt="ultimate" />
              </div>
            )}
            <div className="md:w-7/12 w-full text-center my-8">
              <h2 className="mb-3 text-dark text-xl sm:text-[32px] font-bold font-primary">
                Unlimited <span className="text-secondary">Choices</span>,
                Unmatched <span className="text-secondary">Learning</span>
              </h2>
              <p
                style={{ color: "#FFFF" }}
                className="mb-4 font-medium font-secondary"
              >
                450+ Centers | 6845+ Courses | 160+ Cities
              </p>
              <button
                className="bg-secondary font-primary rounded font-bold uppercase text-white p-3 hover:opacity-80"
                onClick={() => setLoginModal(true)}
              >
                ADMISSION STARTS 2023-24
              </button>
            </div>
          </div>
        </div>
      </section>

      {/* 4RD SECTION */}
      <section>
        <div className="container mx-auto py-6 sm:py-16 px-4 max-w-[1200px]">
          <h2 className="text-dark text-xl lg:text-[34px] text-[25px] font-semibold font-primary capitalize mb-lg-6 mb-3">
            How to learn with Cotgin Edu
          </h2>
          <div className="flex items-center flex-wrap">
            <div className="left-learn-edu">
              <div className="flex left-learn-active">
                <div className="left-learn-icon">
                <GoDotFill />
                </div>
                <div className="left-learn-content">
                  <p>Choose your exam</p>
                  <span>Can be multiple for example: SBI Clerk Prelims and IBPS RRB PO Prelims</span>
                </div>
              </div>
              <div className="flex">
                <div className="left-learn-icon">
                <GoDotFill />
                </div>
                <div className="left-learn-content">
                  <p>Select your desired course</p>
                  <span>Can be multiple for example: SBI Clerk Prelims and IBPS RRB PO Prelims</span>
                </div>
              </div>
              <div className="flex">
                <div className="left-learn-icon">
                <GoDotFill />
                </div>
                <div className="left-learn-content">
                  <p>Make the purchase</p>
                  <span>Can be multiple for example: SBI Clerk Prelims and IBPS RRB PO Prelims</span>
                </div>
              </div>
              <div className="flex">
                <div className="left-learn-icon">
                <GoDotFill />
                </div>
                <div className="left-learn-content">
                  <p>Navigate to My Purchased Courses</p>
                  <span>Can be multiple for example: SBI Clerk Prelims and IBPS RRB PO Prelims</span>
                </div>
              </div>
              <div className="flex">
                <div className="left-learn-icon">
                <GoDotFill />
                </div>
                <div className="left-learn-content">
                  <p>Choose the course you want to study</p>
                  <span>Can be multiple for example: SBI Clerk Prelims and IBPS RRB PO Prelims</span>
                </div>
              </div>
              
            </div>
            <div className="right-learn-edu">
              <img src={learnEDU} alt=""   />
            </div>
          </div>
        </div>
      </section>

      {/* 5RD SECTION */}
      <section style={{ backgroundColor: "#F1F7FF" }}>
        <div className="container mx-auto py-4 sm:py-16 px-4 max-w-[1200px]">
          <div className="flex flex-col lg:flex-row">
            <div className="lg:w-[600px] lg:text-start text-center">
              <h2 className="mb-2 sm:mb-4 text-dark text-xl sm:text-[32px] sm:leading-9 font-bold font-primary capitalize">
                Testimonials from Happy Learners for Cotgin Edu
              </h2>
            </div>
          </div>
          <div className="testimonial-wrap">
            <Slider {...settings}>
              <div className="testimonial-box">
                <img src={testi01} alt="" />
                <div className="testi-star-box">
                  <FaStar />
                  <FaStar /><FaStar /><FaStar /><FaStar />
                </div>
                <p>The team came on time and did as per fantastic job
                  cleaning my short-term rental property. The
                  team was very helpful and kind. </p>

                <div className="quote-box">
                  <div>
                    <span>Moskur Alom</span>
                  </div>
                  <div>
                    <img src={quote} alt="" />
                  </div>
                </div>
              </div>
              <div className="testimonial-box">
                <img src={testi02} alt="" />
                <div className="testi-star-box">
                  <FaStar />
                  <FaStar /><FaStar /><FaStar /><FaStar />
                </div>
                <p>The team came on time and did as per fantastic job
                  cleaning my short-term rental property. The
                  team was very helpful and kind. </p>
                <div className="quote-box">
                  <div>
                    <span>Moskur Alom</span>
                  </div>
                  <div>
                    <img src={quote} alt="" />
                  </div>
                </div>
              </div>
              <div className="testimonial-box">
                <img src={testi03} alt="" />
                <div className="testi-star-box">
                  <FaStar />
                  <FaStar /><FaStar /><FaStar /><FaStar />
                </div>
                <p>The team came on time and did as per fantastic job
                  cleaning my short-term rental property. The
                  team was very helpful and kind. </p>
                <div className="quote-box">
                  <div>
                    <span>Moskur Alom</span>
                  </div>
                  <div>
                    <img src={quote} alt="" />
                  </div>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>

      {/* 6RD SECTION */}
      <UpperFooter />
    </>
  );
};

export default Home;
