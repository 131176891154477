import React from "react";
import mobileApp from "../../assets/images/mobiledev.webp";
import googlePlay from '../../assets/images/playStore.png';
import appStore from '../../assets/images/appStore.png';

const DownloadApp = () => {
    return (
        <React.Fragment>
            <section className="bg-downloadApp bg-cover bg-no-repeat md:bg-center lg:h-[242px] w-full relative">
                <div className="max-w-[1200px] mx-auto px-4">
                    <div className="absolute bottom-1/2 translate-y-2/4 font-primary font-bold text-white">
                        <ol className="flex gap-2 items-center text-sm mb-4">
                            <li>
                                <a href="#">Home</a>
                            </li>
                            <li>
                                <svg
                                    width="7"
                                    height="12"
                                    viewBox="0 0 7 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M1 1L6 6L1 11"
                                        stroke="white"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                            </li>
                            <li>Download App</li>
                        </ol>
                        <h1 className="lg:text-5xl text-3xl font-bold">Download App</h1>
                    </div>
                </div>
            </section>
            <section>
                <div className="container mx-auto py-10 px-4 max-w-[1200px]">
                    <div className="flex flex-col lg:flex-row gap-20">
                        <div className="md:w-8/12 lg:h-[480px] lg:w-[649px] ">
                            <img src={mobileApp} alt="" className='mx-auto h-full' />
                        </div>
                        <div className="self-center lg:w-4/12 lg:text-start text-center">
                            <h2 className='mb-4 text-dark text-[32px] font-bold font-primary capitalize text-[#333333] leading-[36.81px]'>Take your <span className="text-secondary">education</span> with&nbsp;you</h2>
                            <p style={{ color: '#383838' }} className='mb-4 font-normal font-secondary leading-[18.75px]'>Download the Cotgin Edu App to take your learning to the next&nbsp;level.</p>
                            <div>
                                <a href="https://play.google.com/store/apps/details?id=com.cotginanalytics.cotginedu&hl=en-IN" className="inline-block me-3">
                                    {/* <img src={googlePlay} alt="Play Store" className="w-40" /> */}
                                </a>
                                <a href="#" className="inline-block">
                                    <img src={appStore} alt="App Store" className="w-40" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}

export default DownloadApp;