import React from "react";
import ReactHlsPlayer from "react-hls-player";

const Test = () => {
  return (
    <div>
      <h1>Test page</h1>

      <ReactHlsPlayer
        src={
          "https://iamlearning-dev-bucket.s3.ap-south-1.amazonaws.com/videos/hls_files/6513e2cf387d3455b3404abd1697443723995/output.m3u8"
        }
        autoPlay={false}
        controls={true}
        width="60%"
        height="auto"
      />
    </div>
  );
};

export default Test;
