import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../app/AxiosHandler";
import { toast } from "react-toastify";

export const askDoubt = createAsyncThunk(
  "doubts/ask",
  async (data, thunkAPI) => {
    try {
      const response = await api
        .post(process.env.REACT_APP_ASK_DOUBT, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => res.data);

      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getAllDoubts = createAsyncThunk(
  "doubts/getAllDoubts",
  async (_, thunkAPI) => {
    try {
      // Your async logic here, e.g., making an API request to fetch all doubts
      const response = await api.post("").then((res) => res.data);

      // Return the response or handle it as needed
      return response.data;
    } catch (error) {
      // Handle errors and dispatch a rejection action
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getNews = createAsyncThunk("news/getall", async (data, thunkAPI) => {
  try {
    const response = await api
      .post(process.env.REACT_APP_GETNEWS, data)
      .then((res) => res.data);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

export const newsById = createAsyncThunk(
  "news/id",
  async (data, { rejectWithValue }) => {
    try {
      const response = await api
        .post(process.env.REACT_APP_NEWSBYID, data)
        .then((res) => res.data);
      return response.data;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const doubtBySubject = createAsyncThunk(
  "doubts/doubtBySubject",
  async (data, thunkAPI) => {
    try {
      // Your async logic here, e.g., making an API request to fetch doubts by subject
      const response = await api
        .post("doubt/getbysub", data)
        .then((res) => res.data);

      // Return the response or handle it as needed
      return response;
    } catch (error) {
      // Handle errors and dispatch a rejection action
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const doubtById = createAsyncThunk(
  "doubts/doubtById",
  async (data, thunkAPI) => {
    try {
      // Your async logic here, e.g., making an API request to fetch a doubt by ID
      const response = await api.post("", data).then((res) => res.data);

      // Return the response or handle it as needed
      return response.data;
    } catch (error) {
      // Handle errors and dispatch a rejection action
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const initialState = {
  isLoading: false,
  isError: false,
  getDoubts: [],
  getSingleDoubt: null,
  addResponse: null,
  newsData: [],
  singleNews: "",
};

const doubtSlice = createSlice({
  name: "doubts",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(askDoubt.fulfilled, (state, action) => {
      state.getDoubts = state.getDoubts;
      toast.success("Your Doubts sent Successfully");
      
      setTimeout(() => {
        window.location.reload(); 
      }, 240);
    })
    .addCase(askDoubt.pending, (state, action) => {
      state.isLoading = true
    }).addCase(askDoubt.rejected, (state, action) => {
      state.isError = action.payload
    });

    builder
      .addCase(getAllDoubts.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getAllDoubts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.getDoubts = action.payload;
      })
      .addCase(getAllDoubts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
        // Handle errors here, action.payload contains the error message
      })
      .addCase(doubtBySubject.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(doubtBySubject.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.getDoubts = action.payload;

      })
      .addCase(doubtBySubject.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = action.payload;
        state.getDoubts = [];
        // Handle errors here, action.payload contains the error message
      })
      .addCase(doubtById.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(doubtById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.getSingleDoubt = action.payload;
        // Update state with the fetched data if needed
        // Example: state.getSingleDoubt = action.payload;
      })
      .addCase(doubtById.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        // Handle errors here, action.payload contains the error message
      })
      .addCase(getNews.pending, (state, action) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getNews.fulfilled, (state, action) => {
        state.newsData = action.payload;
      })
      .addCase(getNews.rejected, (state, action) => {
        state.isError = action.payload;
        state.isLoading = action.payload;
      });

    builder
      .addCase(newsById.fulfilled, (state, action) => {
        state.singleNews = action.payload;
        state.isLoading = false;
      })
      .addCase(newsById.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(newsById.rejected, (state, action) => {
        state.isError = action.payload;
      });
  },
});

export default doubtSlice.reducer;
