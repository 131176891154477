import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrders, orders ,getProfileData} from "../../features/userSlice";
import { ReactComponent as OrderIcon } from "../../assets/SVG/orderIcon.svg";
import { ReactComponent as NoOrders } from "../../assets/SVG/noOrderIcon.svg";
import { Link } from "react-router-dom";

const MyPurchases = () => {
  const dispatch = useDispatch();
  const ordersdata = useSelector(orders);
  const user = useSelector((state) => state.user.profileData[0]);

  useEffect(() => {
    dispatch(getAllOrders());
    dispatch(getProfileData());
  }, []);

  const formatAmount = (amount) => {
    const formattedAmount = (amount / 100).toFixed(2);
    return formattedAmount;
  };
  const order = ordersdata && ordersdata?.map((order, i) => {
    const {
      createdAt,
      totalAmount,
      courseFieldId,
      orderId,
      planDuration,
      paymentId,
      expireDate,
    } = order || "";
    console.log(expireDate,'expireDate')
    return (
      <div>
        <div className="flex items-center gap-5">
          <h3 className="font-bold text-base font-primary text-black ">
            {new Date(createdAt).toLocaleDateString()}
          </h3><span style={{fontWeight:"700", color:"red"}}>To</span>
            <h3 className="font-bold text-base font-primary text-black ">
            {new Date(expireDate).toLocaleDateString()}
          </h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1027"
            height="2"
            viewBox="0 0 1027 2"
            fill="none"
          >
            <path d="M0 1H1027" stroke="#D9D9D9" stroke-dasharray="2 2" />
          </svg>
        </div>

        <div className="flex items-center gap-5 shadow-sm shadow-[#0770BF40] pl-3 pr-7 mt-5 mb-7 border border-[#0770BF40] py-4 rounded  lg:w-[1130px] ">
          <div className="bg-[#E7F3FF] bg-opacity-25 p-3.5">
            <OrderIcon />
          </div>
          <div>
            <h1 className="font-bold font-primary text-xl ">
              {courseFieldId.name}
            </h1>
            <p className="font-normal text-[#6C6C6C] font-primary text-sm ">
              Payment ID: {paymentId}
            </p>
            <p className="font-normal text-[#6C6C6C] font-primary text-sm ">
              Order ID: {orderId}
            </p>
          </div>
          <div className="flex flex-1"></div>
          <div>
          <h1 className="font-bold text-xl font-primary text-black">
  {' '}
  &#x20B9; {formatAmount(totalAmount)}
</h1>
            <h1 className="font-semibold text-xl font-primary text-primary">
              {planDuration.duration} Months Plan
            </h1>
          </div>
        </div>
      </div>
    );
  });

  return (
    <div>
      <section className="bg-purchases bg-cover bg-no-repeat md:bg-center h-[242px] w-full relative">
        <div className="max-w-[1200px] mx-auto px-4">
          <div className="absolute bottom-1/2 translate-y-2/4 font-primary font-bold text-white">
            <ol className="flex gap-2 items-center text-sm mb-4">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <svg
                  width="7"
                  height="12"
                  viewBox="0 0 7 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1L6 6L1 11"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </li>
              <li>My Purchases</li>
            </ol>
            <h1 className="lg:text-5xl text-3xl">My Purchases</h1>
          </div>
        </div>
      </section>

      {/* <div className="max-w-[1200px] mx-auto my-10 lg:flex lg:flex-wrap gap-8 md:grid font-primary">
        <div className="bg-white lg:h-[252px] lg:w-[455px] shadow-xl border border-[#0000000D] shadow-[#0000000D] rounded-2xl flex">
          <div className="relative">
            <svg xmlns="http://www.w3.org/2000/svg" width="147" height="147" viewBox="0 0 147 147" fill="none">
              <path d="M0 146.513V83.932L84.6683 0H146.513L0 146.513Z" fill="#2E90EF" />
            </svg>
            <p className="absolute top-11 right-10 -rotate-45 text-white text-xl font-medium">Active Plan</p>
          </div>
          <div className="py-8 pr-10 w-full">
            <p className="text-primary font-bold text-4xl">Basic Plan</p>
            <p className="mt-3 text-2xl font-bold">₹26,500</p>
            <p className=" text-sm font-semibold">For 1 Year</p>
            <p className="flex gap-3 font-bold text-lg mt-2">₹2,142 <span className="text-secondary"> 9% off</span> </p>
            <div className="flex justify-between w-full font-bold text-base  mt-2">
              <p>₹ 2,142/month</p>
              <div className="underline underline-offset-4 text-primary"><Link to='/plans'>Upgrade Plan</Link></div>
            </div>
          </div>
        </div>

        <div className="bg-white lg:h-[252px] lg:w-[455px] border-2 border-[#C3C3C3] rounded-2xl flex">
          <div className="relative">
            <svg xmlns="http://www.w3.org/2000/svg" width="147" height="147" viewBox="0 0 147 147" fill="none">
              <path d="M0 146.513V83.932L84.6683 0H146.513L0 146.513Z" fill="#C3C3C3" />
            </svg>
            <p className="absolute top-10 right-12 -rotate-45 text-white text-xl font-medium">Expired</p>
          </div>
          <div className="py-8">
            <p className="font-bold text-4xl">Lite Plan</p>
            <p className="mt-3 text-2xl font-bold">₹26,500</p>
            <p className=" text-sm font-semibold">For 1 Year</p>
            <p className="flex gap-3 font-bold text-lg mt-2">₹2,142 <span className="text-secondary"> 9% off</span> </p>
            <div className="flex justify-between w-full font-bold text-base  mt-2">
              <p>₹ 2,142/month</p>
              <div className="underline underline-offset-4 text-primary"><Link>Upgrade Plan</Link></div>
            </div>
          </div>
        </div>

        <div className="bg-white lg:h-[252px] lg:w-[455px] border-2 border-[#C3C3C3] rounded-2xl flex">
          <div className="relative">
            <svg xmlns="http://www.w3.org/2000/svg" width="147" height="147" viewBox="0 0 147 147" fill="none">
              <path d="M0 146.513V83.932L84.6683 0H146.513L0 146.513Z" fill="#C3C3C3" />
            </svg>
            <p className="absolute top-10 right-12 -rotate-45 text-white text-xl font-medium">Expired</p>
          </div>
          <div className="py-8">
            <p className="font-bold text-4xl">Plus Plan</p>
            <p className="mt-3 text-2xl font-bold">₹26,500</p>
            <p className=" text-sm font-semibold">For 1 Year</p>
            <p className="flex gap-3 font-bold text-lg mt-2">₹2,142 <span className="text-secondary"> 9% off</span> </p>
            <div className="flex justify-between w-full font-bold text-base  mt-2">
              <p>₹ 2,142/month</p>
            </div>
          </div>
        </div>
      </div> */}


      <div className="max-w-[1200px] mx-auto mt-20 mb-10">
        {ordersdata ? (
          <>
            <h1 className="font-primary text-2xl text-black font-bold mb-5">
              Payment History
            </h1>

            {order}
          </>
        ) : (
          <div className="flex flex-col gap-2 mx-auto items-center my-5 max-w-[800px]">
            <NoOrders />
            <h1 className="text-2xl font-bold font-primary text-black ">
              {" "}
              Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            </h1>
            <p className="font-normal text-base font-secondary text-[#383838]">
              {" "}
              Lorem ipsum, dolor sit amet consectetur adipisicing elit.
            </p>
            <button className="px-4 my-5 py-2 bg-[#2E90EF] rounded  text-white uppercase font-medium font-primary text-base hover:opacity-75"

            >
              <Link to={"/plans"}>Purchase Plan</Link>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default MyPurchases;
