import React from "react";
import NoDataFoundImg from "../../assets/images/NoDataFound.png";

const NoDataFound = () => {
    return (
        <React.Fragment>
            <div className=" flex justify-center w-full mt-8" >
                <img src={NoDataFoundImg} className="h-[250px]" />
            </div>
        </React.Fragment>
    );

}
export default NoDataFound;