import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

import userReducer from "../features/userSlice";
import courseReducer from "../features/getCourseSlice";
import enrollReducer from "../features/enrollSlice";
import playlistReducer from "../features/playlistSlice";
import testReducer from "../features/testSlice";
import videoReducer from "../features/videoSlice";
import plansReducer from "../features/plansSlice";
import doubtReducer from "../features/doubtSlice";
import liveStreamReducer from "../features/liveSlice";
import bookmarkSlice from "../features/bookmarkSlice";
import quicksolutionSlice from "../features/quicksolutionSlice";
import teacherSlice from "../features/teacherSlice";
// import logger from 'redux-logger'
import thunk from "redux-thunk";
import examSlice from "../features/examSlice";
import myLearningSlice from "../features/myLearning";
import batchSlice from "../features/batchSlice";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["enroll", "testlist"],
};

const rootReducer = combineReducers({
  user: userReducer,
  course: courseReducer,
  enroll: enrollReducer,
  playlist: playlistReducer,
  testlist: testReducer,
  videos: videoReducer,
  plans: plansReducer,
  doubt: doubtReducer,
  liveStreams: liveStreamReducer,
  bookmarks: bookmarkSlice,
  exams: examSlice,
  quickSolution: quicksolutionSlice,
  teacher: teacherSlice,
  myLearning: myLearningSlice,
  batch: batchSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk],
});

export const persistor = persistStore(store);
