import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/Loader";
import defaultImg from "../../assets/images/defaultImg.png";
import { doubtBySubject } from "../../features/doubtSlice";
import NoDataFound from "../../components/NoDataFound";

export const DoubtCard = ({ data, solution, indexVal, index }) => {
  const { message, fileUrl, _id, solved, createdAt } = data;


  return (
    <div className="col-span-3 w-full relative">
      <div className="w-[273px] lg:h-[290px] bg-white  shadow-md flex flex-col p-5  cursor-pointer">
        <img
          src={process.env.REACT_APP_BASE_URL + fileUrl[0]|| defaultImg}
          alt=""
          className="w-full h-[170px]"
        />
        <p className="font-semibold text-base font-primary mt-2" style={{wordBreak:"break-word"}}>
          {message?.slice(0, 50)}
        </p>
        <div className="flex-1"></div>
        <div className="flex justify-between font-medium text-base font-primary py-1">
          <span>{createdAt.slice(0, 10)}</span>
          <p>
            {solved ? (
              <span className="bg-green-500 text-white px-1 py-1 rounded text-xs">
                Solved
              </span>
            ) : (
              <span className="bg-red-500 text-white px-1 py-1 rounded text-xs">
                Unsolved
              </span>
            )}
          </p>
        </div>
      </div>
      <div className="col-span-3">
        {solution && indexVal === index && (
          <div className="col-span-3">
            <h1 className="text-2xl sm:text-3xl md:text-4xl font-semibold capitalize font-primary">
              Solution By <span className="text-secondary">Experts</span>{" "}
            </h1>
            <div className="mt-7 ">
              <h1 className="text-[#383838] font-normal bg-[#FAFAFA] px-2 py-1 rounded-md text-base capitalize  font-secondary mb-2">
                {solution?.message}
              </h1>
              <img src={process.env.REACT_APP_BASE_URL + solution?.fileUrl} className="h-48 w-48" alt="" />
            </div>
            <div>
              <h1 className="text-[#383838] font-normal bg-[#FAFAFA] px-2 py-1 rounded-md text-base capitalize  font-secondary mb-2">
                {solution?.solutionMsg}
              </h1>
              <img src={process.env.REACT_APP_BASE_URL + solution?.solutionImg} className="h-48 w-48" alt="" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const RecentDoubts = ({ doubtsData, tabId, setSingleDoubtData }) => {
  console.log('1')
  const dispatch = useDispatch();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const [solution, setSolution] = useState("");
  const [indexVal, setIndex] = useState();

  const loading = useSelector((state) => state?.doubt?.isLoading);

  console.log("Doubts Data:", doubtsData);
  console.log("Loading State:", loading);

  const handleLoadMoreBtn = (limit) => {
    dispatch(doubtBySubject({ subjectId: tabId, limit: limit }));
  };
  // console.log(doubtBySubject,'doubtBySubject')

  return (
    <div className="mt-8">
      <h1 className="text-2xl md:text-[28px] font-semibold capitalize font-primary">
        Recent <span className="text-secondary"> doubts</span>{" "}
      </h1>
      {loading ? (
        <Loader />
      ) : (
        <>
          {doubtsData?.data && doubtsData?.data?.length ? (
            <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-y-5 mt-5">
              {doubtsData?.data?.map((item, i) => {
                return (
                  <div
                    className={`${solution ? " col-span-3" : ""}`}
                    onClick={() => {
                      setSingleDoubtData(item);
                      scrollToTop();
                    }}
                  >
                    <DoubtCard
                      key={i}
                      data={item}
                      solution={solution}
                      indexVal={indexVal}
                      index={i}
                    />
                  </div>
                );
              })}
            </div>
          ) : (
            <NoDataFound />
          )}

          {doubtsData?.count > 9 &&
          doubtsData?.data?.length !== doubtsData?.count ? (
            <div className="text-center">
              <button
                onClick={() => handleLoadMoreBtn(doubtsData?.count)}
                className="py-2 px-4 mt-8 text-white bg-primary rounded font-medium font-primary text-base hover:opacity-75 lg:w-[142px] text-center"
              >
                LOAD MORE
              </button>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </div>
  );
};

export default RecentDoubts;
