import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getStreamBySubjectId,
  getUpcomingStreamBySubjects,
} from "../../features/liveSlice";
import { getSubjects } from "../../features/getCourseSlice";
import Card from "../../components/Card/VideoCard";
import UpComingLiveClasses from "./UpComingLiveClasses";
import Loader from "../../components/Loader";
import useEnrolledData from "../../components/hooks/useEnrolledData";
import NoDataFound from "../../components/NoDataFound";

const LiveClasses = () => {
  const dispatch = useDispatch();
  const { loadingState, selectedId, subCourses } = useEnrolledData();

  // const studentGoalsData = useSelector(
  //     (state) => state?.enroll?.studentData[0]
  // );

  // const getSelectedId = studentGoalsData?.courses
  //     .flatMap((course) => course.courseFields)
  //     .find((courseField) => courseField.isSelected);

  const LiveStreamData = useSelector((state) => state?.liveStreams?.data);
  const loading = useSelector((state) => state?.liveStreams?.isLoading);

  const subjectsData = useSelector((state) => state?.course?.subjectData);

  const [activeTab, setTab] = useState(0);
  const [tabId, setTabId] = useState(
    subjectsData && subjectsData?.data[0]?._id
  );

  const UpcomingLiveStreams = useSelector(
    (state) => state?.liveStreams?.upcomingClassData
  );

  const [currentTime, setCurrentTime] = useState(new Date());

 
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000); // Update current time every second
    return () => clearInterval(interval);
  }, []);
  useEffect(() => {
    dispatch(
      getUpcomingStreamBySubjects({
        subjectId: tabId,
      })
    );
  }, [dispatch, tabId]);

  useEffect(() => {
    if (selectedId) {
      dispatch(getSubjects({ courseFieldId: selectedId }));
    }
  }, [selectedId, dispatch]);

  useEffect(() => {
    dispatch(
      getStreamBySubjectId({
        subjectId: tabId,
      })
    );
  }, [dispatch, tabId]);

  return (
    <React.Fragment>
      <div>
        <div className="justify-center sm:justify-start flex flex-wrap gap-5">
          {subjectsData &&
            subjectsData?.data?.map((item, i) => {
              return (
                <button
                  key={i}
                  className={`font-medium text-sm font-primary p-2 px-5 border-2 border-primary rounded
                    ${
                      activeTab === i
                        ? ` bg-primary text-white`
                        : `bg-white text-primary`
                    }`}
                  onClick={() => {
                    setTab(i);
                    setTabId(item._id);
                  }}
                >
                  {item?.subjectName}
                </button>
              );
            })}
        </div>
        <div className="">
          {loading ? (
            <Loader />
          ) : (
            <>
        {LiveStreamData && LiveStreamData.length ? (
        <div className="grid md:grid-cols-2 lg:grid-cols-3 lg:flex-wrap gap-y-7 mt-8">
          {LiveStreamData.map((item, i) => {
            const classTime = new Date(item.timeSchedule);
            const isClassActive = currentTime >= classTime;

            return (
              <React.Fragment key={i}>
                <Card
                  thumbnail={item.teacherId.image}
                  backgroundImg={item.teacherId.teacherBgImage}
                  title={item.title}
                  fullname={item.teacherId.fullname}
                  btnName={isClassActive ? "Watch Class" : "Class Not Started"}
                  showSubjectDetails={true}
                  subjectName={item.subject.subjectName}
                  courseName={item.courseFieldId.name}
                  link={isClassActive ? `/playlive/${item._id}` : "#"}
                  id={item._id}
                  type={"Current"}
                  classTime={classTime.toLocaleString()}
                  disabled={!isClassActive}
                />
              </React.Fragment>
            );
          })}
        </div>
      ) : (
        <NoDataFound />
      )}
            </>
          )}
        </div>
        {UpcomingLiveStreams && UpcomingLiveStreams?.length ? (
          <div className="mt-8">
            <UpComingLiveClasses
              subjectId={tabId}
              UpcomingLiveStreams={UpcomingLiveStreams}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </React.Fragment>
  );
};

export default LiveClasses;
